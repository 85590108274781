/**
 * SessionStorage Static Class
 *
 * Used to customize how we store the session info.
 * The values in cookies is obfuscated to discourage easy tampering.
 * Attribute values are never stored, they are fetched from the server on request.
 */
import _ from 'lodash';
import Cookies from 'universal-cookie';

import getEnv from 'utils/getEnv';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('SessionStorage');

const domain = {
  localhost: 'localhost',
  test: 'localhost',
  dev: 'my.loci.dev',
  prod: 'my.loci.ca',
};

const tagName = {
  prefix: 'CognitoIdentityServiceProvider',
  keys: [
    'deviceKey',
    'idToken',
    'accessToken',
    'refreshToken',
    'clockDrift',
    'LastAuthUser',
    'aws-amplify-federatedInfo',
  ],
  encodedKeys: {
    deviceKey: 'udk',
    idToken: 'uit',
    accessToken: 'uat',
    refreshToken: 'urt',
    clockDrift: 'ucd',
    LastAuthUser: 'ulu',
    'aws-amplify-federatedInfo': 'ufi',
  },
  regex: null,
};

const ONE_DAY = 864E5;
const ONE_MONTH = ONE_DAY * 30;

/**
 * SessionStorage Class
 *
 * Used to obfuscate security tokens with Amplify Auth.
 */
export default class SessionStorage {
  static syncPromise;

  static cookies;

  static expiration;

  static domain;

  /**
   * Sets static values and returns self, used by Amplify.Auth
   *
   * @returns {SessionStorage}
   */
  static init() {
    this.syncPromise = null;
    this.cookies = new Cookies();
    this.expiration = ONE_DAY;
    this.domain = domain[getEnv()];
    // eslint-disable-next-line no-useless-escape
    tagName.regex = new RegExp(`^(${tagName.prefix})\.([a-z0-9]+\.[a-z0-9\-]+)\.(${tagName.keys.join('|')})$`, 'i');
    return this;
  }

  /**
   * Syncs data to storage (cookie).
   * Handle AsyncStorage for React Native
   *
   * @returns {object|Promise}
   */
  static sync() {
    if (!this.syncPromise) {
      this.syncPromise = new Promise((resolve) => resolve())
        .then((data) => dev.info(data))
        .catch((err) => dev.info(err));
    }
    return this.syncPromise;
  }

  /**
   * The promise returned from sync function
   * Set item with the key
   *
   * @param {string} key
   * @param {string} val
   *
   * @returns {string|null}
   */
  static setItem(key, val) {
    const matches = tagName.regex.exec(key);
    if (matches && matches.length === 4) {
      dev.info('setItem', matches[3]);
      const encodedKey = this.encodeKey(matches[3]);
      const encodedVal = this.encodeVal(val);

      this.cookies.set(encodedKey, encodedVal, {
        domain: this.domain,
        path: '/', // Important otherwise we get doubles.
        sameSite: 'lax',
        secure: true, // (process.env.NODE_ENV === 'production'),
        expires: new Date(Date.now() + this.expiration),
      });

      return val;
    }
    return null;
  }

  /**
   * Get item with the key
   *
   * @param {string} key
   * @returns {string|null}
   */
  static getItem(key) {
    const matches = tagName.regex.exec(key);
    if (matches && matches.length === 4) {
      const encodedKey = this.encodeKey(matches[3]);

      // Cookie storage:
      const val = this.cookies.get(encodedKey);
      return this.decodeVal(val);
    }
    return null;
  }

  /**
   * Remove item with the key
   *
   * @param {string} key
   */
  static removeItem(key) {
    const matches = tagName.regex.exec(key);
    if (matches && matches.length === 4) {
      const encodedKey = this.encodeKey(matches[3]);

      // Cookie storage:
      this.cookies.remove(encodedKey, {
        domain: this.domain,
        path: '/',
        secure: true,
      });
      dev.info('removeItem', matches[3]);
    }
  }

  /**
   * Remove all items from storage
   */
  static clear() {
    dev.info('clear');
    _.forEach(tagName.encodedKeys, (item) => this.removeItem(item));
  }

  // Helper methods:

  /**
   * Encode cookie keys
   *
   * @param {string} key
   *
   * @returns {string}
   */
  static encodeKey(key) {
    return tagName.encodedKeys[key];
  }

  /**
   * Encode cookie values
   *
   * @param {string} val
   *
   * @returns {string}
   */
  static encodeVal(val) {
    return btoa(val.split('').reverse().join(''));
  }

  /**
   * Decode cookie values
   *
   * @param {string} val
   *
   * @returns {string|null}
   */
  static decodeVal(val) {
    try {
      return atob(val).split('').reverse().join('');
    } catch (e) {
      return null;
    }
  }

  /**
   * Sets cookie expiration date.
   * Argument can be "short" for 1 day, "long" for one month, or any other number of days.
   *
   * @param {string|number} exp
   */
  static setExpiration(exp) {
    if (exp === 'short') {
      this.expiration = ONE_DAY;
    } else if (exp === 'long') {
      this.expiration = ONE_MONTH;
    } else if (typeof exp === 'number') {
      this.expiration = Math.max(ONE_DAY, ONE_DAY * exp);
    }
  }
}
