import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentFlex: {
    width: '50%',
    height: '60%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25rem',
    order: 1,
    '& .title': {
      color: '#FFFFFF',
      fontSize: '3vw',
      letterSpacing: '0.25rem',
      textShadow: '0 0 15px #00a7ff',
      textAlign: 'center',
      '@media screen and (orientation: portrait)': {
        fontSize: '3vh',
      },
    },
    '& .instruction': {
      maxWidth: '100vw',
      color: '#FFFFFF',
      fontSize: '2.25vw',
      textAlign: 'center',
      textShadow: '0 0 15px #00a7ff',
      fontStyle: 'italic',
      '@media screen and (orientation: landscape)': {
        maxWidth: '30vw',
      },
      '@media screen and (orientation: portrait)': {
        fontSize: '3vh',
      },
    },
    '& img': {
      width: '6vmax',
      [theme.breakpoints.up('md')]: {
        width: '6vmax',
      },
    },
    [theme.breakpoints.up('lg')]: {
      flex: 2,
      height: '50%',
    },
  },
  close: {
    position: 'absolute',
    top: '2vw',
    right: '2vw',
    fontSize: '3.25rem',
  },
  textFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '2px solid #FFFFFF5F',
    padding: '1% 0',
  },
  socialContainer: {
    margin: '2% 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    '& p': {
      fontSize: '2vw',
      '@media screen and (orientation: portrait)': {
        fontSize: '3vh',
      },
    },
  },
  megaphone: {
    marginRight: '3%',
  },
  faq: {
    position: 'absolute',
    top: '2%',
    left: '1%',
    fontStyle: 'italic',
    fontSize: '3vw',
    '@media screen and (orientation: portrait)': {
      fontSize: '3vh',
    },
  },
  faqCTA: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& a': {
      fontSize: '2rem',
      marginRight: '10%',
    },
  },
}));

export default useStyles;
