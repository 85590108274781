import Recoil from 'recoil';

const imageState = Recoil.atom({
  key: 'screenShotState',
  default: {},
});

export {
  imageState,
};
