import { makeStyles } from '@material-ui/core/styles';

const purple2 = '#8a2be285';
const gradientOpaco = 'linear-gradient(180deg, rgb(94 34 164 / 60%) 0%, rgb(24 37 91 / 60%) 100%)';
const borderWhite = 'solid 0.3vw #ffffff82';
const green = '#3fff00';
const shadowGreen = `0.1vw 0.1vw 1vw ${green}`;
const shadowRed = '0.1vw 0.3vw 1vw red';
const shadowBlue = '0.1vw 0.3vw 1vw dodgerblue';
const greenOpaco = '#2db70066 !important';
const redOpaco = '#ff000054 !important';
const shadowBlack = '0.4vw 0.4vw 1vw #0000009e';
const choicesBtnBase = {
  color: 'white',
  fontSize: '2.8vw',
  background: gradientOpaco,
  border: borderWhite,
  borderRadius: '50%',
  margin: '1%',
  fontFamily: 'sarica',
  minWidth: '8.7vw',
  height: '8.7vw',
};

const baseCssIncorrect = {
  position: 'absolute',
  top: '20%',
  width: '28%',
};

const baseCssCustomIncorrect = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '25%',
};

const baseCssCorrect = {
  position: 'absolute',
  top: '10%',
  left: '-5%',
  width: '35%',
  zIndex: 999,
};


const baseCssCustomCorrect = {
  position: 'absolute',
  bottom: 0,
  left: '0',
  zIndex: 999,
  width: '25%',
  // clipPath: 'inset(20px 10px 0px 0px)',
};

const setStyleForAvatar = (custom, status) => {
  switch (status) {
    case 'correct':
      if (custom) return baseCssCustomCorrect;
      return baseCssCorrect;
    case 'incorrect':
      if (custom) return baseCssCustomIncorrect;
      return baseCssIncorrect;
    default:
      throw new Error('Status invalid no styles found');
  }
};


const useStyles = makeStyles({
  // New CSS specific to avatar position
  avatarPosition: props => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& img': {
      ...setStyleForAvatar(props.custom, 'incorrect'),
      ...setStyleForAvatar(props.custom, 'correct'),
    },
  }),
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: purple2,
  },
  questionContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '3%',
  },
  question: {
    color: 'white',
    background: gradientOpaco,
    fontFamily: 'sarica',
    fontSize: '2.4vw',
    textAlign: 'center',
    width: '60%',
    borderRadius: '1em',
    padding: '1.5%',
    top: '15%',
    border: borderWhite,
  },
  correct: {
    fontFamily: 'spyitalic',
    fontSize: '3.6vw',
    textAlign: 'center',
    textShadow: shadowGreen,
  },
  incorrect: {
    fontFamily: 'spyitalic',
    fontSize: '3.6vw',
    textAlign: 'center',
    textShadow: shadowRed,
  },
  horatioIncorrect: props => ({
    ...setStyleForAvatar(props.custom, 'incorrect'),
  }),
  horatioCorrect: props => ({
    ...setStyleForAvatar(props.custom, 'correct'),
  }),
  surfaceContent: {
    color: 'white',
    position: 'absolute',
    bottom: '2%',
    right: '2%',
    textAlign: 'right',
    textShadow: shadowBlue,
    lineHeight: '2.5vw',
  },
  surfaceTitle: {
    fontSize: '2.5vw',
    fontFamily: 'spyitalic',
  },
  surfaceInstruction: {
    fontSize: '2vw',
    fontFamily: 'sarica',
  },
  nextButton: {
    position: 'absolute',
    bottom: '2%',
    right: '2%',
    zIndex: 9,
  },
  choicesContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  choicesBtn: {
    ...choicesBtnBase,
  },
  choicesBtnRed: {
    ...choicesBtnBase,
    background: 'transparent',
    borderColor: 'red',
    backgroundColor: redOpaco,
  },
  choicesBtnGreen: {
    ...choicesBtnBase,
    background: 'transparent',
    borderColor: 'lime',
    backgroundColor: greenOpaco,
  },
  locateContainer: {
    position: 'absolute',
    bottom: '2%',
    left: '2%',
  },
  locateIcon: {
    width: '8%',
    verticalAlign: 'bottom',
    marginRight: '1vw',
    transform: 'rotate(15deg)',
  },
  locateText: {
    fontFamily: 'sarica',
    fontSize: '3vw',
    verticalAlign: 'bottom',
    fontStyle: 'italic',
    textShadow: shadowBlack,
  },
  shadowFrame: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    boxShadow: 'inset 0 0 4vw #5b22a0',
    background: 'transparent',
    border: 0,
  },
});

export default useStyles;
