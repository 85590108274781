import React from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import moment from 'moment';
import useSound from 'use-sound';
import {
  questionState,
  levelState,
  levelScore,
  answerState,
  maxTimeOutMs,
  germHotSpotProgress,
  germHotSpotState,
  germHotSpotCollection,
  correctState,
  incorrectState,
  noGerms,
  questionsAnsweredCount,
  selectedLanguageState,
  customURL,
} from 'store/atoms';
import {
  LEVEL_PROGRESS,
  ANSWER_PROGRESS,
  LEVEL_TYPES,
  GERM_HOT_SPOT_PROGRESS,
} from 'config/game';
import {
  GermIcons,
} from 'components/UI';
import questions from 'config/questions';
import useLocalization from 'hooks/useLocalization';
import useTrackAnswers from 'hooks/useTrackAnswers';
import DevConsole from 'utils/DevConsole';
import { detectLang } from 'utils/detectLang';
import correct2 from 'assets/sounds/correct2.mp3';
import wrongAnswer from 'assets/sounds/wronganswer.mp3';
// Direct Children
import RenderPolygons from './RenderPolygons';
import HotSpotInstructions from './HotSpotInstructions';
import PlayerAnswer from './PlayerAnswer';
import ValidateAnswer from './ValidateAnswer';

// custom css
import useStyles from './styles';

const dev = new DevConsole('GermHotSpots');

// Memoized since it relies on changing props from parent
const GermHotSpots = ({ changeQuestionState, changeLevelProgress, changeLevelScore }) => {
  dev.log('GermHotSpots Rendered');
  const totalSpots = [];

  // Game State
  const currentQuestionState = useRecoilValue(questionState);
  const currentLevelState = useRecoilValue(levelState);
  const currentLevelScore = useRecoilValue(levelScore);
  const setCurrentAnswerState = useSetRecoilState(answerState);
  const [currentGermHotSpotProgress, setCurrentGermHotSpotProgress] = useRecoilState(germHotSpotProgress);
  const currentMaxTimeOutMs = useRecoilValue(maxTimeOutMs);
  const [currentCorrectState, setCurrentCorrectState] = useRecoilState(correctState);
  const [currentIncorrectState, setCurrentIncorrectState] = useRecoilState(incorrectState);
  const [currentGermHotSpotState, setCurrentGermHotSpotState] = useRecoilState(germHotSpotState);
  const [currentGermHotSpotCollection, setCurrentGermHotSpotCollectionState] = useRecoilState(germHotSpotCollection);
  const [currentNoGerms, setCurrentNoGermState] = useRecoilState(noGerms);
  const [currentQuestionsAnsweredCount, setCurrentQuestionsAnsweredCount] = useRecoilState(questionsAnsweredCount);

  const [playCorrect] = useSound(correct2);
  const [playWrong] = useSound(wrongAnswer, { volume: 0.50 });

  const hotSpots = questions[currentLevelState.key][`QUESTION_${currentQuestionState}`];
  const maxQuestions = Object.keys(questions[currentLevelState.key]).length;
  let totalGermHotSpots = 0;
  const maxTimeShort = 500;

  const isCustomURL = useRecoilValue(customURL);

  // Localization
  const selectedLang = useRecoilValue(selectedLanguageState);
  const tGlobal = useLocalization('Global');
  let tQuestions;

  // CSS PROPS
  const props = {
    language: detectLang(selectedLang, 'cr'),
    custom: isCustomURL,
  };
  const classes = useStyles(props);

  // From useTrackAnswers: tracks player answers and creates a new item in the answersList
  const debouncedAnswer = useTrackAnswers();

  if (currentLevelState.type === LEVEL_TYPES.GERM_HOT_SPOTS && currentGermHotSpotState) {
    if (questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].translationScene) {
      tQuestions = useLocalization(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].translationScene);
    }
  } else {
    tQuestions = useLocalization(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].translationScene);
  }

  if (Object.prototype.hasOwnProperty.call(hotSpots, 'HOT_SPOTS')) {
    totalGermHotSpots = Object.keys(hotSpots.HOT_SPOTS).length;
  }

  for (let i = 0; i < totalGermHotSpots; i += 1) {
    totalSpots.push(i);
  }

  const nextQuestionHandler = () => {
    setCurrentCorrectState(null);
    setCurrentIncorrectState(null);
    if (currentGermHotSpotCollection.length < totalGermHotSpots && currentLevelState.type === LEVEL_TYPES.GERM_HOT_SPOTS) {
      dev.log(`currentGermHotSpotCollection.length: ${currentGermHotSpotCollection.length} < totalGermHotSpots: ${totalGermHotSpots}`);
      setCurrentGermHotSpotProgress(GERM_HOT_SPOT_PROGRESS.SELECTION);
    } else if (currentQuestionState < maxQuestions) {
      dev.log(`currentQuestionState: ${currentQuestionState} < maxQuestions: ${maxQuestions}`);
      changeQuestionState(currentQuestionState + 1);

      if (currentLevelState.type === LEVEL_TYPES.GERM_HOT_SPOTS) {
        setCurrentGermHotSpotCollectionState([]);
        setCurrentGermHotSpotProgress(GERM_HOT_SPOT_PROGRESS.SELECTION);
      }
    } else {
      dev.log('else');
      changeLevelProgress(LEVEL_PROGRESS.CONGRATULATION);

      if (currentLevelState.type === LEVEL_TYPES.GERM_HOT_SPOTS) {
        setCurrentGermHotSpotCollectionState([]);
        setCurrentGermHotSpotState('');
        setCurrentGermHotSpotProgress(GERM_HOT_SPOT_PROGRESS.SELECTION);
      }
    }
    setCurrentAnswerState(ANSWER_PROGRESS.UNANSWERED);
  };

  const validateAnswer = (value, correctAnswer, maxAttempt) => {
    dev.log(`value: ${value}, correctAnswer: ${correctAnswer}, maxAttempt: ${maxAttempt}`);
    const qtState = questions[currentLevelState.key][`QUESTION_${currentQuestionState}`];
    const keyword = `${currentLevelState.key}_QUESTION_${currentQuestionsAnsweredCount + 1}`;
    const cquestion = qtState.HOT_SPOTS[currentGermHotSpotState].question;
    const dateUpdated = moment().format('YYYY-MM-DD HH:mm:ss.SS');

    setCurrentQuestionsAnsweredCount(currentQuestionsAnsweredCount + 1);

    if (!currentCorrectState && !currentIncorrectState) {
      if (value === correctAnswer) {
        setCurrentCorrectState(value);
        changeLevelScore(currentLevelScore[currentLevelState.key] + 1);
        setCurrentAnswerState(ANSWER_PROGRESS.CORRECT);

        debouncedAnswer(keyword, cquestion, correctAnswer, value, ANSWER_PROGRESS.CORRECT, dateUpdated, currentQuestionsAnsweredCount);
        playCorrect();
        dev.log('IS THE CORRECT SOUND PLAYING?', playCorrect);
      } else {
        dev.log('Got wrong answer');
        playWrong();
        dev.log('IS THE WRONG SOUND PLAYING?', playWrong);
        setCurrentIncorrectState(value);
        setCurrentAnswerState(ANSWER_PROGRESS.TRY_AGAIN);
        debouncedAnswer(keyword, cquestion, correctAnswer, value, ANSWER_PROGRESS.INCORRECT, dateUpdated, currentQuestionsAnsweredCount);
        setTimeout(() => {
          setCurrentCorrectState(qtState.HOT_SPOTS[currentGermHotSpotState].correctAnswer);
        }, maxTimeShort);
      }
    }
  };

  const showQuestion = (germhotspot) => {
    setCurrentGermHotSpotCollectionState(prev => [...prev, germhotspot]);
    setTimeout(() => {
      setCurrentGermHotSpotState(germhotspot);
      setCurrentGermHotSpotProgress(GERM_HOT_SPOT_PROGRESS.QUESTION);
    }, maxTimeShort);
    dev.log({ germhotspot });
  };

  const showNoGermsText = () => {
    setCurrentNoGermState(true);
    setTimeout(() => {
      setCurrentNoGermState(false);
    }, currentMaxTimeOutMs);
  };

  dev.log({ currentGermHotSpotState });
  return (
    <>
      <GermIcons currentGermHotSpotCollection={currentGermHotSpotCollection} />
      <button
        type="button"
        label="Surname"
        className={classes.shadowFrame}
        onClick={showNoGermsText}
      />
      <RenderPolygons
        hotSpotsObject={questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS}
        collection={currentGermHotSpotCollection}
        showQuestion={showQuestion}
      />
      <HotSpotInstructions
        hotSpotProgress={currentGermHotSpotProgress}
        question={GERM_HOT_SPOT_PROGRESS.QUESTION}
        currentQuestion={questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS}
        currentNoGerms={currentNoGerms}
        tGlobal={tGlobal}
      />
      <PlayerAnswer
        selectedLang={selectedLang}
        tGlobal={tGlobal}
        tQuestions={tQuestions}
        nextQuestionHandler={nextQuestionHandler}
      />
      <ValidateAnswer
        currentGermHotSpotState={currentGermHotSpotState}
        currentProgress={currentGermHotSpotProgress}
        tQuestions={tQuestions}
        validateAnswer={validateAnswer}
      />
    </>
  );
};

GermHotSpots.propTypes = {
  changeQuestionState: PropTypes.func,
  changeLevelProgress: PropTypes.func,
  changeLevelScore: PropTypes.func,
};

GermHotSpots.defaultProps = {
  changeQuestionState: {},
  changeLevelProgress: {},
  changeLevelScore: {},
};

export default GermHotSpots;
