/**
 * Organizations API
 *
 * Wraps around the Organizations API endpoint. For fetching data related to any tables we use
 */
import API from 'API';
import mockData from './mockData';

const api = new API();
const service = 'organization';
const path = '/organization';

const organization = {
  mock: () => api.setMock(mockData),
  live: () => api.setMock(null),
  get: query => api.exec({
    action: 'get',
    service,
    path,
    query,
  }),
  list: () => {
    const res = api.exec({
      action: 'get',
      service,
      path,
    });
    return res;
  },
  create: query => api.exec({
    action: 'post',
    service,
    path,
    query,
  }),
  update: query => api.exec({
    action: 'put',
    service,
    path,
    query,
  }),
  delete: query => api.exec({
    action: 'del',
    service,
    path,
    query,
  }),
};

export default organization;
