/**
 * guest API
 *
 * Wraps around the Feature Flags API endpoint.
 */
import API from 'API';
import mockData from './mockData';

const api = new API();
const service = 'guest';
const path = '/guest';

const guest = {
  mock: () => api.setMock(mockData),
  live: () => api.setMock(null),
  get: payload => api.exec({
    action: 'get',
    service,
    path,
    payload,
  }),
  create: init => api.exec({
    action: 'post',
    service,
    path,
    init: {
      headers: init.headers,
      body: init.body,
    },
  }),
  update: mixedData => api.exec({
    action: 'put',
    service,
    path,
    payload: {
      companyId: mixedData.payload.companyId,
      guestId: mixedData.payload.guestId,
    },
    init: {
      headers: mixedData.headers,
      body: mixedData.body,
    },
  }),
  delete: payload => api.exec({
    action: 'del',
    service,
    path,
    payload: {
      companyId: payload.companyId,
      guestId: payload.guestId,
    },
  }),
};

export default guest;

// group
// guest-group
// guest-plays
// guest-avg-score
