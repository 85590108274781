import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'block',
    border: 'none',
    overflow: 'hidden',
    '@media screen and (max-width: 650px)': {
      width: '575px',
      height: '300px',
      margin: '5% auto',
      border: '1px solid white',
      overflow: 'hidden',
    },
    '@media screen and (min-width: 1000px)': {
      width: '960px',
      height: '475px',
      margin: '5% auto',
      border: '1px solid white',
      borderRadius: '5px',
    },
    '@media screen and (min-height: 475px)': {
      width: '960px',
      height: '475px',
      margin: '8% auto',
      border: '1px solid white',
      borderRadius: '5px',
    },
  },
}));

export default useStyles;
