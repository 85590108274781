/**
 * Feature Component
 *
 * This component is used to enable/disable features.
 */

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Recoil from 'recoil';
import {
  featuresState,
} from 'store/atoms';
import _ from 'lodash';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('Feature');


/**
 * Feature Component
 *
 * Looks into the feature flags list & enables/disables component as specified.
 * We can also specify a fallback component when it's disabled. This is how we do A/B testing.
 *
 * @param {object} props
 * @param {string} [props.uuid] - The id to lookup in the features list.
 * @param {string} [props.keyword] - The keyword to lookup in the features list.
 * @param {React.Component|null} [props.component] - The component to flag.
 * @param {React.Component|null} [props.fallback] - Optional fallback component if flag is disabled.
 * @param {React.Component|null} [props.children] - Optional wrapped child to use instead of component to flag.
 *
 * @returns {React.Component}
 */
function Feature(props) {
  const {
    uuid,
    keyword,
    component,
    fallback,
    children,
  } = props;
  const [ratio, setRatio] = useState(0);
  const features = Recoil.useRecoilValue(featuresState);

  // Set A/B ratio [1-100] on first run:
  useEffect(() => setRatio(Math.ceil(Math.random() * 100)), []);

  // Find feature:
  const feature = useMemo(() => features.find(f => {
    if (uuid) {
      return f.uuid === uuid;
    }
    if (keyword) {
      return _.upperFirst(_.camelCase(f.keyword)) === keyword;
    }
    return undefined;
  }) || null, [features]);

  // Define child:
  const child = useMemo(() => {
    return React.Children.count(children) ? React.Children.only(children) : component;
  }, [children, component]);

  const Component = useMemo(() => {
    // Wait for ratio to be calculated:
    if (ratio === 0) {
      return null;
    }

    // If we have a feature and a child to display:
    if (feature !== null && child !== null) {
      if (feature.enabled) {
        dev.info(`${uuid || keyword} rendered`);
        return ratio <= feature.ratio ? child : fallback;
      }
      if (fallback) {
        dev.info(`${uuid || keyword} disabled, rendering fallback`);
        return fallback;
      }
      dev.info(`${uuid || keyword} disabled.`);
      return null;
    }

    // Otherwise we crap out:
    dev.error(`${uuid || keyword} does not exist!`);
    return null;
  }, [feature, ratio]); // If we put child & fallback here, memo re-renders every time child changes, so for now we won't.

  return Component
    ? React.cloneElement(Component, feature.props)
    : null;
}

Feature.propTypes = {
  uuid: PropTypes.string,
  keyword: PropTypes.string,
  component: PropTypes.node,
  fallback: PropTypes.node,
  children: PropTypes.node,
};

Feature.defaultProps = {
  uuid: null,
  keyword: null,
  component: null,
  fallback: null,
  children: null,
};

export default Feature;
