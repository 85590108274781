import React, { useEffect, useState } from 'react';
import API from 'API';
import { localization } from 'API/services';
import _ from 'lodash';
import cachedLanguages from 'config/languages';
import ReactDOM from 'react-dom';
import gsiLogo from 'assets/default/labels/gsi_transparent@3x.png';
import useLocalization from 'hooks/useLocalization';
import {
  Grid,
  Text,
  Asset,
  Form,
  Language,
  Modal,
  CustomAsset,
  PrivacyPolicy,
} from 'components/UI';
import {
  formModal,
  langModal,
  translationState,
  languageState,
  selectedLanguageState,
  customURL,
  policyTextState,
} from 'store/atoms';
import { detectLang } from 'utils/detectLang';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import DevConsole from 'utils/DevConsole';
import rotateIcon from 'assets/default/icons/rotate-icon.png';
import rotateAndroid from 'assets/default/icons/android-rotate.png';
import rotateIOS from 'assets/default/icons/ios-rotate.png';
import question from 'assets/default/icons/question-icon-hex@3x.png';
import language from 'assets/default/icons/language-selector-icon-only@3x.png';
import thumbUp from 'assets/default/horatio/horatio-thumbs-up-cropped@3x.png';
import useStyles from './styles';
import privacyPolicyMd from './privacy-policy.md';

const dev = new DevConsole('Rotation');
const api = new API();

/**
 * Rotation is rendered when the screen width reaches portrait size
 * as the game can only be played in landscape mode.
 *
 * @returns {React.Component}
 */
const Rotation = () => {
  const [formModalOpen, setFormModalOpen] = useRecoilState(formModal);
  const [langModalOpen, setFaqModalOpen] = useRecoilState(langModal);
  const [policyText, setPolicyText] = useRecoilState(policyTextState);

  // Translations
  const setTranslations = useSetRecoilState(translationState);
  const [languages, setLanguages] = useRecoilState(languageState);
  const selectedLang = useRecoilValue(selectedLanguageState);

  // Custom Asset State
  const isCustomURL = useRecoilValue(customURL);

  const [initializing, setInitializing] = useState(true);


  const props = {
    language: detectLang(selectedLang, 'cr'),
    currentLang: selectedLang,
    isCustomURL,
  };

  const classes = useStyles(props);

  const handleFormOpen = () => {
    setFormModalOpen(true);
  };

  const handleLangOpen = () => {
    setFaqModalOpen(true);
  };

  const onClose = () => {
    setFormModalOpen(false);
    setFaqModalOpen(false);
  };

  const detectApple = () => {
    return navigator.appVersion.includes('iPhone') ? <Asset className={classes.rotateIcon} src={rotateIOS} />
      : <Asset className={classes.rotateIcon} src={rotateAndroid} />;
  };

  // Only the grab the data we need for this component to work
  useEffect(() => {
    api.configure();
    dev.log('useEffect fired');
    (async () => {
      const APIGroups = await localization.group.list({
        productId: 'GSI',
      });
      const APITranslations = await localization.translation.list({
        productId: 'GSI',
      });
      const APILanguages = await localization.language.list({
        productId: 'GSI',
        active: true,
      });

      // PrivacyPolicy for rotation
      const policyContent = await fetch(privacyPolicyMd);
      const policyResult = await policyContent.text();
      setPolicyText(policyResult);

      setTranslations({
        groups: APIGroups.items,
        items: APITranslations.items,
      });
      setLanguages(_.orderBy(APILanguages.items, ['listOrder']) || cachedLanguages);
      setInitializing(false);
    })();
  }, []);

  const t = useLocalization('Rotate');
  dev.log({ languages });

  const renderLoadingOrPage = () => {
    if (initializing) {
      return <Asset isLoadingImg src={gsiLogo} />;
    }
    return (
      <>
        <Grid className={classes.root} container>
          <Asset src={question} className={classes.question} onClick={handleFormOpen} />
          <div className={classes.language}>
            <Text family="Rubik" color="primary">Translate</Text>
            <Asset src={language} onClick={handleLangOpen} />
          </div>
          <Modal open={formModalOpen} onClose={onClose}>
            <Form />
          </Modal>
          <Modal open={langModalOpen} onClose={onClose}>
            <Language />
          </Modal>
          <Grid xs={12} className={classes.rotate} item>
            <section>
              <Asset src={rotateIcon} className={classes.rotateIcon} />
              {detectApple()}
            </section>
            <Text className={classes.text} family="chollasansbold">{t('headline') || 'ROTATE PHONE TO PLAY'}</Text>
            <Text className={classes.subtext} family="chollasansbold">{t('subtext') || 'Turn off orientation lock to rotate'}</Text>
          </Grid>
          <Grid xs={12} className={classes.container} item>
            <CustomAsset
              defaultSrc={thumbUp}
              customSrc="leothumbsupx3.png"
              slash="bclions"
              cloudFront
              className={classes.horatioCorrect}
            />
          </Grid>
        </Grid>
        <PrivacyPolicy
          policyContent={policyText}
        />
      </>
    );
  };



  dev.log('Initializing', { initializing });

  return ReactDOM.createPortal(
    renderLoadingOrPage(),
    document.getElementById('orientation'),
  );
};

export default Rotation;

