import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  fsAction: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    right: 2,
    zIndex: 999,
    color: '#FFFFFF',
    fontSize: '5vw',
    filter: 'drop-shadow(0 0 15px #00a7ff)',
    '& :hover': {
      cursor: 'pointer',
    },
  },
});

export default useStyles;
