import _ from 'lodash';
import {
  LEVELS,
  LEVEL_TYPES,
} from 'config/game';
import questions from 'config/questions';

/** START: Calculate the total scores and give the respective grade takes in the levelScore atom
 *
 * @param {object} levelState - from the levelScoreAtom
 * @returns {object}
 */
export const getScores = (levelState) => {
  const maxQuestionsPerLevel = {};
  let totalScore = 0;
  let totalQuestion = 0;
  let totalPercent = 0;

  _.map(levelState, (value, key) => {
    let maxQuestions = 0;
    totalScore += value;

    if (LEVELS[key].type === LEVEL_TYPES.GERM_HOT_SPOTS) {
      _.map(questions[key], (valueL) => {
        const totalHotSpotQuestion = Object.keys(valueL.HOT_SPOTS).length;
        maxQuestions += totalHotSpotQuestion;
      });
    } else {
      maxQuestions = Object.keys(questions[key]).length;
    }
    maxQuestionsPerLevel[key] = maxQuestions;
    totalQuestion += maxQuestions;
  });

  totalPercent = (totalScore / totalQuestion) * 100;

  return {
    maxQuestionsPerLevel,
    totalPercent,
    totalQuestion,
    totalScore,
  };
};
