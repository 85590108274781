import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { translationState, languageState, selectedLanguageState } from 'store/atoms';
import { cleanTranslation } from 'utils/sanitize';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('uselocalization');

/**
 * uselocalizationData Custom Hook
 *
 * Returns translations filtered by Scene Name.
 *
 * Use this hook in components that require translations.
 *
 * @param {string} sceneName - Scene name to filter translations by.
 * @param {boolean} [full] - If true, return entire item row, not just translated text.
 *
 * @returns {Function}
 */
function useLocalization(sceneName, full = false) {
  const translations = useRecoilValue(translationState);
  const languages = useRecoilValue(languageState);
  const selectedLanguage = useRecoilValue(selectedLanguageState);
  const lang = languages?.find(l => l.code === selectedLanguage)?.code || 'en';

  dev.group('useLocalization');
  dev.log({ translations, languages, selectedLanguage, lang });
  dev.log(`sceneName: ${sceneName}, selectedLanguage: ${JSON.stringify(selectedLanguage)}`);
  dev.ungroup();

  try {
    if (translations.groups.length && translations.items.length) {
      const sceneGroup = _.find(translations.groups, g => cleanTranslation(g.groupName) === cleanTranslation(sceneName));
      const sceneTranslations = _.filter(translations.items, t => t.groupId === sceneGroup.uuid);

      return (keyword) => {
        try {
          const translation = _.find(sceneTranslations, t => cleanTranslation(t.keyword) === cleanTranslation(keyword));
          return full ? translation : translation.translations[lang] || translation.translations.en;
        } catch (e) {
          dev.error(e);
          return full ? {} : '';
        }
      };
    }
  } catch (e) {
    dev.error(`Translation not found for "${cleanTranslation(sceneName)}"`, [...translations.groups.map(g => g.groupName)]);
  }

  return () => (full ? {} : '');
}

export default useLocalization;
