/**
 * Locations API endpoint
 *
 * Wrap around utility for our locations microservice.
 */
import API from 'API';
import mockData from './mockData';

const api = new API();
const service = 'location';
const path = '/location';

const location = {
  mock: () => api.setMock(mockData),
  live: () => api.setMock(null),
  get: query => api.exec({
    action: 'get',
    service,
    path,
    query,
  }),
  create: query => api.exec({
    action: 'post',
    service,
    path,
    query,
  }),
  listAll: () => {
    const res = api.exec({
      action: 'get',
      service,
      path,
    });
    return res;
  },
};

export default location;
