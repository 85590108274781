import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  questionTop: {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 999,
    width: '6%',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('sm')]: {
      width: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '4%',
    },
    '@media screen and (orientation: portrait)': {
      top: 0,
      width: '15%',
    },
  },
  questionBottom: {
    position: 'absolute',
    bottom: 0,
    left: 2,
    zIndex: 999,
    width: '6%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
