import React from 'react';
import PropTypes from 'prop-types';
import {
  useRecoilValue,
} from 'recoil';
import {
  questionState,
  levelState,
  correctState,
  incorrectState,
} from 'store/atoms';
import { LEVEL_TYPES, GERM_HOT_SPOT_PROGRESS } from 'config/game';
import questions from 'config/questions';
import { Button } from 'components/UI';
import DevConsole from 'utils/DevConsole';
import _ from 'lodash';

import useStyles from './styles';


const dev = new DevConsole('ValidateAnswer');
/**
 * Answer Validation for PlayerAnswer parent component
 *
 * @param {*} props
 * @returns {React.Component}
 */
const ValidateAnswer = ({ ...props }) => {
  const currentQuestionState = useRecoilValue(questionState);

  // Global Correct/Incorrect
  const currentCorrectState = useRecoilValue(correctState);
  const currentIncorrectState = useRecoilValue(incorrectState);

  // Global Level State
  const currentLevelState = useRecoilValue(levelState);

  // Functions from parent
  const {
    currentProgress,
    currentGermHotSpotState,
    tQuestions,
    validateAnswer,
  } = props;

  dev.log({
    currentLevelState,
    currentProgress,
    currentGermHotSpotState,
    currentQuestionState,
  });

  // custom css
  const classes = useStyles();

  return currentLevelState.type === LEVEL_TYPES.GERM_HOT_SPOTS && (
    <>
      {currentProgress === GERM_HOT_SPOT_PROGRESS.QUESTION && (
        <div className={classes.choicesContent}>
          {
            _.map(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].choices, (value, key) => {
              return (
                <Button
                  type="button"
                  variant="text"
                  className={`${currentIncorrectState === value ? classes.choicesBtnRed : classes.choicesBtn} ${currentCorrectState === value ? classes.choicesBtnGreen : classes.choicesBtn}`}
                  onClick={() => {
                    validateAnswer(
                      value,
                      questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].correctAnswer,
                      Object.keys(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].choices).length - 1,
                    );
                  }}
                  key={key}
                >
                  {tQuestions(key) || value}
                </Button>
              );
            })
          }
        </div>
      )}
    </>
  );
};

ValidateAnswer.propTypes = {
  currentGermHotSpotState: PropTypes.string.isRequired,
  currentProgress: PropTypes.string.isRequired,
  validateAnswer: PropTypes.func.isRequired,
  tQuestions: PropTypes.func.isRequired,
};

export default ValidateAnswer;
