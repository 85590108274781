import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import useSound from 'use-sound';
import {
  questionState,
  levelState,
  answerState,
  levelScore,
  correctState,
  incorrectState,
  showNext,
  questionsAnsweredCount,
  selectedLanguageState,
  customURL,
} from 'store/atoms';
import { LEVEL_PROGRESS, ANSWER_PROGRESS } from 'config/game';
import { Action, CustomAsset, Button, Feature } from 'components/UI';
import questions from 'config/questions';
import useLocalization from 'hooks/useLocalization';
import useTrackAnswers from 'hooks/useTrackAnswers';
import thumbUp from 'assets/default/horatio/horatio_up-min.png';
import thumbDown from 'assets/default/horatio/horatio_down-min.png';
import DevConsole from 'utils/DevConsole';
import { detectLang } from 'utils/detectLang';
import correct2 from 'assets/sounds/correct2.mp3';
import wrongAnswer from 'assets/sounds/wronganswer.mp3';
import useStyles from './styles';

const dev = new DevConsole('QuestionScene');
dev.mute();

const MultipleChoice = React.memo(({ changeQuestionState, changeLevelProgress, changeLevelScore }) => {
  // Game State
  const currentQuestionState = useRecoilValue(questionState);
  const currentLevelState = useRecoilValue(levelState);
  const currentLevelScore = useRecoilValue(levelScore);
  const [currentAnswerState, setCurrentAnswerState] = useRecoilState(answerState);
  const [currentCorrectState, setCurrentCorrectState] = useRecoilState(correctState);
  const [currentIncorrectState, setCurrentIncorrectState] = useRecoilState(incorrectState);
  const [currentShowNext, setCurrentShowNextState] = useRecoilState(showNext);
  const [playCorrect] = useSound(correct2);
  const [playWrong] = useSound(wrongAnswer, { volume: 0.50 });
  const maxQuestions = Object.keys(questions[currentLevelState.key]).length;
  const maxTimeShort = 500;

  const isCustomURL = useRecoilValue(customURL);

  // Localization
  const selectedLang = useRecoilValue(selectedLanguageState);
  const tGlobal = useLocalization('Global');
  const tQuestions = useLocalization(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].translationScene);
  const [currentQuestionsAnsweredCount, setCurrentQuestionsAnsweredCount] = useRecoilState(questionsAnsweredCount);

  // CSS PROPS
  const props = {
    language: detectLang(selectedLang, 'cr'),
    custom: isCustomURL,
  };
  const classes = useStyles(props);

  // From useTrackAnswers: tracks player answers and creates a new item in the answersList
  const debouncedAnswer = useTrackAnswers();

  const next = () => {
    setCurrentShowNextState(false);
    setCurrentCorrectState(null);
    setCurrentIncorrectState(null);
    setTimeout(() => {
      if (currentQuestionState < maxQuestions) {
        changeQuestionState(currentQuestionState + 1);
      } else {
        changeLevelProgress(LEVEL_PROGRESS.CONGRATULATION);
      }
      setCurrentAnswerState(ANSWER_PROGRESS.UNANSWERED);
    }, 100);
  };

  const validateAnswer = (value, correctAnswer, maxAttempt) => {
    dev.log(`value: ${value}, correctAnswer: ${correctAnswer}, maxAttempt: ${maxAttempt}`);
    const qtState = questions[currentLevelState.key][`QUESTION_${currentQuestionState}`];
    const rightQuestion = qtState.correctAnswer;
    const keyword = `${currentLevelState.key}_QUESTION_${currentQuestionState}`;
    const cquestion = qtState.question;
    const dateUpdated = moment().format('YYYY-MM-DD HH:mm:ss.SS');

    setCurrentQuestionsAnsweredCount(currentQuestionsAnsweredCount + 1);

    if (!currentCorrectState && !currentIncorrectState) {
      if (value === correctAnswer) {
        setCurrentCorrectState(value);
        changeLevelScore(currentLevelScore[currentLevelState.key] + 1);
        setCurrentAnswerState(ANSWER_PROGRESS.CORRECT);
        playCorrect();
        setCurrentShowNextState(true);
        debouncedAnswer(keyword, cquestion, correctAnswer, value, ANSWER_PROGRESS.CORRECT, dateUpdated, currentQuestionsAnsweredCount);
        dev.log('IS THE CORRECT SOUND PLAYING?', playCorrect);
      } else {
        dev.log('Got wrong answer');
        playWrong();
        dev.log('IS THE WRONG SOUND PLAYING?', playWrong);
        setCurrentIncorrectState(value);
        setCurrentAnswerState(ANSWER_PROGRESS.INCORRECT);
        debouncedAnswer(keyword, cquestion, correctAnswer, value, ANSWER_PROGRESS.INCORRECT, dateUpdated, currentQuestionsAnsweredCount);
        setTimeout(() => {
          setCurrentCorrectState(rightQuestion);
          setCurrentShowNextState(true);
        }, maxTimeShort);
      }
    }
  };

  const checkWidth = (items) => {
    return items.find(item => item.length > 2);
  };

  return (
    <>
      {(currentCorrectState || currentIncorrectState) && (
        <>
          <div className={classes.wrapper} />
          {currentShowNext && (
            <div className={classes.nextButton}>
              <Action text={tGlobal('NEXT') || 'NEXT'} action={next} />
            </div>
          )}
        </>
      )}
      {(currentAnswerState !== ANSWER_PROGRESS.TRY_AGAIN) && (
        <>
          <div
            className={classes.questionContent}
          >
            <div className={classes.question}>
              {currentAnswerState === ANSWER_PROGRESS.CORRECT && (
                <>
                  <div className={classes.correct}>
                    {tGlobal('CORRECT') || 'CORRECT'}
                  </div>
                </>
              )}
              {(currentAnswerState === ANSWER_PROGRESS.INCORRECT) && (
                <>
                  <div className={classes.incorrect}>
                    {tGlobal('INCORRECT') || 'INCORRECT'}
                  </div>
                </>
              )}
              {!(currentAnswerState === ANSWER_PROGRESS.CORRECT || currentAnswerState === ANSWER_PROGRESS.INCORRECT) && (
                <>
                  {tQuestions('Question') || questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].question}
                </>
              )}
              {(currentAnswerState === ANSWER_PROGRESS.CORRECT || currentAnswerState === ANSWER_PROGRESS.INCORRECT) && (
                <>
                  {tQuestions(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].promptKeyword) || questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].prompt}
                </>
              )}
            </div>
          </div>
          <section className={classes.avatarPosition}>
            {(currentAnswerState === ANSWER_PROGRESS.INCORRECT) && (
              <>
                <Feature
                  keyword="AssetSwap"
                  fallback={
                    (
                      <CustomAsset
                        defaultSrc={thumbDown}
                        customSrc="leothumbsdownx3.png"
                        slash="bclions"
                        cloudFront
                        className={classes.avatarImg}
                      />
                    )
                  }
                  component={
                    (
                      <CustomAsset
                        defaultSrc={thumbDown}
                        customSrc="BC_Lions_transparent_thumbs_down_full_body_450x450.gif"
                        slash="bclions"
                        cloudFront
                        className={classes.avatarImg}
                      />
                    )
                  }
                />
              </>
            )}
            {currentAnswerState === ANSWER_PROGRESS.CORRECT && (
              <>
                <Feature
                  keyword="AssetSwap"
                  fallback={
                    (
                      <CustomAsset
                        defaultSrc={thumbUp}
                        customSrc="leothumbsupx3.png"
                        slash="bclions"
                        cloudFront
                        className={classes.avatarImg}
                      />
                    )
                  }
                  component={
                    (
                      <CustomAsset
                        defaultSrc={thumbUp}
                        customSrc="BC_Lions_transparent_thumbs_up_full_body_450x450.gif"
                        slash="bclions"
                        cloudFront
                        className={classes.avatarImg}
                      />
                    )
                  }
                />
              </>
            )}
          </section>
        </>
      )}
      <div className={classes.choicesContent}>
        {
          _.map(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].choices, (value, key) => {
            dev.log('I GOT HERE 2KEY');
            dev.log(`2key: ${key}, value: ${JSON.stringify(value)}`);
            return (
              <Button
                type="button"
                variant="text"
                className={`${currentIncorrectState === value ? classes.choicesBtnRed : classes.choicesBtn} ${currentCorrectState === value ? classes.choicesBtnGreen : classes.choicesBtn}`}
                onClick={() => {
                  validateAnswer(
                    value,
                    questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].correctAnswer,
                    Object.keys(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].choices).length - 1,
                  );
                }}
                key={key}
                style={checkWidth(Object.values(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].choices)) ? {
                  width: '16vw',
                  height: '5vw',
                  borderRadius: '1vw',
                } : null}
              >
                {tQuestions(key) || value}
              </Button>
            );
          })
        }
      </div>
    </>
  );
});

MultipleChoice.propTypes = {
  changeQuestionState: PropTypes.func,
  changeLevelProgress: PropTypes.func,
  changeLevelScore: PropTypes.func,
};

MultipleChoice.defaultProps = {
  changeQuestionState: {},
  changeLevelProgress: {},
  changeLevelScore: {},
};

export default MultipleChoice;
