import React from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { questionState, levelState } from 'store/atoms';
import questions from 'config/questions';
import germIcon from 'assets/default/germs/congratulation-bw.png';
import germIconActive from 'assets/default/germs/germ-color.png';
import useStyles from './styles';

const GermIcons = ({ currentGermHotSpotCollection }) => {
  const classes = useStyles();
  const totalSpots = [];
  const currentQuestionState = useRecoilValue(questionState);
  const currentLevelState = useRecoilValue(levelState);
  const hotSpots = questions[currentLevelState.key][`QUESTION_${currentQuestionState}`];
  let totalGermHotSpots = 0;
  if (Object.prototype.hasOwnProperty.call(hotSpots, 'HOT_SPOTS')) {
    totalGermHotSpots = Object.keys(hotSpots.HOT_SPOTS).length;
  }
  for (let i = 0; i < totalGermHotSpots; i += 1) {
    totalSpots.push(i);
  }
  return (
    <div data-test="GermIcons">
      {
        _.map(totalSpots, (value, key) => {
          const icon = key + 1 > currentGermHotSpotCollection.length ? germIcon : germIconActive;
          return (
            <img
              src={icon}
              alt="germ-icon"
              key={key}
              className={classes.icon}
            />
          );
        })
      }
    </div>
  );
};

GermIcons.propTypes = {
  currentGermHotSpotCollection: PropTypes.any,
};

GermIcons.defaultProps = {
  currentGermHotSpotCollection: '',
};

export default GermIcons;
