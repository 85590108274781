import { useState } from 'react';
import getURL from 'utils/getURL';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('useCustomAsset');

const useCustomAsset = (customURL, options) => {
  const urlMatches = window.location.href.includes(customURL.toLowerCase());
  const [isCustomURL] = useState(urlMatches);

  const {
    customAsset,
    defaultAsset,
    // This comes from the props of the component we use this for
    cloudFront,
  } = options;

  const distroURL = getURL(true);

  dev.log({ distroURL, cloudFront, isCustomURL });

  // Access bucket based on environment
  const constructedCloudFrontURL = `${distroURL}/${customAsset}`;
  const constructedS3URL = `https://s3.amazonaws.com/${getURL(false)}/bclions/${customAsset}`;
  const customSrc = cloudFront ? constructedCloudFrontURL : constructedS3URL;


  const src = isCustomURL ? customSrc : defaultAsset;

  dev.log({ src });
  return {
    src,
    isCustomURL,
    urlMatches,
  };
};


export default useCustomAsset;
