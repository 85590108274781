import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import useSound from 'use-sound';
import {
  questionState,
  levelState,
  answerState,
  levelScore,
  btnSideState,
  questionsAnsweredCount,
} from 'store/atoms';
import { LEVEL_PROGRESS, ANSWER_PROGRESS, LEVEL_TYPES } from 'config/game';
import { Action, Button } from 'components/UI';
import questions from 'config/questions';
import useLocalization from 'hooks/useLocalization';
import useTrackAnswers from 'hooks/useTrackAnswers';
import DevConsole from 'utils/DevConsole';
import correct2 from 'assets/sounds/correct2.mp3';
import wrongAnswer from 'assets/sounds/wronganswer.mp3';
import useStyles from './styles';

const dev = new DevConsole('QuestionScene');

const SaferActivity = React.memo(({ changeQuestionState, changeLevelProgress, changeLevelScore }) => {
  const currentQuestionState = useRecoilValue(questionState);
  const currentLevelState = useRecoilValue(levelState);
  const currentLevelScore = useRecoilValue(levelScore);
  const [currentAnswerState, setCurrentAnswerState] = useRecoilState(answerState);
  const [currentBtnSideState, setCurrentBtnSideState] = useRecoilState(btnSideState);
  const [playCorrect] = useSound(correct2);
  const [playWrong] = useSound(wrongAnswer, { volume: 0.50 });
  const maxQuestions = Object.keys(questions[currentLevelState.key]).length;
  const tGlobal = useLocalization('Global');
  const tQuestions = useLocalization(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].translationScene);
  const [currentQuestionsAnsweredCount, setCurrentQuestionsAnsweredCount] = useRecoilState(questionsAnsweredCount);
  // From useTrackAnswers: tracks player answers and creates a new item in the answersList
  const debouncedAnswer = useTrackAnswers();

  // Custom CSS
  const props = {
    correct: currentAnswerState === ANSWER_PROGRESS.CORRECT,
    incorrect: currentAnswerState === ANSWER_PROGRESS.INCORRECT,
  };

  const classes = useStyles(props);
  dev.log({ props });
  const validateAnswer = (value, correctAnswer, maxAttempt) => {
    dev.log(`value: ${value}, correctAnswer: ${correctAnswer}, maxAttempt: ${maxAttempt}`);
    const keyword = `${currentLevelState.key}_QUESTION_${currentQuestionState}`;
    const cquestion = questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].question;
    const dateUpdated = moment().format('YYYY-MM-DD HH:mm:ss.SS');

    setCurrentQuestionsAnsweredCount(currentQuestionsAnsweredCount + 1);

    if (value === correctAnswer) {
      changeLevelScore(currentLevelScore[currentLevelState.key] + 1);
      setCurrentAnswerState(ANSWER_PROGRESS.CORRECT);
      playCorrect();
      debouncedAnswer(keyword, cquestion, correctAnswer, value, ANSWER_PROGRESS.CORRECT, dateUpdated, currentQuestionsAnsweredCount);
    } else {
      dev.log('Got wrong answer');
      playWrong();
      setCurrentAnswerState(ANSWER_PROGRESS.INCORRECT);
      debouncedAnswer(keyword, cquestion, correctAnswer, value, ANSWER_PROGRESS.INCORRECT, dateUpdated, currentQuestionsAnsweredCount);
    }
  };

  const next = () => {
    if (currentQuestionState < maxQuestions) {
      changeQuestionState(currentQuestionState + 1);
    } else {
      changeLevelProgress(LEVEL_PROGRESS.CONGRATULATION);
    }
    // reset the answer state for the next question
    setCurrentAnswerState(ANSWER_PROGRESS.UNANSWERED);
  };

  return (
    <>
      <div className={classes.choisesBtnContainer}>
        {
          _.map(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].choices, (value, key) => {
            dev.log('I GOT HERE 1KEY');
            dev.log(`1key: ${key}, value: ${JSON.stringify(value)}`);
            return (
              _.map(value, (v, k) => {
                return (
                  <Button
                    type="button"
                    variant="text"
                    className={classes.choisesBtnHalf}
                    onClick={() => {
                      if (currentAnswerState === ANSWER_PROGRESS.UNANSWERED) {
                        validateAnswer(
                          v,
                          questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].correctAnswer,
                          Object.keys(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].choices).length - 1,
                        );
                        setCurrentBtnSideState(k.split(' ')[1]);
                      }
                    }}
                    key={k}
                  >
                    {tQuestions(k) || v}
                  </Button>
                );
              })
            );
          })
        }
      </div>
      {(currentAnswerState === ANSWER_PROGRESS.CORRECT || currentAnswerState === ANSWER_PROGRESS.INCORRECT) && (
        <section className={currentBtnSideState === 0 ? classes.containerHalfLeft : classes.containerHalfRight}>
          <article>
            <div className={`${classes.purpleHalf} ${currentBtnSideState === '1' ? classes.purpleHalfLeft : classes.purpleHalfRight}`} />
            <div className={`${currentAnswerState === 'CORRECT' ? classes.greenHalf : classes.redHalf} ${currentBtnSideState === '1' ? classes.answerHalfLeft : classes.answerHalfRight}`} />
          </article>
          <div className={`${currentAnswerState === 'CORRECT' ? classes.questionStateContainerCorrect : classes.questionStateContainerIncorrect} ${currentBtnSideState === '1' ? classes.answerContainerHalfLeft : classes.answerContainerHalfRight}`}>
            <div className={currentAnswerState === 'CORRECT' ? classes.questionStateTitleCorrect : classes.questionStateTitleIncorrect}>
              {tGlobal(currentAnswerState) || currentAnswerState}
            </div>
            {currentLevelState.type !== LEVEL_TYPES.GERM_HOT_SPOTS && (
              <div className={classes.questionStateDescription}>
                {tQuestions(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].promptKeyword) || questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].prompt}
              </div>
            )}
          </div>
          <div className={classes.nextButton}>
            <Action text={tGlobal('NEXT') || 'NEXT'} action={next} />
          </div>
        </section>
      )}
    </>
  );
});

SaferActivity.propTypes = {
  changeQuestionState: PropTypes.func,
  changeLevelProgress: PropTypes.func,
  changeLevelScore: PropTypes.func,
};

SaferActivity.defaultProps = {
  changeQuestionState: {},
  changeLevelProgress: {},
  changeLevelScore: {},
};

export default SaferActivity;
