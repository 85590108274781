import useDebounce from 'hooks/useDebounce';
import useDataApi from 'hooks/useDataApi';
import { getStatus } from 'utils/getStatus';
import { getScores } from 'utils/getScores';
import { answersListState, levelScore, selectedLanguageState } from 'store/atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import DevConsole from 'utils/DevConsole';
import moment from 'moment';

const dev = new DevConsole('useTrackAnswers');

const useTrackAnswers = () => {
  dev.info('Called');
  const [currentAnswersList, setCurrentAnswersList] = useRecoilState(answersListState);
  const selectedLanguage = useRecoilValue(selectedLanguageState);
  const currentLevelScore = useRecoilValue(levelScore);
  const { updateGuest } = useDataApi();
  const dateCreated = moment().format('YYYY-MM-DD HH:mm:ss.SS');


  const [debouncedAnswer] = useDebounce(async (keyword, question, correctAnswer, playerAnswer, status, dateUpdated, currentQuestionsAnsweredCount) => {
    const newAnswersListState = {
      ...currentAnswersList,
      [keyword]: {
        question,
        correctAnswer,
        playerAnswer,
        status,
        dateCreated,
        dateUpdated,
      },
      dateCreated,
      selectedLanguage,
      grade: getStatus(getScores(currentLevelScore).totalPercent).text,
      totalScore: getScores(currentLevelScore).totalScore,
    };

    setCurrentAnswersList(newAnswersListState);

    const statusContainer = {
      grade: getStatus(getScores(currentLevelScore).totalPercent).text,
      score: getScores(currentLevelScore).totalScore,
    };

    updateGuest(statusContainer.grade, currentQuestionsAnsweredCount, statusContainer.score, false);
  }, 500);

  return debouncedAnswer;
};

export default useTrackAnswers;
