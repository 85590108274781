import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import DevConsole from 'utils/DevConsole';
// Direct Children
import Polygon from './Polygon';

const dev = new DevConsole('GermHotSpots');
// questions[currentLevelState.key][`QUESTION_${currentQuestionState}`] - accessing objects from config

/**
 * Rendering clickable polygon layer that players interact with to
 * progress in the game.
 *
 * @param {*} props
 * @param {object} props.hotSpots - array of polygon coords we need to render at specific positions
 * @param {Array} props.collection - array of coords player has already interacted with. Used to determine opacity of polygons
 * @param {Function} props.showQuestion - showQuestion state updater from GermHotSpots.
 *
 * @returns {React.Component}
 */
const RenderPolygons = ({ hotSpotsObject, collection, showQuestion }) => {
  return (
    <>
      {/* The initial map we make over the hot spots from base config */}
      {
        _.map(hotSpotsObject, (value, key) => {
          const disabledPolygonArea = collection.includes(value.key) ? 'disabled' : '';
          dev.log('RenderPolygons hotSpots value', value.key);
          return (
            <div key={key}>
              {/* We create a second map with this component */}
              <Polygon
                key={key}
                polygons={value.polygons}
                hotSpotValue={value.key}
                collection={collection}
                disabled={disabledPolygonArea}
                showQuestion={showQuestion}
              />
            </div>
          );
        })
      }
    </>
  );
};

RenderPolygons.propTypes = {
  hotSpotsObject: PropTypes.object.isRequired,
  collection: PropTypes.array.isRequired,
  showQuestion: PropTypes.func.isRequired,
};

export default RenderPolygons;
