/* eslint-disable max-len */
/**
 * Languages config
 *
 * Returns languages list supported.
 */

export default [
  { productId: 'GSI', uuid: '7c68aec7-5980-4b31-b663-a08dd0b1186f', code: 'en', keyword: 'English', active: false, isDefault: true, listOrder: 1, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: 'cdb9d017-21ef-4ffb-872a-4303f5f934c8', code: 'es', keyword: 'Spanish', active: false, isDefault: false, listOrder: 2, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '953c9fc2-a3c6-42a0-99fe-52b076a5393a', code: 'fr', keyword: 'French', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '65a0c471-2dc4-4147-bb82-999892fe14ed', code: 'ja', keyword: 'Japanese', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '546b3f45-d8d7-4975-81d2-cf561b5ef569', code: 'ko', keyword: 'Korean', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '89c1b78c-611c-4d12-b0d5-393ac38bfadb', code: 'it', keyword: 'Italian', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '17cc4bcb-3d08-47ce-815b-70aa254b0268', code: 'ar', keyword: 'Arabic', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '9ad27913-67fe-4dcc-943e-f29cb4a94917', code: 'ps', keyword: 'Pashto / Persian', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '9d17fdf8-6052-423f-ad7b-ffdac73c7ef8', code: 'vi', keyword: 'Vietnamese', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '5ddf90c7-50fc-499b-996e-033d814baa13', code: 'tl', keyword: 'Tagalog', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '919172e7-f67d-478b-a4dc-7405024b638f', code: 'de', keyword: 'German', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '157f80df-0bef-4d59-90ee-15d0bdd319b8', code: 'zh', keyword: 'Cantonese', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: 'f5a8c7c1-3b5e-4522-a535-26b0df8492d3', code: 'yue', keyword: 'Mandarin', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: 'af248883-f593-4da4-8931-e5912d0014fc', code: 'pt', keyword: 'Portuguese', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '1ae1d795-847f-4aec-b51e-c9eb830e7a99', code: 'pa', keyword: 'Punjabi', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '3c3c2d39-c8d0-41b0-a882-3bd178edf647', code: 'ur', keyword: 'Urdu', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },
  { productId: 'GSI', uuid: '5330f864-ec0e-4186-ac11-cdb63c5c1bdc', code: 'cr', keyword: 'Cree', active: false, isDefault: false, listOrder: 99, dateCreated: '2021-08-15', dateUpdated: '2021-08-15' },

];
