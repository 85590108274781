import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: 'flex',
    flexDirection: props.custom ? 'column-reverse' : 'column',
    justifyContent: props.custom ? 'space-between' : 'center',
    width: props.custom ? '17vmax' : '10vmax',
    zIndex: 999,
    '@media screen and (max-height: 380px)': {
      width: props.custom ? '15vmax' : '10vmax',
    },
    '@media screen and (max-height: 285px)': {
      width: props.custom ? '15vmax' : '10vmax',
    },
    '& img': {
      width: '100%',
    },
    '& p': {
      width: '100%',
      display: 'block',
      color: props.custom ? theme.palette.secondary.main : 'white',
      fontSize: props.custom ? '3vw' : '2vw',
      textAlign: 'center',
      '@media screen and (max-height: 380px)': {
        fontSize: props.custom ? '2vw' : '2vw',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: props.custom ? '3vw' : '2vw',
      },
    },
    // Ipad Pro
    '@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)': {
      width: '15vmax',
      transform: 'translate(20vmax, -5vmax)',
    },
  }),
}));

export default useStyles;
