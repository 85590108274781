import { useState } from 'react';

/**
 * useDebounce Custom Hook
 *
 * Used to debounce functions or values.
 *
 * If a value is passed, debounces setState.
 * If a function is passed, debounces execution of the function.
 *
 * A cancel function is also passed, which can be used to cancel the timner.
 *
 * Note that this hook only trails execution.
 *
 * @param {any} toDebounce - Value or Function to debounce
 * @param {number} delay - Delay to debounce for
 *
 * @returns {any} Debounced value
 */
export default function useDebounce(toDebounce, delay = 1000) {
  const isFunction = typeof toDebounce === 'function';
  const [handler, setHandler] = useState(null);
  const [value, setValue] = useState(isFunction ? null : toDebounce);

  const dispatch = (...params) => {
    if (handler) {
      clearTimeout(handler);
      setHandler(null);
    }
    setHandler(setTimeout(() => {
      if (isFunction) {
        toDebounce(...params);
      } else {
        setValue(...params);
      }
    }, delay));
  };

  const cancelDispatch = () => {
    clearTimeout(handler);
  };

  return isFunction
    ? [dispatch, cancelDispatch]
    : [value, dispatch, cancelDispatch];
}
