// Line below is to resolve various fixes in StrictMode.
// More info found at https://github.com/mui-org/material-ui/issues/13394
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ':root': {
          fontSize: '0.875rem',
          '& body>div': {
            width: '100%',
            height: '100%',
          },
          '& .frame-root, .frame-content': {
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#FFFFFF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FF580F',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF580F',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF580F',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#004D26',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#004D26',
      contrastText: '#FFFFFF',
    },
  },
  breakpoints: {
    values: {
      xs: 300,
      sm: 768,
      md: 960,
      lg: 1440,
      xl: 1800,
    },
  },
});
