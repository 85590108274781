import React from 'react';
import { Grid as MUIGrid } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * MUI's grid component we are going to use to create a fluid layout that works on all device widths/heights
 *
 * @param {object} props - spread operator for handling any flex-props.
 * @param {React.Component} props.children - any react components rendered as children
 * @param {React.Component} props.component - component used for the root node that renders the grid
 *
 * @returns {React.Component}
 */
const Grid = (props) => {
  const { component, children } = props;
  const classes = useStyles();
  return (
    <MUIGrid
      className={classes.root}
      component={component}
      {...props}
    >
      {children}
    </MUIGrid>
  );
};

Grid.defaultProps = {
  component: 'div',
  classes: {},
};

Grid.propTypes = {
  component: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default Grid;
