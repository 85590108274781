/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@material-ui/core';

const alignments = {
  i: 'inherit',
  l: 'left',
  c: 'center',
  r: 'right',
  j: 'justify',
};

const Text = ({
  v, a, g, p, t, h, c, i, family, children, ...props
}) => {
  // TODO: Add Localization Context to support mutlilangue keywords
  const text = t || children;
  return (
    <Typography
      style={{ fontFamily: family }}
      variant={v || 'body2'}
      align={a ? alignments[a] : 'inherit'}
      gutterBottom={!!g}
      paragraph={!!p}
      color={c || 'inherit'}
      display={i ? 'inline' : 'initial'}
      {...props}
    >
      {text}
    </Typography>
  );
};

Text.defaultProps = {
  family: 'spyitalic-semi',
};

export default Text;
