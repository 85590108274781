/**
 * Configures AWS & AWS Amplify.
 *
 * For info on storage, check https://aws-amplify.github.io/docs/js/storage
 */
import getEnv from 'utils/getEnv';

const config = {
  localhost: {
    max_file_size: 5242880,
    s3: {
      bucket: 'assets.loci.dev',
      region: 'us-east-1',
    },
    apiGateway: {
      endpoints: [
        {
          name: 'feature',
          endpoint: 'https://nzmzhhvfmh.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'guest',
          endpoint: 'https://1b38zeycjk.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'localization',
          endpoint: 'https://mlxr87csod.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'feedback',
          endpoint: 'https://n0wgy9zrif.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'accessKey',
          endpoint: 'https://23ynmfh549.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
      ],
    },
    cognito: {
      region: 'us-east-1',
      user_pool_id: 'us-east-1_Iz9YQFp4h',
      app_client_id: '6su5h10mlnom5s4kbh2k0uii6g',
      identity_pool_id: 'us-east-1:84e1738c-957a-4ed2-a7d3-df356f3812d8',
    },
    cookieStorage: {
      domain: 'localhost',
      path: '/',
      expires: 90,
      secure: true,
    },
  },

  dev: {
    max_file_size: 5242880,
    s3: {
      bucket: 'assets.loci.dev',
      region: 'us-east-1',
    },
    apiGateway: {
      endpoints: [
        {
          name: 'feature',
          endpoint: 'https://nzmzhhvfmh.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'guest',
          endpoint: 'https://1b38zeycjk.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'localization',
          endpoint: 'https://mlxr87csod.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'feedback',
          endpoint: 'https://n0wgy9zrif.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'accessKey',
          endpoint: 'https://23ynmfh549.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
      ],
    },
    cognito: {
      region: 'us-east-1',
      user_pool_id: 'us-east-1_Iz9YQFp4h',
      app_client_id: '6su5h10mlnom5s4kbh2k0uii6g',
      identity_pool_id: 'us-east-1:84e1738c-957a-4ed2-a7d3-df356f3812d8',
    },
    cookieStorage: {
      domain: 'gsi.loci.dev',
      path: '/',
      expires: 30,
      secure: true,
    },
  },

  prod: {
    max_file_size: 5242880,
    s3: {
      bucket: 'assets.learnbyloci.com',
      region: 'us-east-1',
    },
    apiGateway: {
      endpoints: [
        {
          name: 'feature',
          endpoint: 'https://uamusx3at6.execute-api.us-east-1.amazonaws.com/prod',
          region: 'us-east-1',
        },
        {
          name: 'guest',
          endpoint: 'https://y4kvjhrop4.execute-api.us-east-1.amazonaws.com/prod',
          region: 'us-east-1',
        },
        {
          name: 'localization',
          endpoint: 'https://ys80fp6cm8.execute-api.us-east-1.amazonaws.com/prod',
          region: 'us-east-1',
        },
        {
          name: 'feedback',
          endpoint: 'https://28b45zklgl.execute-api.us-east-1.amazonaws.com/prod',
          region: 'us-east-1',
        },
        {
          name: 'accessKey',
          endpoint: 'https://waf5k5qup8.execute-api.us-east-1.amazonaws.com/prod',
          region: 'us-east-1',
        },
      ],
    },
    cognito: {
      region: 'us-east-1',
      user_pool_id: 'us-east-1_fx0KaI2Gl',
      app_client_id: '5qu18fav401oft9mbsbkcftrl7',
      identity_pool_id: 'us-east-1:dc4c7c30-cf59-4151-8030-e43b1979b0a2',
    },
    cookieStorage: {
      domain: 'gsi.learnbyloci.com',
      path: '/',
      expires: 30,
      secure: true,
    },
  },
};

export default config[getEnv()];
