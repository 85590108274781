/**
 * A function that can be used to detect a given language.
 * Used in conjunction with clsx or any other conditional that can render either react components or classnames that are more
 * appropriate for the given language.
 *
 * @param {string} selected - the current selectedLangageState from recoil
 * @param  {...any} args - any number of language code strings
 * @returns {boolean}
 */
export const detectLang = (selected, ...args) => {
  return args.includes(selected);
};
