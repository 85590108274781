import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(95, 43, 168), rgba(14, 38, 75))',
    overflow: 'auto',
    zIndex: 1000000,
    color: '#FFFFFF',
    fontFamily: 'chollasansreg',
    letterSpacing: '1px',
    padding: '1.5rem',
    fontSize: '2.50rem',
    borderRadius: '15px',
    '& h3': {
      fontFamily: 'chollasansbold',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.25rem',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '3rem',
      position: 'fixed',
      top: 0,
      right: 0,
    },
  },
  closeIconWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '.75rem',
  },
  close: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contentWrap: {
    padding: '1.75rem',
  },
  readBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
    marginBottom: '0.75rem',
  },
  readBtnFlex: {
    display: 'flex',
  },
  readButton: {
    border: '1px solid #FFFFFF',
    padding: '0.75rem',
  },
}));

export default useStyles;
