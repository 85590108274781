import { makeStyles } from '@material-ui/core';

const languageFonts = (selectedLang) => {
  switch (selectedLang) {
    case 'pt':
      return {
        fontSize: '3.5vh',
        padding: '0.25rem',
      };
    case 'pa':
      return {
        fontSize: '3vh',
        padding: '0.75rem',
      };
    case 'it':
      return {
        fontSize: '3vh',
        padding: '0.25rem',
      };
    case 'fr':
      return {
        fontSize: '3vh',
      };
    case 'tl':
      return {
        fontSize: '2.75vh',
      };
    case 'cr':
      return {
        fontSize: '3vh',
      };
    case 'de':
      return {
        fontSize: '3vh',
      };
    case 'ko':
      return {
        fontSize: '3vh',
      };
    default:
      return {
        fontSize: '3vh',
        padding: '0.25rem',
      };
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  container: props => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: props.isCustomURL ? '35vmax' : '40vmax',
      position: 'fixed',
      bottom: 0,
      '@media screen and (max-height: 380px)': {
        display: 'none',
      },
    },
  }),
  question: {
    position: 'absolute',
    bottom: 2,
    left: 4,
    zIndex: 3,
    width: '8%',
    [theme.breakpoints.up('lg')]: {
      width: '5%',
    },
    '@media screen and (orientation: portrait)': {
      top: 0,
      width: '15%',
    },
  },
  language: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    zIndex: 3,
    '& img': {
      width: '10vw',
    },
    '& p': {
      fontSize: '5vw',
      fontWeight: 800,
      color: '#77DDEC',
      textShadow: '0 0 15px #5CB4FF',
      textTransform: 'uppercase',
    },
    '@media screen and (orientation: portrait)': {
      top: 2,
      right: '30%',
      width: '12%',
    },
  },
  rotate: {
    marginTop: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rotateIcon: {
    width: '15vmax',
  },
  text: props => ({
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    textAlign: 'center',
    fontStyle: 'italic',
    textShadow: '0 0 11px #5ba7d2',
    ...languageFonts(props.currentLang),
  }),
  subtext: props => ({
    color: '#FFFFFF',
    padding: props.language ? '0 1rem 0 0' : 0,
    marginTop: '2%',
    textAlign: 'center',
    textShadow: '0 0 11px #5ba7d2',
    fontStyle: 'italic',
    ...languageFonts(props.currentLang),
  }),
}));

export default useStyles;
