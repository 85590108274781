export default {
  LEVEL_1: {
    text: 'LOCATE AND TAP TWO GERM HOT SPOTS IN EACH PHOTO',
  },
  LEVEL_2: {
    text: 'CHOOSE THE SAFER ACTIVITY',
    subText: 'WHEN IT COMES TO BEING GERM SAFE, SOME ACTIVITIES ARE SAFER THAN OTHERS. DO YOU KNOW THE DIFFERENCE?',
  },
  LEVEL_3: {
    text: 'TAP THE CORRECT ANSWER',
  },
  LEVEL_4: {
    text: 'CHOOSE THE SAFER ACTIVITY',
  },
};
