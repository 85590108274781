/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Text } from 'components/UI';
import useStyles from './styles';

/**
 * Returns converted markdown so users to view our privacy policy.
 *
 * @param {*} props
 * @param {boolean} props.open - whether the page containing the policy info is open
 * @param {Function} props.setOpen - function to toggle hiding/showing the panel
 * @param {string} props.policyContent - the content of the privacy policy
 * @param {boolean} props.contDisabled - boolean value for continue button disabled
 * @param {Function} props.setContDisabled - function to change the disabled value for continue button
 * @returns {React.Component}
 */
const PrivacyPolicy = ({ open, setOpen, policyContent }) => {
  const classes = useStyles();

  return open ? (
    <section className={classes.root}>
      <article className={classes.contentWrap}>
        <div className={classes.closeIconWrap}>
          <CloseIcon
            className={classes.close}
            onClick={() => setOpen(!open)}
          />
        </div>
        <ReactMarkdown children={policyContent} />
      </article>
      <article className={classes.readBtnContainer}>
        <div>
          <Button
            className={classes.readButton}
            color="primary"
            onClick={() => setOpen(!open)}
          >
            <Text>Close</Text>
          </Button>
        </div>
      </article>
    </section>
  ) : null;
};

PrivacyPolicy.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  policyContent: PropTypes.string,
};

PrivacyPolicy.defaultProps = {
  open: false,
  setOpen: () => {},
  policyContent: 'The Policy Content',
};

export default PrivacyPolicy;
