import Recoil from 'recoil';

const gdprOpenState = Recoil.atom({
  key: 'gdprOpenState',
  default: true,
});

const cookieConsentState = Recoil.atom({
  key: 'cookieConsentState',
  default: false,
});

const shakeAnimationState = Recoil.atom({
  key: 'shakeAnimationState',
  default: false,
});

export {
  gdprOpenState,
  cookieConsentState,
  shakeAnimationState,
};
