import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  shadowFrame: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    boxShadow: 'inset 0 0 4vw #5b22a0',
    background: 'transparent',
    border: 0,
  },
  '@keyframes fromLeft': {
    '0%': {
      opacity: 1,
      left: '-27%',
    },
    '100%': {
      opacity: 1,
      left: 0,
    },
  },
});

export default useStyles;
