import Recoil from 'recoil';
import { sessionStorageEffect } from 'utils/session';
import cachedLanguages from 'config/languages';

const translationState = Recoil.atom({
  key: 'translationState',
  default: {
    groups: [],
    items: [],
  },
});

const languageState = Recoil.atom({
  key: 'languageState',
  default: cachedLanguages,
  effects_UNSTABLE: [
    sessionStorageEffect('languageState'),
  ],
});

const selectedLanguageState = Recoil.atom({
  key: 'selectedLanguageState',
  default: 'en',
  effects_UNSTABLE: [
    sessionStorageEffect('selectedLanguageState'),
  ],
});

export {
  translationState,
  languageState,
  selectedLanguageState,
};
