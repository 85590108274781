import API from 'API';

const api = new API();
const service = 'feedback';
const path = '/feedback';

const feedback = {
  create: init => api.exec({
    action: 'post',
    service,
    path,
    init: {
      body: init.body,
    },
  }),
};

export default feedback;
