import { selectorFamily } from 'recoil';

import {
  levelScore,
} from './atoms';

import {
  guestPlayState,
} from './guest/selectors';

const levelScoreFamily = selectorFamily({
  key: 'levelScoreFamily',
  get: field => ({ get }) => get(levelScore)[field],
  set: field => ({ set }, newValue) =>
    set(levelScore, prevState => ({ ...prevState, [field]: newValue })),
});

export {
  levelScoreFamily,
  guestPlayState,
};
