/* eslint-disable no-plusplus */
import React from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import useLocalization from 'hooks/useLocalization';
import useGuestData from 'hooks/useDataApi';
import {
  Grid,
  Container,
  Text,
  Action,
  StatusBar,
  Scores,
} from 'components/UI';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  gameState,
  levelProgress,
  levelScore,
  questionsAnsweredCount,
  answersListState,
  playState,
  playThroughState,
  selectedLanguageState,
} from 'store/atoms';
import {
  GAME_STATE,
  LEVELS,
  LEVEL_PROGRESS,
  GSI_STATUS,
} from 'config/game';
import { detectLang } from 'utils/detectLang';
import { getScores } from 'utils/getScores';
import { getStatus } from 'utils/getStatus';
import DevConsole from 'utils/DevConsole';
import useStyles from './styles';

const dev = new DevConsole('TotalScene');

const TotalScene = ({ changeGameState, changeLevelProgress }) => {
  dev.log('Total Scene');
  const currentGameState = useRecoilValue(gameState);
  const currentLevelProgress = useRecoilValue(levelProgress);
  const [currentLevelScore, setCurrentLevelScore] = useRecoilState(levelScore);
  const [currentQuestionsAnsweredCount, setCurrentQuestionsAnsweredCount] = useRecoilState(questionsAnsweredCount);
  const currentAnswersListState = useRecoilValue(answersListState);
  const selectedLang = useRecoilValue(selectedLanguageState);
  const setPlayThrough = useSetRecoilState(playThroughState);
  const setPlays = useSetRecoilState(playState);
  const matches = useMediaQuery('(max-width: 900px)');
  const { updateGuest } = useGuestData();
  const t = useLocalization('Congratulations');

  // CSS PROPS
  const props = {
    language: detectLang(selectedLang, 'cr'),
  };

  const classes = useStyles(props);

  const nextButtonHandler = () => {
    changeGameState(GAME_STATE.INTRO);
    changeLevelProgress(LEVEL_PROGRESS.INSTRUCTION);
    setCurrentLevelScore({
      LEVEL_1: 0,
      LEVEL_2: 0,
      LEVEL_3: 0,
      LEVEL_4: 0,
    });
    setCurrentQuestionsAnsweredCount(0);
    setPlays(prevState => prevState + 1);
    // eslint-disable-next-line no-const-assign
    sessionStorage.clear();
  };

  // Initial render to have these states ready for the guest API
  React.useEffect(() => {
    dev.log('useEffect TotalScene');
    setPlayThrough(prevState => {
      return prevState.add(currentAnswersListState);
    });
    updateGuest(getStatus(getScores(currentLevelScore).totalPercent).text, currentQuestionsAnsweredCount, getScores(currentLevelScore).totalScore, true);
  }, []);

  return (
    <Grid xs={12} md={12} lg={12} data-test="TotalScene" className={classes.root} item>
      {currentGameState === GAME_STATE.IN_GAME && currentLevelProgress === LEVEL_PROGRESS.TOTAL && (
        <>
          <Container>
            <Grid
              spacing={0}
              className={classes.container}
              container
            >
              <Grid xs={12} m={12} lg={12} className={classes.item} item>
                <Text className={classes.congratulationsText}>
                  {t('Congratulations') || 'CONGRATULATIONS'}
                </Text>
              </Grid>
              <StatusBar
                status={GSI_STATUS}
                rank={getStatus(getScores(currentLevelScore).totalPercent)}
              />
              <Scores
                scoreState={currentLevelScore}
                rank={getStatus(getScores(currentLevelScore).totalPercent)}
                levels={LEVELS}
                maxQuestions={getScores(currentLevelScore).maxQuestionsPerLevel}
                overAllScore={getScores(currentLevelScore).totalScore}
                overAllQuestion={getScores(currentLevelScore).totalQuestion}
                handler={nextButtonHandler}
              />
            </Grid>
          </Container>
        </>
      )}
      {matches ? null : (
        <div className={classes.nextButton}>
          <Action
            text={t(getStatus(getScores(currentLevelScore).totalPercent).translationCopy) || getStatus(getScores(currentLevelScore).totalPercent).description}
            action={nextButtonHandler}
          />
        </div>
      )}
    </Grid>
  );
};

TotalScene.propTypes = {
  changeGameState: PropTypes.func,
  changeLevelProgress: PropTypes.func,
};

TotalScene.defaultProps = {
  changeGameState: {},
  changeLevelProgress: {},
};

export default TotalScene;
