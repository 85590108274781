import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    left: '10%',
    bottom: '15%',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    border: 'solid 0.2vw #ffffff82',
    background: 'linear-gradient(180deg, rgba(95, 43, 168, 0.8), rgba(14, 38, 75, 0.8))',
    borderRadius: '10px',
    textAlign: 'left',
    width: '85%',
    height: '65%',
    padding: '0 1.75rem',
    boxShadow: '0 0 30px #26ACC465',
    '@media only screen and  (max-height: 380px)': {
      bottom: '10%',
      height: '70%',
    },
    '@media only screen and (min-height: 800px) and (max-width: 900px)': {
      width: '85%',
      height: '50%',
    },
    // Tablet/Laptop Height
    [theme.breakpoints.between('md', 'lg')]: {
      height: '60%',
    },
  },
  headline: {
    fontSize: '3.5vw',
    width: '100%',
    textShadow: '0 0 5px #00a7ff',
  },
  text: {
    fontSize: '1.75vw',
    textShadow: '0 0 5px #00a7ff',
  },
  hashtagContainer: {
    width: '100%',
    background: 'linear-gradient(180deg,rgba(95, 43, 168, 0.2), rgba(255, 255, 255, 0.3))',
  },
  hashtag: {
    fontSize: '1.65vw',
    display: 'inline-block',
    textShadow: '0 0 15px #00a7ff',
    borderRadius: '10px',
    letterSpacing: '0.35vw',
  },
  actionGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  actionIcons: props => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexBasis: '60%',
    '& .actionImg': {
      flex: 0,
      width: props.language ? '4vmax' : '5vmax',
      height: '5vmax',
      marginRight: '0.75rem',
      [theme.breakpoints.up('lg')]: {
        marginRight: '2.75rem',
      },
    },
  }),
}));

export default useStyles;
