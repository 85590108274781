import React from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { GermHotSpots, MultipleChoice, SaferActivity } from 'components/UI';
import {
  gameState,
  levelState,
  levelScore,
  questionState,
  levelProgress,
  questionsAnsweredCount,
} from 'store/atoms';
import useGuestData from 'hooks/useDataApi';
import { GAME_STATE, LEVEL_PROGRESS, LEVEL_TYPES } from 'config/game';
import { getScores } from 'utils/getScores';
import { getStatus } from 'utils/getStatus';
import questions from 'config/questions';
import useStyles from './styles';

const QuestionScene = ({ changeQuestionState, changeLevelProgress, changeLevelScore }) => {
  const classes = useStyles();
  const currentGameState = useRecoilValue(gameState);
  const currentLevelState = useRecoilValue(levelState);
  const currentLevelScore = useRecoilValue(levelScore);
  const currentLevelProgress = useRecoilValue(levelProgress);
  const currentQuestionState = useRecoilValue(questionState);
  const currentQuestionsAnsweredCount = useRecoilValue(questionsAnsweredCount);
  const { updateGuest } = useGuestData();

  React.useEffect(() => {
    updateGuest(getStatus(getScores(currentLevelScore).totalPercent).text, currentQuestionsAnsweredCount, getScores(currentLevelScore).totalScore, false);
  }, []);

  return (
    <div
      data-test="QuestionScene"
      className={classes.root}
      style={{
        backgroundImage: `url(${questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].background})`,
        backgroundSize: `${Object.prototype.hasOwnProperty.call(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`], 'HOT_SPOTS') ? '100% 100%' : 'cover'}`,
      }}
    >
      {currentGameState === GAME_STATE.IN_GAME && currentLevelProgress === LEVEL_PROGRESS.QUESTION && (
        <>
          {currentLevelState.type === LEVEL_TYPES.SAFER_ACTIVITY && (
            <SaferActivity
              changeQuestionState={changeQuestionState}
              changeLevelProgress={changeLevelProgress}
              changeLevelScore={changeLevelScore}
            />
          )}
          {currentLevelState.type === LEVEL_TYPES.MULTIPLE_CHOICE && (
            <MultipleChoice
              changeQuestionState={changeQuestionState}
              changeLevelProgress={changeLevelProgress}
              changeLevelScore={changeLevelScore}
            />
          )}
          {currentLevelState.type === LEVEL_TYPES.GERM_HOT_SPOTS && (
            <GermHotSpots
              changeQuestionState={changeQuestionState}
              changeLevelProgress={changeLevelProgress}
              changeLevelScore={changeLevelScore}
            />
          )}
        </>
      )}
    </div>
  );
};

QuestionScene.propTypes = {
  changeQuestionState: PropTypes.func,
  changeLevelProgress: PropTypes.func,
  changeLevelScore: PropTypes.func,
};

QuestionScene.defaultProps = {
  changeQuestionState: {},
  changeLevelProgress: {},
  changeLevelScore: {},
};

export default QuestionScene;
