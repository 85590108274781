import { ReactSession } from 'react-client-session';

export const sessionStorageEffect = key => ({ setSelf, onSet }) => {
  const sessionValue = ReactSession.get(key);
  if (sessionValue != null) {
    setSelf(sessionValue);
  }
  onSet(newValue => {
    ReactSession.set(key, newValue);
  });
};
