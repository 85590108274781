export const FAQ = [
  {
    id: 0,
    question: 'My screen wont rotate to start the game, what should I do?',
    answer: 'Check your settings to ensure that the Portrait Orientation Lock is off and Auto-Rotate is enabled. On iPhones this can be done from the Control Centre, and from Settings on most Androids.',
    open: false,
    qKeyword: 'HEADLINE1',
    aKeyword: 'SUBTEXT1',
  },
  {
    id: 1,
    question: 'Do I need to download an app?',
    answer: 'Nope. It’s browser-based and you can play from any device or computer.',
    open: false,
    qKeyword: 'Headline2',
    aKeyword: 'Subtext2',
  },
  {
    id: 2,
    question: 'What’s a Germ Scene?',
    answer: 'Anywhere people are can be a Germ Scene, like an office, grocery store or public transit because germs transfer from person to person via the surfaces they touch, as well as through the air they breathe.',
    open: false,
    qKeyword: 'Headline3',
    aKeyword: 'Subtext3',
  },
  {
    id: 3,
    question: 'What types of surfaces are germs typically found on?',
    answer: 'The material of hotspot surfaces may vary, but many are made of metal, glass, plastic, wood or cardboard. Learn more by playing GSI!',
    open: false,
    qKeyword: 'Headline4',
    aKeyword: 'Subtext4',
  },
  {
    id: 4,
    question: 'Does the game come in any languages besides English?',
    answer: 'Yes! GSI is currently available in many languages. Choose your language by tapping the globe icon in the upper corner at the start of the game. Don’t see your preferred language? More translations are coming soon, stay tuned!',
    open: false,
    qKeyword: 'Headline5',
    aKeyword: 'Subtext5',
  },
  {
    id: 5,
    question: 'What should I do once I’ve completed GSI?',
    answer: 'Congratulations, you’re now a GSI Agent trained to spot invisible germs and stop their spread! Share the game with friends and family so that everyone you know can work together to fight against the spread of COVID-19.',
    open: false,
    qKeyword: 'Headline6',
    aKeyword: 'Subtext6',
  },
];
