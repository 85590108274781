import ReactGA from 'react-ga';
import config from 'config/ga';

const canRun = process.env.NODE_ENV !== 'test';

const ga = {
  init: () => {
    return canRun && ReactGA.initialize(config.propertyId);
  },
  pageView: (...params) => {
    return canRun && ReactGA.pageview(...params);
  },
  set: (dimension, value) => {
    return canRun && ReactGA.set({ [dimension]: value });
  },
  event: (category, action, ...args) => {
    return canRun && ReactGA.event({
      category,
      action,
      ...args,
    });
  },
};

export default ga;
