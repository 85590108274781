import { makeStyles } from '@material-ui/core';

const gradientOpaco = 'linear-gradient(180deg, rgb(94 34 164 / 60%) 0%, rgb(24 37 91 / 60%) 100%)';
const borderWhite = 'solid 0.3vw #ffffff82';
const redOpaco = '#ff000054 !important';
const greenOpaco = '#2db70066 !important';

const choicesBtnBase = {
  color: 'white',
  fontSize: '2.8vw',
  background: gradientOpaco,
  border: borderWhite,
  borderRadius: '50%',
  margin: '1%',
  fontFamily: 'sarica',
  minWidth: '8.7vw',
  height: '8.7vw',
};

const useStyles = makeStyles({
  choicesContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  choicesBtn: {
    ...choicesBtnBase,
  },
  choicesBtnRed: {
    ...choicesBtnBase,
    background: 'transparent',
    borderColor: 'red',
    backgroundColor: redOpaco,
  },
  choicesBtnGreen: {
    ...choicesBtnBase,
    background: 'transparent',
    borderColor: 'lime',
    backgroundColor: greenOpaco,
  },
});

export default useStyles;
