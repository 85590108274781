import React from 'react';
import PropTypes from 'prop-types';
import iconHand from 'assets/default/icons/hand-icon.png';
import useStyles from './styles';

/**
 * Instructions for the beginning of each level for GermHotSpots
 *
 * @param {*} props
 * @param {string} props.hotSpotProgress - current progress for the hotspot level.
 * @param {string} props.question - comparator for our progress
 * @param {string} props.currentQuestion - the current question being rendered
 * @param {boolean} props.currentNoGerms - if there are currently no germs in game view
 * @param {Function} props.tGlobal - our global translations for GSI from backend
 *
 * @returns {React.Component}
 */
const HotSpotInstructions = ({
  hotSpotProgress,
  question,
  currentQuestion,
  currentNoGerms,
  tGlobal,
}) => {
  const classes = useStyles();

  return hotSpotProgress !== question && currentQuestion && (
    <>
      <div className={classes.locateContainer}>
        <img src={iconHand} alt="icon-hand" className={classes.locateIcon} />
        <span className={classes.locateText}>{tGlobal('hotspots subtext') || 'LOCATE AND TAP GERM HOTSPOTS'}</span>
      </div>
      {currentNoGerms && (
        <>
          <div className={classes.surfaceContent}>
            <div className={classes.surfaceTitle}>
              {tGlobal('TRY AGAIN') || 'TRY AGAIN'}
            </div>
            <div className={classes.surfaceInstruction}>
              {tGlobal('NO GERMS SELECTED') || 'NO GERMS SELECTED'}
            </div>
          </div>
        </>
      )}
    </>
  );
};

HotSpotInstructions.propTypes = {
  hotSpotProgress: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  currentQuestion: PropTypes.object.isRequired,
  currentNoGerms: PropTypes.bool.isRequired,
  tGlobal: PropTypes.func.isRequired,
};

export default HotSpotInstructions;
