import { makeStyles } from '@material-ui/core';

const purple2 = '#8a2be285';
const green = '#3fff00';
const gradientOpaco = 'linear-gradient(180deg, rgb(94 34 164 / 60%) 0%, rgb(24 37 91 / 60%) 100%)';
const borderWhite = 'solid 0.3vw #ffffff82';
const shadowGreen = `0.1vw 0.1vw 1vw ${green}`;
const shadowRed = '0.1vw 0.3vw 1vw red';
const shadowBlue = '0.1vw 0.3vw 1vw dodgerblue';

const baseCssIncorrect = {
  position: 'absolute',
  top: '20%',
  width: '28%',
};

const baseCssCustomIncorrect = {
  position: 'absolute',
  bottom: 0,
  left: '0',
  width: '25%',
  // clipPath: 'inset(10px 10px 0px 0px)',
};

const baseCssCorrect = {
  position: 'absolute',
  top: '10%',
  left: '-5%',
  width: '35%',
};


const baseCssCustomCorrect = {
  position: 'absolute',
  bottom: 0,
  left: '2%',
  width: '25%',
};

const setStyleForAvatar = (custom, status) => {
  switch (status) {
    case 'correct':
      if (custom) return baseCssCustomCorrect;
      return baseCssCorrect;
    case 'incorrect':
      if (custom) return baseCssCustomIncorrect;
      return baseCssIncorrect;
    default:
      throw new Error('Status invalid no styles found');
  }
};

const useStyles = makeStyles((theme) => ({
  purpleOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: purple2,
  },
  questionContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  question: {
    color: 'white',
    background: gradientOpaco,
    fontFamily: 'sarica',
    fontSize: '2.4vw',
    textAlign: 'center',
    width: '60%',
    borderRadius: '1em',
    padding: '1.5%',
    top: '15%',
    border: borderWhite,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  correct: {
    fontFamily: 'spyitalic',
    fontSize: '3.6vw',
    textAlign: 'center',
    textShadow: shadowGreen,
  },
  incorrect: {
    fontFamily: 'spyitalic',
    fontSize: '3.6vw',
    textAlign: 'center',
    textShadow: shadowRed,
  },
  avatarPosition: props => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& img': {
      ...setStyleForAvatar(props.custom, 'incorrect', theme),
      ...setStyleForAvatar(props.custom, 'correct', theme),
      zIndex: props.language ? 999 : 0,
    },
  }),
  nextButton: {
    position: 'absolute',
    bottom: '2%',
    right: '2%',
    zIndex: 9,
  },
  surfaceContent: {
    color: 'white',
    position: 'absolute',
    bottom: '2%',
    right: '2%',
    textAlign: 'right',
    textShadow: shadowBlue,
    lineHeight: '2.5vw',
  },
  surfaceTitle: {
    fontSize: '2.5vw',
    fontFamily: 'spyitalic',
  },
  surfaceInstruction: {
    fontSize: '2vw',
    fontFamily: 'sarica',
  },
}));

export default useStyles;
