import { makeStyles } from '@material-ui/core';
import trapezoid from 'assets/default/images/UI_element_trapezoid@2x.png';

const useStyles = makeStyles(() => ({
  germWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  covidLabel: {
    width: '15vmax',
    transform: 'translate(-10%, 20%)',
  },
  germFlex: props => ({
    position: 'absolute',
    bottom: '20%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    left: props.custom ? '35%' : '10%',
    // Phones - Browser Bar
    '@media only screen and (max-height: 380px)': {
      left: props.custom ? '35%' : '15%',
      bottom: '20%',
    },
    // Tablet Height
    '@media only screen and (min-height: 768px) and (max-width: 1366px)': {
      bottom: '25%',
      left: '-5%',
    },
    // Laptop Height
    '@media only screen and (min-height: 1024px) and (max-width: 1400px)': {
      bottom: '25%',
    },
  }),
  leoName: {
    position: 'absolute',
    bottom: '20%',
    left: '-45%',
    fontSize: '3vw',
    marginRight: '1.75rem',
    '@media only screen and (max-height: 380px)': {
      bottom: '15%',
      left: '-45%',
    },
  },
  dangerFlex: {
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      width: '4vmax',
      // Phones - Browser Bar
      '@media only screen and (max-height: 380px)': {
        width: '4vmax',
      },
    },
    transform: 'translate(-2vmax, -3vmax)',
  },
  germBg: {
    background: `url(${trapezoid})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    width: '10vmax',
    // Phones - Browser Bar
    '@media only screen and (max-height: 380px)': {
      width: '10vmax',
    },
    // Tablet/Laptop Height
    '@media only screen and (min-height: 768px) and (max-width: 1280px)': {
      width: '15vmax',
    },
    '@media only screen and (min-height: 1000px) and (max-width: 1366px)': {
      width: '15vmax',
    },
  },
}));

export default useStyles;
