import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'translate(0,0)',
    zIndex: 990,
    padding: '9% 10% 5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgb(84, 25, 185), rgba(84, 25, 185, 0.9), rgba(0, 0, 55, 0.8))',
    boxShadow: '0 0 15px #00a7ff',
    textShadow: '2px 4px 9px #2A97F4E6',
  },
  close: {
    position: 'absolute',
    top: '2vw',
    right: '2vw',
    fontSize: '5vw',
    zIndex: 99999,
  },
  outer: {
    position: 'relative',
    zIndex: 999,
    width: '100%',
    height: '100%',
  },
  wrapper: {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  overFlow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    order: 2,
  },
  title: {
    position: 'fixed',
    top: '7%',
    zIndex: 5,
    fontSize: '3vw',
    '@media screen and (orientation: portrait)': {
      top: '2%',
      fontSize: '2vh',
    },
  },
  languageContainer: {
    position: 'relative',
    left: '3vw',
    width: '65vw',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      justifyContent: 'space-between',
      flex: 0,
      margin: '2vh 0',
      padding: '1%',
    },
    '& p': {
      color: 'white',
      textShadow: '0 0 15px #00a7ff',
      fontSize: '2.5vw',
      fontFamily: 'chollasansreg !important',
      fontStyle: 'italic',
      '@media screen and (orientation: portrait)': {
        fontSize: '2.5vh',
      },
    },
    '& img': {
      width: '5vmax',
      display: 'none',
    },
  },
  selected: {
    backgroundColor: '#ffffff21',
    borderRadius: '2px',
    '& img': {
      display: 'block',
    },
  },
}));

export default useStyles;
