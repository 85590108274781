import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '@media only screen and (max-height: 380px)': {
      paddingRight: '10%',
      paddingLeft: '10%',
    },
  },
  congratulationsText: {
    position: 'relative',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '3vw',
    letterSpacing: '0.50rem',
    textShadow: '2px 4px 9px #2A97F4E6',
    '@media only screen and (max-height: 380px)': {
      paddingRight: '10%',
      paddingLeft: '10%',
      fontSize: '2.75vw',
    },
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});

export default useStyles;
