import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  consentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: '65%',
    margin: 'auto',
    padding: '0.65rem',
    background: 'linear-gradient(180deg, rgba(95, 43, 168, 0.8), rgba(14, 38, 75, 1))',
    borderTop: '2px solid #004D26',
    borderRadius: '5px',
    zIndex: 9999,
  },
  shakeAnimation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    animation: '$shake 1s infinite',
    zIndex: 100,
    background: 'linear-gradient(180deg, rgba(95, 43, 168, 0.8), rgba(14, 38, 75, 1))',
    width: '65%',
    margin: 'auto',
    padding: '0.65rem',
  },
  textContainer: props => ({
    textAlign: 'center',
    fontFamily: 'spyitalic-semi',
    marginBottom: '1.25rem',
    // Compliance Content Text Styles
    '& p': {
      color: '#FFFFFF',
      fontSize: props.phones ? '1.25rem' : '0.85rem',
      '@media screen and (max-width: 700px)': {
        fontSize: '0.75rem',
      },
    },
    // Link Styles
    '& a': {
      color: '#FFFFFF',
      marginLeft: '0.65rem',
      alignSelf: 'center',
      fontSize: props.phones ? '1.25rem' : '0.85rem',
      '@media screen and (max-width: 700px)': {
        fontSize: '0.75rem',
      },
    },
  }),
  consentButton: {
    border: '1px solid #FFFFFF',
    padding: '0.25rem',
  },
  '@keyframes shake': {
    '0%': {
      transform: 'translate(10px, 0px)',
    },
    '10%': {
      transform: 'translate(20px, 0px)',
    },
    '20%': {
      transform: 'translate(10px, 0px)',
    },
    '30%': {
      transform: 'translate(20px, 0px)',
    },
  },
}));

export default useStyles;
