/**
 * Courses API endpoint
 *
 * Wrap around utility for the API endpoint that returns data related to courses.
 */
import API from 'API';
import mockData from './mockData';

const api = new API();
const service = 'courses';
const path = '/courses';

const courses = {
  mock: () => api.setMock(mockData),
  live: () => api.setMock(null),
  get: query => api.exec({
    action: 'get',
    service,
    path,
    query,
  }),
  listAll: () => {
    const res = api.exec({
      action: 'get',
      service,
      path,
    });
    return res;
  },
};

export default courses;
