import logoRadar1 from 'assets/default/level_complete/GSI_logo_radar_level_1@2x-min.png';
import logoRadar2 from 'assets/default/level_complete/GSI_logo_radar_level_2@2x-min.png';
import logoRadar3 from 'assets/default/level_complete/GSI_logo_radar_level_3@2x-min.png';
import logoRadar4 from 'assets/default/level_complete/GSI_logo_radar_level_4@2x-min.png';
import gsiNewbieActive from 'assets/default/total_score/gsi_newbie_active@2x.png';
import gsiNewbieInActive from 'assets/default/total_score/gsi_newbie_inactive@2x.png';
import gsiNewbieBadgeBg from 'assets/default/total_score/rank_earned_shape_rect_green@2x.png';
import gsiNoviceActive from 'assets/default/total_score/gsi_novice_active@2x.png';
import gsiNoviceInActive from 'assets/default/total_score/gsi_novice_inactive@2x.png';
import gsiNoviceBadgeBg from 'assets/default/total_score/rank_earned_shape_rect_blue@2x.png';
import gsiScientistActive from 'assets/default/total_score/gsi_scientist_active@2x.png';
import gsiScientistInActive from 'assets/default/total_score/gsi_scientist_inactive@2x.png';
import gsiScientistBadgeBg from 'assets/default/total_score/rank_earned_shape_rect_purple@2x.png';

export const GAME_STATE = {
  INTRO: 'INTRO',
  LEVEL_SELECTOR: 'LEVEL_SELECTOR',
  IN_GAME: 'IN_GAME',
  FINISH: 'FINISH',
};

export const LEVEL_TYPES = {
  GERM_HOT_SPOTS: 'GERM_HOT_SPOTS',
  SAFER_ACTIVITY: 'SAFER_ACTIVITY',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
};

export const LEVELS = {
  LEVEL_1: {
    key: 'LEVEL_1',
    text: 'LEVEL 1',
    value: 1,
    type: LEVEL_TYPES.GERM_HOT_SPOTS,
    radar: logoRadar1,
    complete: false,
  },
  LEVEL_2: {
    key: 'LEVEL_2',
    text: 'LEVEL 2',
    value: 2,
    type: LEVEL_TYPES.SAFER_ACTIVITY,
    radar: logoRadar2,
    complete: false,
  },
  LEVEL_3: {
    key: 'LEVEL_3',
    text: 'LEVEL 3',
    value: 3,
    type: LEVEL_TYPES.MULTIPLE_CHOICE,
    radar: logoRadar3,
    complete: false,
  },
  LEVEL_4: {
    key: 'LEVEL_4',
    text: 'LEVEL 4',
    value: 4,
    type: LEVEL_TYPES.SAFER_ACTIVITY,
    radar: logoRadar4,
    complete: false,
  },
};

export const LEVEL_PROGRESS = {
  INSTRUCTION: 'INSTRUCTION',
  QUESTION: 'QUESTION',
  CONGRATULATION: 'CONGRATULATION',
  TOTAL: 'TOTAL',
};

export const ANSWER_PROGRESS = {
  UNANSWERED: 'UNANSWERED',
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  TRY_AGAIN: 'TRY_AGAIN',
};

export const GERM_HOT_SPOT_PROGRESS = {
  SELECTION: 'SELECTION',
  QUESTION: 'QUESTION',
  COMPLETE: 'COMPLETE',
};

export const GSI_STATUS = {
  GERM_NEWBIE: {
    key: 'GERM_NEWBIE',
    text: 'GERM BEGINNER',
    description: 'KEEP BUILDING YOUR GERM KNOWLEDGE. PLAY AGAIN!',
    activeLogo: gsiNewbieActive,
    inActiveLogo: gsiNewbieInActive,
    badgeBg: gsiNewbieBadgeBg,
    scoreBg: 'rgba(0, 155, 20, 0.4)',
    activeClassname: 'newbieActive',
    inActiveClassname: 'newbieInActive',
    activeBadgeClassname: 'newbieActiveBadge',
    activeBadgeEs: 'newbieBadgeEs',
    textClassname: 'newbieText',
    translationKeyword: 'Newb',
    translationCopy: 'Newb copy',
  },
  GERM_ROOKIE: {
    key: 'GERM_ROOKIE',
    text: 'GERM ROOKIE',
    description: 'PLAY AGAIN TO ADVANCE TO GERM SCIENTIST!',
    activeLogo: gsiNoviceActive,
    inActiveLogo: gsiNoviceInActive,
    badgeBg: gsiNoviceBadgeBg,
    scoreBg: 'rgba(0, 115, 255, 0.4)',
    activeClassname: 'noviceActive',
    inActiveClassname: 'noviceInActive',
    activeBadgeClassname: 'noviceActiveBadge',
    activeBadgeEs: 'noviceBadgeEs',
    textClassname: 'noviceText',
    translationKeyword: 'Rookie',
    translationCopy: 'Rookie copy',
  },
  GERM_SCIENTIST: {
    key: 'GERM_SCIENTIST',
    text: 'GERM SCIENTIST',
    description: 'PLAY AGAIN TO KEEP YOUR GERM KNOWLEDGE STRONG!',
    activeLogo: gsiScientistActive,
    inActiveLogo: gsiScientistInActive,
    badgeBg: gsiScientistBadgeBg,
    scoreBg: 'rgba(235, 50, 255, 0.4)',
    activeClassname: 'scientistActive',
    inActiveClassname: 'scientistInActive',
    activeBadgeClassname: 'scientistActiveBadge',
    activeBadgeEs: 'scientistBadgeEs',
    textClassname: 'scientistText',
    translationKeyword: 'Scientist',
    translationCopy: 'Scientist copy',
  },
};
