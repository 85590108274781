/**
 * feature API
 *
 * Wraps around the Feature Flags API endpoint.
 */
import API from 'API';
import mockData from './mockData';

const api = new API();
const service = 'feature';
const path = '';

const feature = {
  mock: () => api.setMock(mockData),
  live: () => api.setMock(null),
  list: payload => api.exec({
    action: 'get',
    service,
    path,
    payload: {
      productId: payload.productId,
    },
  }),
  create: init => api.exec({
    action: 'post',
    service,
    init: {
      headers: init.headers,
      body: init.body,
    },
  }),
  update: mixedData => api.exec({
    action: 'put',
    service,
    payload: {
      productId: mixedData.payload.productId,
      uuid: mixedData.payload.uuid,
    },
    init: {
      headers: mixedData.headers,
      body: mixedData.body,
    },
  }),
  delete: payload => api.exec({
    action: 'del',
    service,
    payload: {
      productId: payload.productId,
      uuid: payload.uuid,
    },
  }),
};

export default feature;
