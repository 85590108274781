/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import React from 'react';
import { faqModal } from 'store/atoms';
import { useSetRecoilState } from 'recoil';
import useLocalization from 'hooks/useLocalization';
import {
  Grid,
  Text,
} from 'components/UI';
import {
  List,
  ListItem,
  Collapse,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FAQ } from 'config/faq';
import { ExpandMore } from '@material-ui/icons';
import useStyles from './styles';

const Faq = () => {
  const [faqs, setFaq] = React.useState(FAQ);
  const classes = useStyles();
  const setFaqModalOpen = useSetRecoilState(faqModal);
  const t = useLocalization('FAQ');
  const tGlobal = useLocalization('Global');

  const iconStyles = {
    color: '#00FF30',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    border: '2px solid rgba(255, 255, 255, 0.4)',
    borderRadius: '50%',
    fontSize: '2.5rem',
  };

  const iconStylesRotate = {
    color: '#00FF30',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    border: '2px solid rgba(255, 255, 255, 0.4)',
    borderRadius: '50%',
    fontSize: '2.5rem',
    transform: 'rotate(270deg)',
  };

  const handleCloseFaq = () => {
    setFaqModalOpen(false);
  };

  const onToggleQuestions = (e, index) => {
    setFaq(faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open;
      } else {
        faq.open = false;
      }
      return faq;
    }));
    e.preventDefault();
  };

  return (
    <Grid
      className={classes.parentFlex}
      container
      alignItems="flex-start"
    >
      <Text family="spyitalic-semi" color="primary" className={classes.general}>{tGlobal('FAQ upper') || 'FREQUENTLY ASKED QUESTIONS'}</Text>
      <div className={classes.outer}>
        <div className={classes.wrapper}>
          <div className={classes.overFlow}>
            <List className={classes.faqFlex}>
              {faqs.map((item) => {
                return (
                  <>
                    <ListItem key={item.id} button onClick={(e) => onToggleQuestions(e, item.id)}>
                      <Text color="primary" family="chollasansreg">
                        {t(item.qKeyword) || item.question}
                      </Text>
                      {item.open ? <ExpandMore style={iconStyles} /> : <ExpandMore style={iconStylesRotate} />}
                    </ListItem>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                      <ListItem className={classes.answer}>
                        <Text color="primary" family="chollasansreg">
                          {t(item.aKeyword) || item.answer}
                        </Text>
                      </ListItem>
                    </Collapse>
                  </>
                );
              })}
            </List>
          </div>
        </div>
      </div>
      <CloseIcon color="primary" className={classes.close} role="button" tabIndex="0" onClick={handleCloseFaq} />
    </Grid>
  );
};

export default Faq;
