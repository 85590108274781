import React from 'react';
import Recoil from 'recoil';
import { GAME_STATE } from 'config/game';
import PropTypes from 'prop-types';
import { Grid, Text, Asset, Action } from 'components/UI';
import {
  selectedLanguageState,
  gdprOpenState,
  shakeAnimationState,
} from 'store/atoms';
import { useCookies } from 'react-cookie';
import { detectLang } from 'utils/detectLang';
import useLocalization from 'hooks/useLocalization';
import useDataApi from 'hooks/useDataApi';
import mission1 from 'assets/default/icons/mission-icon-1.png';
import mission2 from 'assets/default/icons/mission-icon-2.png';
import mission3 from 'assets/default/icons/mission-icon-3.png';
import useStyles from './styles';

/**
 * Dashboard component for the Intro Scene
 *
 * @param {object} props
 * @param {Function} props.changeGameState - function passed down from Game Engine, updates game state
 * @returns {React.Component}
 */
const Mission = ({ changeGameState }) => {
  const selectedLang = Recoil.useRecoilValue(selectedLanguageState);
  const setGDPROpen = Recoil.useSetRecoilState(gdprOpenState);
  const setShake = Recoil.useSetRecoilState(shakeAnimationState);
  const [, setCookie] = useCookies();

  const props = {
    language: detectLang(selectedLang, 'es'),
  };
  const classes = useStyles(props);
  const t = useLocalization('Intro');
  const { createGuest } = useDataApi();

  const handleAction = () => {
    setShake(true);
    createGuest();
    setCookie('cookie-accepted', true);

    setTimeout(() => {
      changeGameState(GAME_STATE.IN_GAME);
      setGDPROpen(false);
    }, [1000]);
  };

  return (
    <Grid className={classes.root}>
      <Text family="spyitalic-semi" color="primary" className={classes.headline}>{t('headline') || 'YOUR MISSION'}</Text>
      <Text family="chollasansreg" color="primary" className={classes.text}>
        {t('subtext') || 'COMPLETE ALL 4 LEVELS BY COLLECTING LIFE-SAVING GERM FACTS AND LEARNING HOW TO'}
      </Text>
      <section className={classes.hashtagContainer}>
        <Text color="primary" family="spyitalic-semi" className={classes.hashtag}>{t('hashtag') || '#STOPTHESPREAD'}</Text>
      </section>
      <section className={classes.actionGroup}>
        <article className={classes.actionIcons}>
          <Asset className="actionImg" src={mission1} />
          <Asset className="actionImg" src={mission2} />
          <Asset className="actionImg" src={mission3} />
        </article>
        <article>
          <Action text={t('start') || 'START'} action={handleAction} />
        </article>
      </section>
    </Grid>
  );
};

Mission.propTypes = {
  changeGameState: PropTypes.func.isRequired,
};

export default Mission;
