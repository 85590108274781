import DevConsole from './DevConsole';

const dev = new DevConsole('getURL');

/**
 * getURL() We need to dynamically build a URL for the feedback screenshots or custom assets stored in S3
 *
 * Returns the URL with the specified address for dev or prod
 *
 * S3 buckets: assets.loci.dev || assets.learnbyloci.ca
 *
 * @param {boolean} cloudFrontURL - for accessing cloudfront distros in dev and production specifically for custom assets
 *
 * @returns {string} assets.loci.dev || assets.learnbyloci.ca
 *
 */
export default function getURL(cloudFrontURL) {
  const URLContainer = {
    devBucket: 'assets.loci.dev',
    prodBucket: 'assets.learnbyloci.com',
    cloudDev: 'https://d3jnx986fehbsy.cloudfront.net',
    cloudProd: 'https://d27b9k79qu7rfm.cloudfront.net',
  };

  const { devBucket, prodBucket, cloudDev, cloudProd } = URLContainer;
  dev.log(cloudFrontURL);

  // cloudfront distro
  if (process.env.NODE_ENV === 'development') {
    return cloudFrontURL ? cloudDev : devBucket;
  }

  // Look anywhere in hostname for now, i.e. bucket name is "my.loci.dev.s3-website-us-east-1.amazonaws.com"
  if (window.location.hostname.search(/loci\.dev/) === -1) {
    return cloudFrontURL ? cloudProd : prodBucket;
  }

  return cloudFrontURL ? cloudDev : devBucket;
}
