/* eslint-disable consistent-return */
import DevConsole from 'utils/DevConsole';
import { useCookies } from 'react-cookie';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  guestState,
  answersListState,
  playState,
  playThroughState,
  selectedLanguageState,
} from 'store/atoms';
import { guest } from 'API/services';

const dev = new DevConsole('useDataApi');

// Hardcoded for now, will need to be dynamic at some point
const COMP_ID = 'LOCI';
const APP_ID = 'GSI';

const useDataApi = (currentAccessKeyState) => {
  // Cookie Storage
  const [cookie, setCookie] = useCookies();
  const [currentGuestState, setCurrentGuestState] = useRecoilState(guestState);
  const selectedLang = useRecoilValue(selectedLanguageState);
  const currentAnswersList = useRecoilValue(answersListState);
  const numPlays = useRecoilValue(playState);
  const [playThrough, setPlayThrough] = useRecoilState(playThroughState);

  dev.info(cookie);
  dev.info(selectedLang);
  dev.info(playThrough);

  const getGuest = async (guestId) => {
    if (!guestId) return;
    try {
      const result = await guest.get({
        companyId: 'LOCI',
        guestId,
      });
      if (result.success) {
        dev.log(result);
        setPlayThrough(new Set(result.item.playThroughs));
        setCurrentGuestState(result.item || currentGuestState);
      }
    } catch (e) {
      dev.error(e.response);
    }
  };

  const createGuest = async () => {
    if (currentGuestState.guestId !== undefined) return;
    try {
      const result = await guest.create({
        body: {
          companyId: COMP_ID,
          accessKey: currentAccessKeyState,
          selectedLang,
        },
      });

      if (result.success) {
        dev.log('createGuest IF');
        setCurrentGuestState(result.item);
        setCookie('guestid', result.item.guestId);
      }
    } catch (e) {
      dev.error(e.response);
    }
  };

  const updateGuest = async (grade, progress, score, completed) => {
    try {
      const result = await guest.update({
        payload: {
          companyId: currentGuestState.companyId,
          guestId: currentGuestState.guestId,
        },
        body: {
          locationKey: 'N/A',
          accessKey: currentAccessKeyState,
          course: APP_ID,
          grade,
          progress,
          score,
          completed,
          selectedLang,
          answers: currentAnswersList,
          playThroughs: Array.from(playThrough),
          plays: numPlays,
          visible: true,
        },
      });
      if (result.success) {
        dev.log('RESULT:', result.item);
      }
    } catch (e) {
      dev.error(e.response);
    }
  };

  return {
    createGuest,
    updateGuest,
    getGuest,
  };
};

export default useDataApi;
