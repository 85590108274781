/* eslint-disable react/no-unescaped-entities */
/* eslint-disable consistent-return */
import React from 'react';
import { v4 as uuid } from 'uuid';
import API from 'API';
import { feedback } from 'API/services';
import { faqModal, formModal, imageState, gdprOpenState } from 'store/atoms';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import useLocalization from 'hooks/useLocalization';
import CloseIcon from '@material-ui/icons/Close';
import {
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
} from '@material-ui/core';
import getURL from 'utils/getURL';
import DevConsole from 'utils/DevConsole';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';
import { Modal, Grid, Text, Button, Faq, ModalSplit } from 'components/UI';
import useStyles from './styles';

const api = new API();
const dev = new DevConsole('FeedbackForm');

const Form = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState({
    feedbackType: '',
    description: '',
  });
  const [title, setTitle] = React.useState('feedback');
  const faqModalOpen = useRecoilValue(faqModal);
  const images = useRecoilValue(imageState);
  const resetImages = useResetRecoilState(imageState);
  const setFormModalOpen = useSetRecoilState(formModal);
  const setGdprOpen = useSetRecoilState(gdprOpenState);
  const t = useLocalization('Feedback');

  const closeModal = () => {
    setFormModalOpen(false);
    setGdprOpen(true);
    resetImages();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  /**
   * Create method from the feedback service.
   * Generates a unique id for the feedback form.
   * We grab the s3 object link from s3 with the key we generated for the screenshot.
   * This is sent with the rest of the form values.
   *
   * @returns {Promise}
   */
  const create = async () => {
    try {
      const userCredentials = await api.auth.currentCredentials();
      const result = await feedback.create({
        body: {
          feedbackId: uuid(),
          img: `https://s3.amazonaws.com/${getURL(false)}/protected/${userCredentials.identityId}/${images.key}`,
          ...formValues,
        },
      });
      dev.log(getURL());
      dev.log('Identity Id', userCredentials.identityId);
      dev.log(result);
      return result;
    } catch (e) {
      dev.error(e.response);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTitle(t('Thanks for sharing!') || 'Thanks for sharing!');
    setFormValues({ feedbackType: '', description: '' });
    create();
  };

  return (
    <Grid className={classes.parentFlex}>
      <Modal open={faqModalOpen}>
        <Faq />
      </Modal>
      <Grid className={classes.formFlex}>
        <form className={classes.root} onSubmit={onSubmit}>
          <Text
            className={classes.title}
            family="chollasansreg"
          >
            {t(title) || title}
          </Text>
          <FormControl className={classes.select}>
            <Select
              label="feedback"
              name="feedbackType"
              value={formValues.feedbackType}
              displayEmpty
              className={classes.textField}
              onChange={handleChange}
              IconComponent={() => <ExpandMoreSharpIcon fontSize="large" />}
            >
              <MenuItem value="" disabled>
                {t('type') || 'Select feedback type'}
              </MenuItem>
              <MenuItem value="Germs">{t('Cant find') || 'I can’t find any germs'}</MenuItem>
              <MenuItem value="Design">{t('Wonky') || 'The design looks wonky'}</MenuItem>
              <MenuItem value="Frozen">{t('Frozen') || 'I’m stuck or its frozen'}</MenuItem>
              <MenuItem value="Other">{t('Other') || 'Other'}</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <TextareaAutosize
              className={classes.textArea}
              name="description"
              aria-label="Description"
              value={formValues.description}
              minRows={10}
              placeholder={t('Feedback subtext in box') || 'Please share your experience.'}
              onChange={handleChange}
            />

            <Button
              type="submit"
              tabIndex="0"
              variant="outlined"
              className={classes.formBtn}
            >
              {t('Submit') || 'SUBMIT'}
            </Button>
          </FormControl>
        </form>
      </Grid>
      <ModalSplit />
      <CloseIcon color="primary" className={classes.close} role="button" tabIndex="0" onClick={closeModal} />
    </Grid>
  );
};

export default Form;
