import React from 'react';
import useLocalization from 'hooks/useLocalization';
import { Grid, Asset, Action, Text, PrivacyPolicy } from 'components/UI';
import { Link } from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { faqModal, policyTextState } from 'store/atoms';
import megaphone from 'assets/default/icons/megaphone_icon_only@3x.png';
import iconTap from 'assets/default/icons/tap_ui_infographic_icon@3x.png';
import useStyles from './styles';

/**
 * This is a layout component for a split view of the feedback form, it handles showing the content on the left side
 *
 *
 * @returns {React.Component}
 */
const ModalSplit = () => {
  // Global state
  const [faqModalOpen, setFaqModalOpen] = useRecoilState(faqModal);
  const policyContent = useRecoilValue(policyTextState);

  // custom css
  const classes = useStyles();
  const t = useLocalization('Feedback');
  const tGlobal = useLocalization('Global');

  // local state
  const [openPolicy, setOpenPolicy] = React.useState(false);

  const faqHandler = () => {
    return !faqModalOpen ? setFaqModalOpen(true) : null;
  };

  return (
    <Grid className={classes.contentFlex}>
      <Grid className={classes.textFlex}>
        <Text className="title" family="spyitalic-semi">{t('headline') || 'HOW TO PLAY'}</Text>
        <Text family="chollasansreg" className="instruction">{t('subtext') || 'TAP THE CORRECT ANSWER ON EACH SCREEN'}</Text>
        <Asset src={iconTap} />
      </Grid>
      <Grid className={classes.socialContainer}>
        <Asset className={classes.megaphone} src={megaphone} />
        <section className={classes.faqCTA}>
          <Action action={faqHandler} text={tGlobal('FAQ upper') || 'FREQUENTLY ASKED QUESTIONS'} />
          <Link
            href="#"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
            onClick={() => setOpenPolicy(!openPolicy)}
          >
            Read Our Privacy Policy
          </Link>
        </section>
      </Grid>
      <PrivacyPolicy
        open={openPolicy}
        setOpen={setOpenPolicy}
        policyContent={policyContent}
      />
    </Grid>
  );
};

export default ModalSplit;
