import kitchen from 'assets/default/backgrounds/kitchen-min.jpg';
import table from 'assets/default/backgrounds/table-min.jpg';
import talkingMask from 'assets/default/backgrounds/talking-mask-min.jpg';
import camping from 'assets/default/backgrounds/camping-min.jpg';
import sneeze from 'assets/default/backgrounds/sneeze-elbow-min.jpg';
import fitness from 'assets/default/backgrounds/fitness-min.jpg';
import eatingTogether from 'assets/default/backgrounds/eating-together-min.jpg';
import track from 'assets/default/backgrounds/track-min.jpg';
import shaking from 'assets/default/backgrounds/shaking-min.jpg';
import airConditioners from 'assets/default/backgrounds/air-conditioners-min.jpg';
import sittingLeast from 'assets/default/backgrounds/sitting-least-min.jpg';
import gathering from 'assets/default/backgrounds/gathering-min.jpg';
import mask from 'assets/default/backgrounds/mask-min.jpg';
import apart from 'assets/default/backgrounds/apart-min.jpg';
import handwashing from 'assets/default/backgrounds/wash-hands-min.jpg';
import sneezeDroplets from 'assets/default/backgrounds/sneeze-min.jpg';

// Polygons
import plastic1 from 'assets/default/polygons/kitchen/plastic/1.png';
import plastic2 from 'assets/default/polygons/kitchen/plastic/2.png';
import plastic3 from 'assets/default/polygons/kitchen/plastic/3.png';
import plastic4 from 'assets/default/polygons/kitchen/plastic/4.png';

import steel1 from 'assets/default/polygons/kitchen/steel/1.png';
import steel2 from 'assets/default/polygons/kitchen/steel/2.png';
import steel3 from 'assets/default/polygons/kitchen/steel/3.png';
import steel4 from 'assets/default/polygons/kitchen/steel/4.png';

import wood1 from 'assets/default/polygons/table/wood/1.png';
import glass1 from 'assets/default/polygons/table/glass/1.png';

export default {
  LEVEL_1: {
    QUESTION_1: {
      value: 1,
      background: kitchen,
      translationScene: 'GLOBAL',
      HOT_SPOTS: {
        HOT_SPOT_1: {
          key: 'HOT_SPOT_1',
          question: 'How many hours can COVID-19 survive on plastic?',
          surface: 'PLASTIC',
          choices: {
            'Option 2': 96,
            'Option 1 CORRECT': 72,
            'Option 3': 24,
            'option 4': 8,
          },
          correctAnswer: 72,
          prompt: 'COVID-19 can survive up to 72 hours on plastic surfaces.',
          polygons: [
            { x: 27, y: 69, w: 3, h: 13, image: plastic1 },
            { x: 49.2, y: 17, w: 19.1, h: 23.2, image: plastic2 },
            { x: 88.7, y: 52, w: 9.7, h: 13.5, image: plastic3 },
            { x: 92.6, y: 81, w: 2.7, h: 7.5, image: plastic4 },
          ],
          translationScene: 'PLASTIC',
          correctKeyword: 'Option 1 CORRECT',
          promptKeyword: 'answer copy',
        },
        HOT_SPOT_2: {
          key: 'HOT_SPOT_2',
          question: 'How many hours can COVID-19 survive on steel?',
          surface: 'STEEL',
          choices: {
            'Option 4': 8,
            'Option 3': 24,
            'Option 1 Correct': 72,
            'Option 2': 96,
          },
          correctAnswer: 72,
          prompt: 'COVID-19 can live up to 72 hours on steel surfaces.',
          polygons: [
            { x: 7, y: 83.8, w: 29, h: 4.3, image: steel1 },
            { x: 20.3, y: 39.8, w: 6.8, h: 42.3, image: steel2 },
            { x: 57, y: 66, w: 11, h: 15, image: steel3 },
            { x: 9, y: 25, w: 10, h: 2, image: steel4 },
            { x: 27.5, y: 25, w: 10, h: 2, image: steel4 },
            { x: 45, y: 6.2, w: 10, h: 2, image: steel4 },
            { x: 59.8, y: 6.2, w: 10, h: 2, image: steel4 },
            { x: 75.2, y: 6.2, w: 10, h: 2, image: steel4 },
            { x: 90, y: 6.2, w: 9.5, h: 2, image: steel4 },
          ],
          translationScene: 'STEEL',
          correctKeyword: 'Option 1 Correct',
          promptKeyword: 'Answer copy',
        },
      },
    },
    QUESTION_2: {
      value: 2,
      background: table,
      translationScene: 'GLOBAL',
      HOT_SPOTS: {
        HOT_SPOT_1: {
          key: 'HOT_SPOT_1',
          question: 'How many hours can COVID-19 survive on wood?',
          surface: 'WOOD',
          choices: {
            'Option 3': 72,
            'Option 1 Correct': 48,
            'Option 2': 96,
            'Option 4': 24,
          },
          correctAnswer: 48,
          prompt: 'COVID-19 can live up to 48 hours on wood surfaces.',
          polygons: [
            { x: 39, y: 33.3, w: 42, h: 30, image: wood1 },
          ],
          translationScene: 'WOOD',
          correctKeyword: 'Option 1 Correct',
          promptKeyword: 'answer Copy',
        },
        HOT_SPOT_2: {
          key: 'HOT_SPOT_2',
          question: 'How many hours can COVID-19 survive on glass?',
          surface: 'GLASS',
          choices: {
            'Option 4': 24,
            'Option 2': 8,
            'Option 3': 72,
            'Option 1 correct': 96,
          },
          correctAnswer: 96,
          prompt: 'COVID-19 can live up to 96 hours on glass surfaces.',
          polygons: [
            { x: 57.4, y: 15, w: 13, h: 33, image: glass1 },
          ],
          translationScene: 'GLASS',
          correctKeyword: 'Option 1 correct',
          promptKeyword: 'answer copy',
        },
      },
    },
  },
  LEVEL_2: {
    QUESTION_1: {
      background: talkingMask,
      question: 'Which situation is safer?',
      topic: 'talking',
      choices: [{
        'Option 1 CORRECT': 'TALKING WITH YOUR MASK ON',
        'Option 2': 'TALKING WITH NO MASK',
      }],
      correctAnswer: 'TALKING WITH YOUR MASK ON',
      prompt: `Wearing a mask is safer. Droplets carrying the virus come out of your mouth when you talk, cough or sneeze. 
        Wearing a mask stops these droplets from entering the air. 
        Masks protect you from breathing in droplets from other people, too.`,
      value: 1,
      translationScene: 'TALKING',
      correctKeyword: 'Option 1 CORRECT',
      promptKeyword: 'Answer copy',
    },
    QUESTION_2: {
      background: camping,
      question: 'Which situation is safer?',
      topic: 'partyCamping',
      choices: [{
        'Option 1 correct': 'GOING CAMPING',
        'Option 2': 'GOING TO A PARTY',
      }],
      correctAnswer: 'GOING CAMPING',
      prompt: `Doing stuff outside is safer. Inside, droplets that you breathe out gather in the air. 
        The flow of air outside breaks up the droplets and blows them away. 
        Sunshine also evaporates droplets and kills the virus.`,
      value: 2,
      translationScene: 'PARTYING',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
    QUESTION_3: {
      background: sneeze,
      question: 'Which situation is safer?',
      topic: 'sneeze',
      choices: [{
        'OPTION 1 CORRECT': 'SNEEZING AT LEAST 6 FEET AWAY FROM PEOPLE',
        'OPTION 2': 'SNEEZING CLOSE TO PEOPLE',
      }],
      correctAnswer: 'SNEEZING AT LEAST 6 FEET AWAY FROM PEOPLE',
      prompt: `The launching power of a sneeze can send droplets up to 20 feet. 
        Most droplets will travel about 6 feet. 
        If you can, move away from people and sneeze into your elbow to stop the spread of droplets.`,
      value: 3,
      translationScene: 'SNEEZING',
      correctKeyword: 'OPTION 1 CORRECT',
      promptKeyword: 'Answer copy',
    },
    QUESTION_4: {
      background: fitness,
      question: 'Which situation is safer?',
      topic: 'fitness',
      choices: [{
        'Option 1': 'WORKING OUT AT THE GYM',
        'Option 2 correct': 'ATTENDING A FITNESS CLASS OUTSIDE',
      }],
      correctAnswer: 'ATTENDING A FITNESS CLASS OUTSIDE',
      prompt: `Exercising outside is safer. 
        People breathe heavily when they work out, so they exhale more droplets than normal. 
        The flow of air outside breaks up the droplets and blows them away.`,
      value: 4,
      translationScene: 'FITNESS',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
    QUESTION_5: {
      background: eatingTogether,
      question: 'Which situation is safer?',
      topic: 'eatingTogether',
      choices: [{
        'Option 1': 'EATING TOGETHER INDOORS',
        'Option 2 correct': 'EATING TOGETHER OUTDOORS',
      }],
      correctAnswer: 'EATING TOGETHER OUTDOORS',
      prompt: `Eating outside is safer. 
        The flow of air outside breaks up infected droplets and blows them away. 
        The sun helps kill the virus more quickly. 
        It is still important to stay 6 feet apart.`,
      value: 5,
      translationScene: 'EATING',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
  },
  LEVEL_3: {
    QUESTION_1: {
      background: mask,
      question: 'Who is protected when you wear a mask?',
      topic: 'masks',
      choices: {
        'Option 2': 'ME',
        'Option 3': 'OTHERS',
        'Option 1 CORRECT': 'BOTH',
      },
      correctAnswer: 'BOTH',
      prompt: `Everyone is protected when you wear a mask. 
        Your mask traps the droplets you breathe out, and it stops you from breathing in droplets from other people.`,
      value: 1,
      translationScene: 'MASKS',
      correctKeyword: 'Option 1 CORRECT',
      promptKeyword: 'Answer copy',
    },
    QUESTION_2: {
      background: apart,
      question: 'How many feet apart should you be when physical distancing?',
      topic: 'distance',
      choices: {
        'Option 2': 1,
        'Option 4': 2,
        'Option 1 correct': 6,
        'Option 3': 5,
      },
      correctAnswer: 6,
      prompt: `Safe physical distance is 6 feet. 
        Droplets can travel up to 20 feet, so it’s important to keep a mask on when you can, and sneeze or cough into your elbow.`,
      value: 2,
      translationScene: 'DISTANCE',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
    QUESTION_3: {
      background: handwashing,
      question: 'How many seconds does it take to kill COVID-19 when you wash your hands?',
      topic: 'handwash',
      choices: {
        'Option 3': 10,
        'Option 4': 15,
        'Option 2': 6,
        'Option 1 correct': 20,
      },
      correctAnswer: 20,
      prompt: `When you wash your hands with soap, it takes 20 seconds for the soap to break down a germ and kill it. 
        Washing for less than 20 seconds means that germs are still alive on our hands.`,
      value: 3,
      translationScene: 'HANDWASH',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'answer copy',
    },
    QUESTION_4: {
      background: sneezeDroplets,
      question: 'How far, in feet, do most sneeze droplets travel through the air before falling to the ground?',
      topic: 'sneeze',
      choices: {
        'Option 1 correct': 6,
        'Option 2': 3,
        'Option 3': 1,
        'Option 4': 5,
      },
      correctAnswer: 6,
      prompt: `Most sneeze droplets travel about 6 feet before falling to the ground. 
        Sneeze into your elbow to stop the spread of droplets, and stay 6 feet apart to be safe.`,
      value: 4,
      translationScene: 'SNEEZE',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
  },
  LEVEL_4: {
    QUESTION_1: {
      background: shaking,
      question: 'Which situation is safer?',
      choices: [{
        'Option 1': 'SHAKING HANDS',
        'Option 2 correct': 'BUMPING ELBOWS',
      }],
      correctAnswer: 'BUMPING ELBOWS',
      prompt: `Bumping elbows is safer than shaking hands because hands are more likely to have germs on them. 
        Waving is safest because you can do that from 6 feet away.`,
      value: 1,
      translationScene: 'HANDSHAKE',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
    QUESTION_2: {
      background: track,
      question: 'Which situation is safer?',
      choices: [{
        'Option 1 correct': 'TRACK AND FIELD',
        'Option 2': 'PLAYING FOOTBALL',
      }],
      correctAnswer: 'TRACK AND FIELD',
      prompt: 'No-contact sports like track and field are safer because they make it easier to stay at least 6 feet apart.',
      value: 2,
      translationScene: 'SPORTS',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
    QUESTION_3: {
      background: airConditioners,
      question: 'Which situation is safer?',
      choices: [{
        'Option 1': 'SITTING INSIDE WITH AIR CONDITIONING ON',
        'Option 2 correct': 'SITTING INSIDE WITH THE WINDOW OPEN',
      }],
      correctAnswer: 'SITTING INSIDE WITH THE WINDOW OPEN',
      prompt: `If you have the option, opening a window is safer. 
        Many air conditioners recycle inside air, which can spread infected droplets around the room.`,
      value: 3,
      translationScene: 'AIRFLOW',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
    QUESTION_4: {
      background: sittingLeast,
      question: 'Which situation is safer?',
      choices: [{
        'Option 1 correct': 'SITTING AT LEAST 6 FEET APART',
        'Option 2': 'SITTING TOGETHER ON A BENCH',
      }],
      correctAnswer: 'SITTING AT LEAST 6 FEET APART',
      prompt: `You can have Covid and not feel sick. 
        This is called being asymptomatic. 
        When you are asymptomatic you can still breathe infected droplets into the air, so it’s important to wear a mask and stay 6 feet apart.`,
      value: 4,
      translationScene: 'BENCH',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
    QUESTION_5: {
      background: gathering,
      question: 'Which situation is safer?',
      choices: [{
        'Option 1 correct': 'GATHERING WITH FRIENDS AT A PARK',
        'Option 2': 'GATHERING WITH FRIENDS AT HOME',
      }],
      correctAnswer: 'GATHERING WITH FRIENDS AT A PARK',
      prompt: `Hanging out at the park is safer. 90% of people who catch Covid get it indoors. 
        Sunny days are safest because heat and sunlight kill the virus and the breeze will blow droplets away.`,
      value: 5,
      translationScene: 'FRIENDS',
      correctKeyword: 'Option 1 correct',
      promptKeyword: 'Answer copy',
    },
  },
};
