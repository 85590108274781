import { makeStyles } from '@material-ui/core/styles';

const green = '#3fff00';
const gradientOpaco = 'linear-gradient(180deg, rgb(94 34 164 / 60%) 0%, rgb(24 37 91 / 60%) 100%)';
const borderWhite = 'solid 0.3vw #ffffff82';
const gradient = 'linear-gradient(180deg, rgb(94 34 164) 0%, rgb(24 37 91) 100%)';
const shadowBlue = '0.1vw 0.3vw 1vw dodgerblue';
const shadowBlack = '0.4vw 0.4vw 1vw #0000009e';
const shadowGreen = `0.1vw 0.1vw 1vw ${green}`;
const shadowRed = '0.1vw 0.3vw 1vw red';

const purple = '#8a2be29c';
const borderRed = 'solid 0.3vw red';
const borderGreen = `solid 0.3vw ${green}`;

const choisesBtnHalfBase = {
  color: 'white',
  background: gradient,
  fontFamily: 'sarica',
  fontSize: '2.4vw',
  textAlign: 'center',
  width: '80%',
  marginTop: '5%',
  marginRight: '5%',
  marginLeft: '5%',
  marginBottom: '7.5%',
  border: borderWhite,
  borderRadius: '0.8vw',
  textShadow: shadowBlue,
  boxShadow: shadowBlue,
  lineHeight: '3vw',
  padding: '1vw',
  '@media only screen and (min-height: 768px) and (max-width: 1280px)': {
    marginBottom: '10%',
  },
  '@media only screen and (min-height: 1000px) and (max-width: 1366px)': {

  },
};

const half = {
  width: '50%',
  height: '100%',
  position: 'absolute',
};
const questionStateContainer = {
  background: gradient,
  width: '40%',
  position: 'absolute',
  bottom: '12%',
  borderRadius: '1.5vw',
  boxShadow: shadowBlack,
  padding: '2vw',
  '@media only screen and (min-height: 768px) and (max-width: 1280px)': {
    width: '40%',
    bottom: '10%',
  },
  '@media only screen and (min-height: 1000px) and (max-width: 1366px)': {
    width: '40%',
    bottom: '15%',
  },
};

const useStyles = makeStyles({
  questionContentAgain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '10%',
  },
  question: {
    color: 'white',
    background: gradientOpaco,
    fontFamily: 'sarica',
    fontSize: '2.4vw',
    textAlign: 'center',
    width: '60%',
    borderRadius: '1em',
    padding: '1.5%',
    top: '15%',
    border: borderWhite,
  },
  choisesBtnContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  choisesBtnHalf: {
    ...choisesBtnHalfBase,
  },
  greenHalf: {
    ...half,
    border: borderGreen,
    background: '#0fff0d4d',
  },
  redHalf: {
    ...half,
    border: borderRed,
    background: '#ff000082',
    borderRadius: '1vw',
  },
  purpleHalf: {
    ...half,
    background: purple,
  },
  purpleHalfLeft: {
    left: '50%',
  },
  purpleHalfRight: {
    left: 0,
  },
  answerHalfRight: {
    left: '50%',
  },
  answerHalfLeft: {
    left: 0,
  },
  answerContainerHalfRight: {
    left: '55%',
  },
  answerContainerHalfLeft: {
    left: '5%',
  },
  questionStateContainerCorrect: {
    ...questionStateContainer,
    border: borderGreen,
  },
  questionStateContainerIncorrect: {
    ...questionStateContainer,
    border: borderRed,
  },
  questionStateTitleCorrect: {
    fontFamily: 'spyitalic',
    fontSize: '4vw',
    textShadow: shadowGreen,
    borderRadius: '1vw',
    letterSpacing: '0.3vw',
  },
  questionStateTitleIncorrect: {
    fontFamily: 'spyitalic',
    fontSize: '4vw',
    textShadow: shadowRed,
    borderRadius: '1vw',
    letterSpacing: '0.3vw',
  },
  questionStateDescription: {
    textShadow: shadowBlue,
    fontFamily: 'sarica',
    fontSize: '2vw',
    letterSpacing: '0.1vw',
    wordSpacing: '0.4vw',
  },
  nextButton: {
    position: 'absolute',
    bottom: '2%',
    right: '2%',
    zIndex: 9,
  },
});

export default useStyles;
