/* eslint-disable no-console */

/**
 * DEV console wrapper.
 *
 * Use as if you would use console, with a few extra features:
 *
 * If we use the mute() method, all comments except errors are silenced;
 * Auto-mute non-errors if no tag is specified;
 * Auto-mute all if not in dev;
 * Possibility to display extra, custom comments by using the verbose() method.
 *
 */
class DevConsole {
  constructor(tag) {
    this.tag = tag;
    this.silent = !tag;
    this.verbosity = false;
    this.grouping = 0;
    this.dev = process.env.NODE_ENV === 'development';
  }

  exec(method, char, rest) {
    if (this.dev && (!this.silent || method === 'error')) {
      if (this.grouping) {
        console[method](char, ...rest);
      } else {
        console[method](char, this.tag, ...rest);
      }
    } else {
      // If not in dev, we could log to an API instead...
    }
    return this;
  }

  group(label) {
    if (this.dev && !this.silent) {
      this.grouping += 1;
      console.group(`${this.tag} ${label}`);
    }
    return this;
  }

  ungroup() {
    if (this.dev && !this.silent && this.grouping > 0) {
      this.grouping -= 1;
      console.groupEnd();
    }
    return this;
  }

  unmute() {
    this.silent = false;
    return this;
  }

  mute() {
    this.silent = true;
    return this;
  }

  log(...rest) {
    return this.exec('log', '➡', rest);
  }

  info(...rest) {
    return this.exec('info', 'ℹ', rest);
  }

  warn(...rest) {
    return this.exec('warn', '❕', rest);
  }

  error(...rest) {
    return this.exec('error', '❌', rest);
  }

  // Custom comments for verbose-only consoles:
  verbose(verbosity = true) {
    this.verbosity = !!verbosity;
    return this;
  }

  comment(...rest) {
    if (this.verbosity) {
      return this.exec('log', '⁑', rest);
    }
    return this;
  }
}

export default DevConsole;
