import { makeStyles } from '@material-ui/core';

const shadowBlue = '0.1vw 0.3vw 1vw dodgerblue';
const shadowBlack = '0.4vw 0.4vw 1vw #0000009e';

const useStyles = makeStyles({
  locateContainer: {
    position: 'absolute',
    bottom: '2%',
    left: '2%',
  },
  locateIcon: {
    width: '8%',
    verticalAlign: 'bottom',
    marginRight: '1vw',
    transform: 'rotate(15deg)',
  },
  locateText: {
    fontFamily: 'sarica',
    fontSize: '3vw',
    verticalAlign: 'bottom',
    fontStyle: 'italic',
    textShadow: shadowBlack,
  },
  surfaceContent: {
    color: 'white',
    position: 'absolute',
    bottom: '2%',
    right: '2%',
    textAlign: 'right',
    textShadow: shadowBlue,
    lineHeight: '2.5vw',
  },
  surfaceTitle: {
    fontSize: '2.5vw',
    fontFamily: 'spyitalic',
  },
  surfaceInstruction: {
    fontSize: '2vw',
    fontFamily: 'sarica',
  },
});

export default useStyles;
