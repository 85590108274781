import { makeStyles } from '@material-ui/core/styles';

const shadow = '0.4vw 0.4vw 1vw dodgerblue';
const purple = '#8a2be285';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundPosition: 'center',
  },
  nextButton: {
    position: 'absolute',
    bottom: '2%',
    right: '2%',
    zIndex: 9,
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: purple,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  instruction: {
    fontFamily: 'spyitalic',
    color: 'white',
    fontSize: '3.5vw',
    textShadow: shadow,
    textAlign: 'center',
    margin: '2vw 21vw',
  },
  subInstruction: {
    fontFamily: 'sarica',
    fontSize: '2.2vw',
    textShadow: '0 0',
  },
  icon: {
    display: 'block',
    margin: '0 auto',
    width: '12%',
  },
  iconNext: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    border: 0,
    color: 'white',
    fontFamily: 'spyitalic',
    margin: '1.5vw',
  },
  iconText: {
    fontSize: '3vw',
    textShadow: shadow,
    verticalAlign: 'middle',
    marginRight: '1.5vw',
  },
  iconImg: {
    width: '8vw',
    verticalAlign: 'middle',
  },
  iconQuestion: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '8%',
  },
  shadowFrame: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    boxShadow: 'inset 0 0 4vw #5b22a0',
  },
  levelName: {
    fontSize: '6vw',
    letterSpacing: '1vw',
  },
});

export default useStyles;
