import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  parentFlex: {
    position: 'relative',
    zIndex: 5,
    padding: '9% 10% 5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textShadow: '2px 4px 9px #2A97F4E6',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: 'linear-gradient(180deg, rgba(95, 43, 168, 0.95), rgba(14, 38, 75, 0.95))',
    boxShadow: '0 0 15px #00a7ff',
    '@media screen and (orientation: landscape)': {
      flexDirection: 'row',
    },
    '@media screen and (orientation: portrait)': {
      padding: '20% 10%',
    },
  },
  close: {
    position: 'absolute',
    top: '2vw',
    right: '2vw',
    fontSize: '5vw',
    zIndex: 99999,
  },
  outer: {
    position: 'relative',
    zIndex: 999,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  wrapper: {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  overFlow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    order: 2,
    width: '75vw',
    height: '100vh',
    margin: 'auto',
  },
  general: {
    position: 'fixed',
    top: '7%',
    left: '15%',
    zIndex: 5,
    fontSize: '3vw',
    [theme.breakpoints.up('md')]: {
      left: '13%',
    },
    '@media screen and (orientation: portrait)': {
      fontSize: '3vh',
    },
  },
  faqFlex: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      width: '100%',
      '@media screen and (orientation: landscape)': {
        width: '100%',
      },
      '& p': {
        overflow: 'auto',
        margin: '2vh auto',
        fontSize: '2vw',
        fontStyle: 'italic',
        width: '100%',
        '@media screen and (orientation: landscape)': {
          width: '100%',
        },
        '@media screen and (orientation: portrait)': {
          fontSize: '3vh',
        },
      },
    },
  },
  answer: {
    borderLeft: '2px solid #FFFFFF5F',
    width: '63vw',

    [theme.breakpoints.up('xs')]: {
      marginLeft: '5vw',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '3vw',
    },

    '& p': {
      margin: '0 !important',
    },
  },
}));

export default useStyles;
