import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  icon: {
    margin: '0.5%',
    width: '4%',
  },
});

export default useStyles;
