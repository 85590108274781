import { atom } from 'recoil';
import {
  GAME_STATE,
  LEVELS,
  LEVEL_PROGRESS,
  ANSWER_PROGRESS,
  GERM_HOT_SPOT_PROGRESS,
} from 'config/game';
import { FAQ } from 'config/faq';
import { sessionStorageEffect } from 'utils/session';

import {
  policyTextState,
} from 'components/UI/Compliance/state';

// Specific state translations
import {
  translationState,
  languageState,
  selectedLanguageState,
} from './localization/atoms';

// Guest API states
import {
  guestState,
  answersListState,
  accessKeyState,
  playState,
  playThroughState,
  allAccessKeyState,
} from './guest/atoms';

// Screenshot State
import {
  imageState,
} from './screenshot/atoms';

import {
  gdprOpenState,
  cookieConsentState,
  shakeAnimationState,
} from './gdpr/atoms';

import {
  customURL,
} from './customURL/atoms';

// featureFlags
import {
  featuresState,
} from './feature/atoms';

/**
 *
 * Cool thing to add: Dynamically loading states
 *
 */

// Global state defined for the app:

// Game State
const gameState = atom({
  key: 'gameState',
  default: GAME_STATE.INTRO,
  effects_UNSTABLE: [
    sessionStorageEffect('gameState'),
  ],
});

// Level State
const levelState = atom({
  key: 'levelState',
  default: LEVELS.LEVEL_1,
  effects_UNSTABLE: [
    sessionStorageEffect('levelState'),
  ],
});

const levelProgress = atom({
  key: 'levelProgress',
  default: LEVEL_PROGRESS.INSTRUCTION,
  effects_UNSTABLE: [
    sessionStorageEffect('levelProgress'),
  ],
});

const nextLevel = atom({
  key: 'nextLevel',
  default: 1,
  effects_UNSTABLE: [
    sessionStorageEffect('nextLevel'),
  ],
});

const levelScore = atom({
  key: 'levelScore',
  default: {
    LEVEL_1: 0,
    LEVEL_2: 0,
    LEVEL_3: 0,
    LEVEL_4: 0,
  },
  effects_UNSTABLE: [
    sessionStorageEffect('levelScore'),
  ],
});

// Question State
const questionState = atom({
  key: 'questionState',
  default: 1,
  effects_UNSTABLE: [
    sessionStorageEffect('questionState'),
  ],
});

const answerState = atom({
  key: 'answerState',
  default: ANSWER_PROGRESS.UNANSWERED,
  effects_UNSTABLE: [
    sessionStorageEffect('answerState'),
  ],
});

const germHotSpotProgress = atom({
  key: 'germHotSpotProgress',
  default: GERM_HOT_SPOT_PROGRESS.SELECTION,
  effects_UNSTABLE: [
    sessionStorageEffect('germHotSpotProgress'),
  ],
});

const germHotSpotState = atom({
  key: 'germHotSpotState',
  default: '',
  effects_UNSTABLE: [
    sessionStorageEffect('germHotSpotState'),
  ],
});

const germHotSpotCollection = atom({
  key: 'germHotSpotCollection',
  default: [],
  effects_UNSTABLE: [
    sessionStorageEffect('germHotSpotCollection'),
  ],
});

const noGerms = atom({
  key: 'noGerms',
  default: false,
  effects_UNSTABLE: [
    sessionStorageEffect('noGerms'),
  ],
});

const correctState = atom({
  key: 'correctState',
  default: null,
  effects_UNSTABLE: [
    sessionStorageEffect('correctState'),
  ],
});

const incorrectState = atom({
  key: 'incorrectState',
  default: null,
  effects_UNSTABLE: [
    sessionStorageEffect('incorrectState'),
  ],
});

const showNext = atom({
  key: 'showNext',
  default: false,
  effects_UNSTABLE: [
    sessionStorageEffect('showNext'),
  ],
});

const btnSideState = atom({
  key: 'btnSideState',
  default: 0,
  effects_UNSTABLE: [
    sessionStorageEffect('btnSideState'),
  ],
});

const questionsAnsweredCount = atom({
  key: 'questionsAnsweredCount',
  default: 0,
  effects_UNSTABLE: [
    sessionStorageEffect('questionsAnsweredCount'),
  ],
});

// Time
const maxTimeOutMs = atom({
  key: 'maxTimeOutMs',
  default: 3000,
  effects_UNSTABLE: [
    sessionStorageEffect('maxTimeOutMs'),
  ],
});

// UI Atoms
const formModal = atom({
  key: 'formModal',
  default: false,
  effects_UNSTABLE: [
    sessionStorageEffect('formModal'),
  ],
});

const faqModal = atom({
  key: 'faqModal',
  default: false,
  effects_UNSTABLE: [
    sessionStorageEffect('faqModal'),
  ],
});

const langModal = atom({
  key: 'langModal',
  default: false,
  effects_UNSTABLE: [
    sessionStorageEffect('langModal'),
  ],
});

const faqState = atom({
  key: 'faqState',
  default: FAQ,
  effects_UNSTABLE: [
    sessionStorageEffect('faqState'),
  ],
});

export {
  translationState,
  languageState,
  selectedLanguageState,
  guestState,
  answersListState,
  accessKeyState,
  playState,
  playThroughState,
  gameState,
  levelState,
  levelProgress,
  nextLevel,
  levelScore,
  questionState,
  answerState,
  germHotSpotProgress,
  germHotSpotState,
  germHotSpotCollection,
  noGerms,
  correctState,
  incorrectState,
  showNext,
  btnSideState,
  questionsAnsweredCount,
  maxTimeOutMs,
  formModal,
  faqModal,
  langModal,
  faqState,
  imageState,
  gdprOpenState,
  cookieConsentState,
  customURL,
  policyTextState,
  shakeAnimationState,
  allAccessKeyState,
  featuresState,
};
