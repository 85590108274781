import Recoil from 'recoil';
import { sessionStorageEffect } from 'utils/session';
import { guest } from 'API/services';
import {
  // playThroughState,
  // answersListState,
  guestState,
} from './atoms';

const guestPlayState = Recoil.selector({
  key: 'guestPlayState',
  get: async ({ get }) => {
    // const playThrus = get(playThroughState);
    // const answerList = get(answersListState);
    const currentGuestState = get(guestState);

    const getGuest = await guest.get({
      companyId: 'LOCI',
      guestId: currentGuestState.guestId,
    });

    return getGuest;
  },
  effects_UNSTABLE: [
    sessionStorageEffect('guestPlayState'),
  ],
});

export {
  guestPlayState,
};
