import React from 'react';
import useCustomAsset from 'hooks/useCustomAsset';
import DevConsole from 'utils/DevConsole';
import PropTypes from 'prop-types';
import Asset from '../Asset/Asset';


const dev = new DevConsole('CustomAsset');

/**
 * Additional custom asset rendering for a specific brand
 *
 * @param {*} props - any and all properties of this component
 * @param {string} props.defaultSrc - the url of the default asset for GSI
 * @param {string} props.customSrc - the url of the custom asset we want to render
 * @param {string} props.url - the url after the slash that denotes we need to render a custom asset
 * @param {boolean} props.cloudFront - true if we need an asset from cloudfront instead of the default
 * @returns {React.Component}
 */
const CustomAsset = ({ customSrc, defaultSrc, slash, cloudFront, ...props }) => {
  const { src } = useCustomAsset(slash, {
    customAsset: customSrc,
    defaultAsset: defaultSrc,
    cloudFront,
  });

  // TODO: for future reference in case we want to use any other brands with custom assets
  const matchCurrentURL = (url) => {
    switch (url) {
      case 'bclions':
        return true;
      default:
        throw new Error('No content found');
    }
  };

  dev.log({ customSrc, defaultSrc, slash, matchCurrentURL });
  return (
    <>
      <Asset
        src={src}
        cache
        {...props}
      />
    </>
  );
};

CustomAsset.propTypes = {
  defaultSrc: PropTypes.string.isRequired,
  customSrc: PropTypes.string.isRequired,
  slash: PropTypes.string.isRequired,
  cloudFront: PropTypes.bool,
};

CustomAsset.defaultProps = {
  cloudFront: false,
};

export default CustomAsset;
