import Recoil from 'recoil';
import { sessionStorageEffect } from 'utils/session';

const guestState = Recoil.atom({
  key: 'guestState',
  default: {
    companyId: 'LOCI',
    guestId: undefined,
    accessKey: 'N/A',
    answers: {},
    grade: 'N/A',
    progress: 0,
    score: 0,
    selectedLang: 'en',
    completed: false,
    playThroughs: [],
    plays: 0,
    locationKey: 'N/A',
  },
  effects_UNSTABLE: [
    sessionStorageEffect('guestState'),
  ],
});

const answersListState = Recoil.atom({
  key: 'answersListState',
  default: [],
  effects_UNSTABLE: [
    sessionStorageEffect('answersListState'),
  ],
});

const allAccessKeyState = Recoil.atom({
  key: 'allAccessKeyState',
  default: [],
  effects_UNSTABLE: [
    sessionStorageEffect('allAccessKeyState'),
  ],
});

const accessKeyState = Recoil.atom({
  key: 'accessKeyState',
  default: 'N/A',
  effects_UNSTABLE: [
    sessionStorageEffect('accessKeyState'),
  ],
});

// Signifies a complete playthrough of the game
const playState = Recoil.atom({
  key: 'playState',
  default: 0,
  effects_UNSTABLE: [
    sessionStorageEffect('playState'),
  ],
});

// Answers given for each successful playthrough
const playThroughCollection = new Set();
const playThroughState = Recoil.atom({
  key: 'playThroughState',
  default: playThroughCollection,
});

export {
  guestState,
  answersListState,
  accessKeyState,
  playState,
  playThroughState,
  allAccessKeyState,
};
