/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { CustomAsset, Action, Feature } from 'components/UI';
import DevConsole from 'utils/DevConsole';
import {
  useRecoilValue,
} from 'recoil';
// Base Config
import questions from 'config/questions';
// Base Config for Levels
import {
  ANSWER_PROGRESS,
  GERM_HOT_SPOT_PROGRESS,
} from 'config/game';
// Global Game State
import {
  questionState,
  answerState,
  correctState,
  incorrectState,
  germHotSpotState,
  germHotSpotProgress,
  levelState,
  customURL,
} from 'store/atoms';
import thumbUp from 'assets/default/horatio/horatio_up-min.png';
import thumbDown from 'assets/default/horatio/horatio_down-min.png';

// Child
import useStyles from './styles';

const dev = new DevConsole('PlayerAnswers');

/**
 * This component does a lot of conditional rendering based on what answer the player chooses
 *
 * @param {*} props
 * @param {Function} props.validateAnswer - checks if our answers are correct and renders the next question
 * @param {Function} props.tQuestions - translations for specific questions
 * @param {Function} props.tGlobal - translation API
 *
 * @returns {React.Component}
 */
const PlayerAnswer = ({
  selectedLang,
  tGlobal,
  tQuestions,
  nextQuestionHandler,
}) => {
  dev.log('rendered');
  // Global Game Questions
  const currentQuestionState = useRecoilValue(questionState);

  // Global Answer State
  const currentAnswerState = useRecoilValue(answerState);

  // Global Correct/Incorrect
  const currentCorrectState = useRecoilValue(correctState);
  const currentIncorrectState = useRecoilValue(incorrectState);

  // Hot Spots Already interacted with
  // const currentGermHotSpotCollection = useRecoilValue(germHotSpotCollection);

  // Overall HotSpot level progress
  const currentGermHotSpotProgress = useRecoilValue(germHotSpotProgress);

  // Hot Spot Global State
  const currentGermHotSpotState = useRecoilValue(germHotSpotState);

  // Global Level State
  const currentLevelState = useRecoilValue(levelState);
  // for custom assets
  const isCustomURL = useRecoilValue(customURL);

  const props = {
    custom: isCustomURL,
    language: selectedLang,
  };

  // Destructure from Config
  const { CORRECT, TRY_AGAIN } = ANSWER_PROGRESS;
  const { QUESTION } = GERM_HOT_SPOT_PROGRESS;

  // custom CSS
  const classes = useStyles(props);

  const playerAnswerText = () => {
    if (currentAnswerState === CORRECT) {
      return (
        <>
          <div className={classes.correct}>
            {tGlobal('CORRECT') || 'CORRECT'}
          </div>
        </>
      );
    }
    if (currentAnswerState === TRY_AGAIN) {
      return (
        <>
          <div className={classes.incorrect}>
            {tGlobal('INCORRECT') || 'INCORRECT'}
          </div>
        </>
      );
    }
  };

  const playerAnswerAvatar = () => {
    if (currentAnswerState === CORRECT) {
      return (
        <>
          <Feature
            keyword="AssetSwap"
            fallback={
              (
                <CustomAsset
                  defaultSrc={thumbUp}
                  customSrc="leothumbsupx3.png"
                  slash="bclions"
                  cloudFront
                  className={classes.avatarImg}
                />
              )
            }
            component={
              (
                <CustomAsset
                  defaultSrc={thumbUp}
                  customSrc="BC_Lions_transparent_thumbs_up_full_body_450x450.gif"
                  slash="bclions"
                  cloudFront
                  className={classes.avatarImg}
                />
              )
            }
          />
        </>
      );
    }
    if (currentAnswerState === TRY_AGAIN) {
      return (
        <>
          <Feature
            keyword="AssetSwap"
            fallback={
              (
                <CustomAsset
                  defaultSrc={thumbDown}
                  customSrc="leothumbsdownx3.png"
                  slash="bclions"
                  cloudFront
                  className={classes.avatarImg}
                />
              )
            }
            component={
              (
                <CustomAsset
                  defaultSrc={thumbDown}
                  customSrc="BC_Lions_transparent_thumbs_down_full_body_450x450.gif"
                  slash="bclions"
                  cloudFront
                  className={classes.avatarImg}
                />
              )
            }
          />
        </>
      );
    }
  };

  const handleBothAnswerStates = () => {
    // If correct and incorrect are both false we render the question
    if (!currentCorrectState && !currentIncorrectState) {
      return (
        <>
          {tQuestions('Question') || questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].question}
        </>
      );
    }
    // If currentCorrectState OR incorrect is true we render the question prompt
    if (currentCorrectState || currentIncorrectState) {
      return (
        <>
          {
            tQuestions(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].promptKeyword)
            || questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].prompt
          }
        </>
      );
    }
  };

  const handleOneAnswerState = () => {
    if (!currentCorrectState) {
      return (
        <>
          <div className={classes.surfaceContent}>
            <div className={classes.surfaceTitle}>
              {
                tQuestions(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].surface)
                || questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].HOT_SPOTS[currentGermHotSpotState].surface
              }
            </div>
            <div className={classes.surfaceInstruction}>
              {tGlobal('INFECTED AREA VIEW') || 'INFECTED AREA VIEW'}
            </div>
          </div>
        </>
      );
    }
    return (
      <div className={classes.nextButton}>
        <Action text={tGlobal('NEXT') || 'NEXT'} action={nextQuestionHandler} />
      </div>
    );
  };

  return currentGermHotSpotProgress === QUESTION && (
    <>
      <section className={classes.purpleOverlay} />
      <div className={classes.questionContent}>
        <div className={classes.question}>
          {handleBothAnswerStates()}
          {playerAnswerText()}
        </div>
      </div>
      <div className={classes.avatarPosition}>
        {playerAnswerAvatar()}
      </div>
      {handleOneAnswerState()}
    </>
  );
};

PlayerAnswer.propTypes = {
  tGlobal: PropTypes.func.isRequired,
  tQuestions: PropTypes.func.isRequired,
  nextQuestionHandler: PropTypes.func.isRequired,
  selectedLang: PropTypes.string.isRequired,
};

export default PlayerAnswer;
