import React from 'react';
import PropTypes from 'prop-types';
import useLocalization from 'hooks/useLocalization';
import DevConsole from 'utils/DevConsole';
import { useRecoilValue } from 'recoil';
import { Grid, Text, CustomAsset, Action, Feature } from 'components/UI';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import horatio from 'assets/default/total_score/horatio_scrubs_thumbs_up_front_pose@2x.png';
import { getStatus } from 'utils/getStatus';
import { getScores } from 'utils/getScores';
import { detectLang } from 'utils/detectLang';
import { levelScore, selectedLanguageState, customURL } from 'store/atoms';
import _ from 'lodash';
import clsx from 'clsx';
import useStyles from './styles';

const dev = new DevConsole('Scores');

/**
 * Scores UI component for the TotalScene
 *
 * @param {object} props
 * @param {object} props.scoreState - from the levelStateScore atom
 * @param {object} props.levels - the object for comparison with the scoreState
 * @param {object} props.rank - the players rank based on their score
 * @param {object} props.maxQuestions - the maximum number of questions from the scoreState object
 * @param {number} props.overAllScore - the number representing the user's overall score
 * @param {number} props.overAllQuestion - value of the overall questions used to calculate score
 * @param {Function} props.handler - function passed down from TotalScene
 * @returns {React.Component}
 */
const Scores = ({
  scoreState,
  rank,
  levels,
  maxQuestions,
  overAllScore,
  overAllQuestion,
  handler,
}) => {
  const currentLevelScore = useRecoilValue(levelScore);
  const selectedLang = useRecoilValue(selectedLanguageState);
  const matches = useMediaQuery('(max-width: 900px)');
  const t = useLocalization('Congratulations');
  const tGlobal = useLocalization('Global');
  const tNumbers = useLocalization('Numbers');

  const isCustomURL = useRecoilValue(customURL);

  // CSS PROPS
  const props = {
    scoreBg: rank.scoreBg,
    language: detectLang(selectedLang, 'cr'),
    custom: isCustomURL,
  };
  const classes = useStyles(props);

  dev.log(rank.scoreBg);
  return (
    <Grid
      className={classes.scoreContainer}
    >
      <Grid xs={12} m={12} lg={12} className={classes.horatioContainer} item>
        <Feature
          keyword="AssetSwap"
          fallback={
            (
              <CustomAsset
                defaultSrc={horatio}
                customSrc="leocheersx3.png"
                slash="bclions"
                alt="Horatio"
                cloudFront
                className={classes.horatio}

              />
            )
          }
          component={
            (
              <CustomAsset
                defaultSrc={horatio}
                customSrc="BC_Lions_transparent_cheers_full_body_450x450.gif"
                slash="bclions"
                alt="Horatio"
                cloudFront
                className={classes.horatio}

              />
            )
          }
        />
      </Grid>
      <Grid
        spacing={0}
        className={classes.scoreTallyContainer}
        container
      >
        <Grid
          className={classes.scoreLevelContainer}
          container
        >
          {matches ? (
            <div>
              <Action
                text={t(getStatus(getScores(currentLevelScore).totalPercent).translationCopy) || getStatus(getScores(currentLevelScore).totalPercent).description}
                action={handler}
              />
            </div>
          ) : _.map(scoreState, (value, key) => {
            return (
              <Grid key={key} className={classes.scoreFlex} xs={12} m={12} lg={12} item>
                <Text color="primary" className={classes.scoreLevelLabel}>
                  {`${tGlobal('LEVEL') || 'LEVEL'} ${tNumbers(levels[key].value.toString()) || levels[key].value} ${tGlobal('score') || 'SCORE'}` || `${levels[key].text} SCORE`}
                </Text>
                <Text color="primary" className="score-value">
                  {`${tNumbers(value.toString()) || value}/${tNumbers(maxQuestions[key].toString()) || maxQuestions[key]}`}
                </Text>
              </Grid>
            );
          })}
        </Grid>
        <div>
          <Grid className={clsx(classes.finalScoreContainer, classes.finalScoreBg)}>
            <Text color="primary" className={classes.finalScoreLabel}>
              {t('Final score') || 'FINAL SCORE'}
            </Text>
            <Text color="primary" className="final-value">
              {`${tNumbers(overAllScore.toString()) || overAllScore}/${tNumbers(overAllQuestion.toString()) || overAllQuestion}`}
            </Text>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

Scores.propTypes = {
  scoreState: PropTypes.object.isRequired,
  rank: PropTypes.object.isRequired,
  levels: PropTypes.object.isRequired,
  maxQuestions: PropTypes.object.isRequired,
  overAllScore: PropTypes.number.isRequired,
  overAllQuestion: PropTypes.number.isRequired,
  handler: PropTypes.func,
};

Scores.defaultProps = {
  handler: () => { },
};

export default Scores;
