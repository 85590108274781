import React from 'react';
import {
  Text,
  CustomAsset,
} from 'components/UI';
import lociLogo from 'assets/default/logo/loci-logo-white@3x@3x.png';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * Logo UI component, handles whether or not additional text is displayed
 *
 * @param {object} props - any props passed down
 * @param {boolean} props.text - whether or not the logo has additional text
 * @returns {React.Component}
 */
const Logo = ({ text, ...rest }) => {
  const { pathname } = document.location;
  const props = {
    custom: pathname.includes('bclions'),
  };
  const classes = useStyles(props);

  // Workaround for a rendering issue with using booleans to render text with React
  const renderText = () => {
    if (text) {
      return pathname.includes('bclions') ? null : <Text family="chollasansreg">Powered By</Text>;
    }
    return null;
  };

  return (
    <div
      className={classes.root}
      {...rest}
    >
      {renderText()}
      <CustomAsset
        defaultSrc={lociLogo}
        customSrc="bclogox3.png"
        slash="bclions"
        cloudfront
        alt="LOCI logo"
        link

      />
    </div>
  );
};

Logo.propTypes = {
  text: PropTypes.bool.isRequired,
};

export default Logo;
