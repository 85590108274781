import React, { Suspense } from 'react';
import { ReactSession } from 'react-client-session';
import { RecoilRoot } from 'recoil';
import { CookiesProvider } from 'react-cookie';
import DevConsole from 'utils/DevConsole';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import ga from 'utils/ga';
import 'assets/style/global.scss';
import { Iframe, Asset } from 'components/UI';
import GameEngine from 'components/Game/GameEngine';
import gsiLogo from 'assets/default/labels/gsi_transparent@3x.png';
import theme from 'assets/style/theme';
import Rotation from '../Game/Scenes/Rotation';

const dev = new DevConsole('App').mute();


/**
 * App Component
 *
 * Root component for the game, renders the GameEngine
 * GameEngine then renders the SceneManager component which controls
 * whatever components on the tree get rendered according to the game logic
 *
 * @returns {React.Component}
 */
function App() {
  dev.log('App document path', document.path);
  ga.init();
  ga.event('SceneManager', 'Game loaded');
  ga.pageView(window.location.pathname + window.location.search);
  ReactSession.setStoreType('sessionStorage');
  dev.info('Render');

  return (
    <>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <CssBaseline />
          <CookiesProvider>
            <Suspense fallback={<Asset isLoadingImg src={gsiLogo} />}>
              <Iframe
                title="Germ Science Investigation"
              >
                <GameEngine />
              </Iframe>
            </Suspense>
          </CookiesProvider>
          <Rotation />
        </RecoilRoot>
      </ThemeProvider>
    </>
  );
}

export default App;
