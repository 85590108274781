import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundPosition: 'center',
    color: 'white',
    overflow: 'hidden',
  },
});

export default useStyles;
