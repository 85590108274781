import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { selectedLanguageState } from 'store/atoms';
import { Asset, Text } from 'components/UI';
// import clsx from 'clsx';
import DevConsole from 'utils/DevConsole';
import { detectLang } from 'utils/detectLang';
import greenArrows from 'assets/default/icons/GSI_green_arrows.svg';
import useSound from 'use-sound';
import clickSound from 'assets/sounds/click-sound.mp3';
import useStyles from './styles';

const dev = new DevConsole('Action');

/**
 * Action component for GO, SHARE and any other actions that need to happen in game
 *
 * @param {object} props
 * @param {string} props.text - any string passed to Text that will be displayed on the page
 * @param {Function} props.action - any handlers passed into props
 * @returns {React.Component}
 */
const Action = ({ text, action }) => {
  const selectedLang = useRecoilValue(selectedLanguageState);
  dev.log(selectedLang);

  // custom css
  const props = {
    language: detectLang(selectedLang, 'it', 'cr', 'tl'),
    currentLang: selectedLang,
    animation: '$glow 0.5s ease infinite alternate',
  };

  const classes = useStyles(props);

  // sounds
  const [play] = useSound(clickSound);
  const playSound = () => {
    play();
    action();
  };

  return (
    <div
      className={classes.root}
      role="button"
      tabIndex="0"
      onClick={playSound}
      onKeyUp={action}
    >
      <Text className={classes.actionText} family="spyitalic-semi">{text}</Text>
      <Asset className={classes.actionButton} src={greenArrows} />
    </div>
  );
};

Action.propTypes = {
  action: PropTypes.func.isRequired,
  text: PropTypes.any,
};

Action.defaultProps = {
  text: 'Action',
};

export default Action;
