import { makeStyles } from '@material-ui/core/styles';
import levelCompleteBg from 'assets/default/level_complete/blue_purple_trans_bg_inner@2x.png';

const baseCssForAvatar = {
  position: 'absolute',
  top: 0,
  left: '5vmax',
  width: '100%',
};

const baseCssForCustomAvatar = {
  position: 'absolute',
  bottom: 0,
  left: '5vmax',
  width: '100%',
  // clipPath: 'inset(15px 10px 0px 0px)',
};

const setStyleForAvatar = (condition) => {
  if (condition) {
    return baseCssForCustomAvatar;
  }
  return baseCssForAvatar;
};

const useStyles = makeStyles(() => ({
  root: props => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    backgroundImage: props.bgFromQuestion,
    backgroundSize: '100% 100%',
    backgroundClip: 'padding-box',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% 0%',
    overflow: 'hidden',
    opacity: 1,
    width: '100%',
    height: '100%',
    zIndex: 1,
  }),
  rootOverlay: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
    backgroundImage: `url(${levelCompleteBg})`,
    backgroundSize: '100% 100%',
    backgroundClip: 'padding-box',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% 0%',
    boxShadow: 'inset 0 0 3vw dodgerblue',
    opacity: 0.75,
    width: '100%',
    height: '100%',
  },
  containerRoot: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    color: '#FFF',
    textShadow: '2px 1px 9px #2A97F4E6',
    opacity: 1,
    zIndex: 3,
  },
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    margin: 0,
    padding: 0,
  },
  item: {
    flex: 1,
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 9,
  },
  horatioWrapper: {
    position: 'relative',
    width: '30vmax',
    height: '100%',
  },
  horatio: props => ({
    ...setStyleForAvatar(props.custom),
  }),
  radarWrapper: {
    position: 'absolute',
    top: '13vh',
    left: '68vw',
  },
  radar: {
    objectFit: 'contain',
    aspectRatio: 1 / 1,
    maxWidth: '25vw',
    height: '49vh',
  },
  levelWrapper: {
    height: '50%',
    width: '100%',
    margin: 'auto 0',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    flex: 0,
    position: 'relative',
    top: 0,
    left: 0,
  },
  levelComplete: {
    position: 'relative',
    left: '-7%',
    top: '-4%',
    width: '40vw',
    height: '30vh',
  },
  levelName: props => ({
    width: '100vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    fontSize: props.language ? '3vw' : '8vw',
    letterSpacing: '1vw',
  }),
  completeText: {
    width: '100vw',
    position: 'absolute',
    top: '90%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    fontSize: '4vw',
    letterSpacing: '0.25vw',
  },
  scorePanel: props => ({
    backgroundColor: '#2A7CFC2C',
    border: '3px solid #FFF',
    borderRadius: '5px',
    boxShadow: '2px 3px 9px #26ACC491',
    width: 'auto',
    height: 'auto',
    marginTop: '5%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    padding: props.language ? '1rem' : 0,
  }),
  scorePanelLabel: {
    fontSize: '2vw',
  },
  scorePanelScore: {
    paddingLeft: '5%',
    fontSize: '3vw',
    display: 'flex',
    '& div:nth-child(1)': {
      color: '#02F539',
    },
  },
}));

export default useStyles;
