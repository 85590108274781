/**
 * Localization API
 *
 * Wraps around the Localization API endpoint.
 */
import API from 'API';
import mockData from './mockData';

const api = new API();
const service = 'localization';
const path = {
  language: '/localization/language',
  translation: '/localization/item',
  group: '/localization/group',
};

const localization = {
  language: {
    mock: () => api.setMock(mockData),
    live: () => api.setMock(null),
    list: payload => api.exec({
      action: 'get',
      service,
      path: path.language,
      payload: {
        productId: payload.productId,
        active: payload.active,
      },
    }),
    create: init => api.exec({
      action: 'post',
      service,
      path: path.language,
      init: {
        headers: init.headers,
        body: init.body,
      },
    }),
    update: mixedData => api.exec({
      action: 'put',
      service,
      path: path.language,
      payload: {
        productId: mixedData.payload.productId,
        uuid: mixedData.payload.uuid,
      },
      init: {
        headers: mixedData.headers,
        body: mixedData.body,
      },
    }),
    delete: payload => api.exec({
      action: 'del',
      service,
      path: path.language,
      payload: {
        productId: payload.productId,
        uuid: payload.uuid,
      },
    }),
  },
  group: {
    mock: () => api.setMock(mockData),
    live: () => api.setMock(null),
    list: payload => api.exec({
      action: 'get',
      service,
      path: path.group,
      payload: {
        productId: payload.productId,
      },
    }),
    create: init => api.exec({
      action: 'post',
      service,
      path: path.group,
      init: {
        headers: init.headers,
        body: init.body,
      },
    }),
    update: mixedData => api.exec({
      action: 'put',
      service,
      path: path.group,
      payload: {
        productId: mixedData.payload.productId,
        uuid: mixedData.payload.uuid,
      },
      init: {
        headers: mixedData.headers,
        body: mixedData.body,
      },
    }),
    delete: payload => api.exec({
      action: 'del',
      service,
      path: path.group,
      payload: {
        productId: payload.productId,
        uuid: payload.uuid,
      },
    }),
  },
  translation: {
    mock: () => api.setMock(mockData),
    live: () => api.setMock(null),
    list: payload => api.exec({
      action: 'get',
      service,
      path: path.translation,
      payload: {
        productId: payload.productId,
      },
    }),
    create: init => api.exec({
      action: 'post',
      service,
      path: path.translation,
      init: {
        headers: init.headers,
        body: init.body,
      },
    }),
    update: mixedData => api.exec({
      action: 'put',
      service,
      path: path.translation,
      payload: {
        productId: mixedData.payload.productId,
        uuid: mixedData.payload.uuid,
      },
      init: {
        headers: mixedData.headers,
        body: mixedData.body,
      },
    }),
    delete: payload => api.exec({
      action: 'del',
      service,
      path: path.translation,
      payload: {
        productId: payload.productId,
        uuid: payload.uuid,
      },
    }),
  },
};

export default localization;
