import { makeStyles } from '@material-ui/core';

const languageFonts = (langCode) => {
  switch (langCode) {
    case 'es':
      return {
        fontSize: '2vmax',
      };
    case 'cr':
      return {
        fontSize: '1.5vmax',
      };
    case 'it':
      return {
        fontSize: '1.75vmax',
      };
    case 'de':
      return {
        fontSize: '1.75vmax',
      };
    case 'fr':
      return {
        fontSize: '1.50vmax',
      };
    case 'vi':
      return {
        fontSize: '1.75vmax',
      };
    case 'tl':
      return {
        fontSize: '1.75vmax',
      };
    default:
      return {
        fontSize: '2.5vmax',
      };
  }
};

const languageTransform = (langCode) => {
  switch (langCode) {
    case 'pt':
      return {
        transform: 'translate(16%, 55%)',
      };
    case 'fr':
      return {
        transform: 'translate(16%, 55%)',
      };
    case 'it':
      return {
        transform: 'translate(16%, 50%)',
      };
    case 'cr':
      return {
        transform: 'translate(16%, 25%)',
      };
    case 'de':
      return {
        transform: 'translate(16%, 50%)',
      };
    case 'vi':
      return {
        transform: 'translate(16%, 20%)',
      };
    case 'tl':
      return {
        transform: 'translate(16%, 50%)',
      };
    default:
      return {
        transform: 'translate(16%, 35%)',
      };
  }
};

const useStyles = makeStyles(() => ({
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
  },
  active: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    '& .rank-earned': {
      fontSize: '1.25vw',
      color: '#00FF30',
      display: 'inline-block',
      textShadow: '2px 4px 9px #2A97F4E6',
    },
    '& .rank-name': {
      fontSize: '1.75vmax',
      textShadow: '2px 4px 9px #2A97F4E6',
      display: 'block',
      margin: '0 !important',
      width: '75%',
    },
  },
  statusBadge: {
    width: '11vmax',
    zIndex: 999,
    '& img': {
      width: '100%',
    },
  },
  inactiveContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      textShadow: '2px 4px 9px #2A97F4E6',
      fontSize: '1.25vw',
    },
  },
  inactive: {
    width: '6vmax',
    '& img': {
      width: '100%',
    },
  },
  rankContent: props => ({
    ...languageTransform(props.currentLang),
  }),
  newbieActiveBadge: props => ({
    background: props.activeBadgeBg,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    width: '35vmax',
    height: '11vmax',
    transform: 'translate(-3.5vw, 1vh)',
    '@media only screen and (min-device-width: 960px) and (max-device-width: 1350px)': {
      transform: 'translate(-3.5vw, 0.75vh)',
    },
    // Ipad Pro
    '@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)': {
      transform: 'translate(-3.5vw, 0.65vh)',
    },
  }),
  noviceActiveBadge: props => ({
    background: props.activeBadgeBg,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    width: '35vmax',
    height: '11vmax',
    transform: 'translate(-6.5vw, 1vh)',
    '& .rank-name': {
      ...languageFonts(props.currentLang),
    },
  }),
  scientistActiveBadge: props => ({
    background: props.activeBadgeBg,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    width: '35vmax',
    height: '11vmax',
    transform: 'translate(-5vw, 0.5vh)',
  }),
}));

export default useStyles;
