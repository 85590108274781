import React from 'react';
import PropTypes from 'prop-types';
import useLocalization from 'hooks/useLocalization';
import {
  Grid,
  Asset,
  Logo,
  GermFlex,
  Mission,
  Modal,
  Language,
  Text,
  CustomAsset,
  Compliance,
  Feature,
} from 'components/UI';
import { useRecoilState, useRecoilValue } from 'recoil';
import { gameState, langModal, formModal, customURL } from 'store/atoms';
import DevConsole from 'utils/DevConsole';
// default assets
import language from 'assets/default/icons/language-selector-icon-only@3x.png';
import gsiBadge from 'assets/default/labels/gsi_transparent@3x.png';
import horatioBadge from 'assets/default/horatio/horatio_holding_badge@3x.png';
import podium from 'assets/default/images/sci_fi_podium@3x.png';
import { useCookies } from 'react-cookie';
import useStyles from './styles';

const dev = new DevConsole('IntroScene').mute();

/**
 * IntroScene Component
 *
 * @param {*} changeGameState - updates the current state of the game depending on player actions.
 * @returns {undefined}
 */
function IntroScene({ changeGameState }) {
  dev.log('Intro');
  const [langModalOpen, setLangModalOpen] = useRecoilState(langModal);
  const formModalOpen = useRecoilValue(formModal);
  const currentGameState = useRecoilValue(gameState);
  const isCustomURL = useRecoilValue(customURL);
  const [cookie] = useCookies();

  const props = {
    custom: isCustomURL,
  };

  const classes = useStyles(props);
  const t = useLocalization('Intro');

  const handleOpen = () => {
    setLangModalOpen(true);
  };

  const isIconVisible = () => {
    return !langModalOpen && !formModalOpen ? (
      <div className={classes.language} role="button" tabIndex="0" onClick={handleOpen} onKeyUp={handleOpen}>
        <Text family="Rubik" color="primary" className="translate">{t('TRANSLATE') || 'TRANSLATE'}</Text>
        <Asset src={language} />
      </div>
    )
      : null;
  };

  dev.log({ isCustomURL });
  return (
    <>
      {currentGameState === 'INTRO'
        ? (
          <>
            {isIconVisible()}
            <Modal open={langModalOpen}>
              <Language />
            </Modal>
          </>
        ) : null}
      <Grid spacing={0} className={classes.containerRoot} container>
        <Grid xs={4} md={4} lg={4} className={classes.item} item>
          <Grid container className={classes.gsiContainer}>
            <Asset
              src={gsiBadge}
              className={classes.gsiBadge}
            />
          </Grid>
          <Grid className={classes.horatioContainer} container>
            <Feature
              keyword="AssetSwap"
              fallback={
                (
                  <CustomAsset
                    defaultSrc={horatioBadge}
                    customSrc="leocheersx3.png"
                    slash="bclions"
                    cloudFront
                    className={classes.avatarImg}
                  />
                )
              }
              component={
                (
                  <CustomAsset
                    defaultSrc={horatioBadge}
                    customSrc="BC_Lions_transparent_cheers_full_body_450x450.gif"
                    slash="bclions"
                    cloudFront
                    className={classes.avatarImg}
                  />
                )
              }
            />
          </Grid>
        </Grid>
        <Grid xs={2} md={2} lg={2} className={classes.item} item>
          <div className={classes.lociLogoContainer}>
            <Logo text />
          </div>
          <GermFlex />
          <Asset src={podium} className={classes.podium} />
        </Grid>
        <Grid xs={6} md={6} lg={6} className={classes.item} item>
          <Mission changeGameState={changeGameState} />
        </Grid>
      </Grid>
      <Compliance cookie={cookie} />
    </>
  );
}

IntroScene.propTypes = {
  changeGameState: PropTypes.func,
};

IntroScene.defaultProps = {
  changeGameState: {},
};

export default IntroScene;
