import {
  GSI_STATUS,
} from 'config/game';

/**
 * Provides the total percentage of correct answers from getScores
 *
 * @param {number} percentage - the players total percentage of correctly answered questions
 * @returns {number}
 */
export const getStatus = (percentage) => {
  let overAllStatus = GSI_STATUS.GERM_NEWBIE;

  if (percentage >= 86) {
    overAllStatus = GSI_STATUS.GERM_SCIENTIST;
  } else if (percentage >= 50) {
    overAllStatus = GSI_STATUS.GERM_ROOKIE;
  } else {
    overAllStatus = GSI_STATUS.GERM_NEWBIE;
  }
  return overAllStatus;
};
