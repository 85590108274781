import Recoil from 'recoil';

const policyTextState = Recoil.atom({
  key: 'policyText',
  default: undefined,
});

export {
  policyTextState,
};
