import DevConsole from 'utils/DevConsole';

/**
 * Response utility functions
 *
 * The helper functions below return response objects to be used by our
 * API and any method that needs to return a standardized message.
 */

const dev = new DevConsole('Response').mute();

const success = () => {
  dev.log('success');
  return {
    success: true,
  };
};

const error = err => {
  dev.log('error');
  return {
    success: false,
    error: err,
  };
};

const dataSuccess = body => {
  dev.log('success', body);
  return {
    success: true,  // Usually overwritten by response body, but in there in case API response ommits it
    ...body,
  };
};

export {
  success,
  error,
  dataSuccess,
};
