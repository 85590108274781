/**
 * API library
 *
 * This library does two things:
 * - It wraps the AWS Amplify API library with our own functions, so we can have control over mocking API calls;
 * - It groups all our API services as one central library so that we access the API through it.
 *
 * API rules:
 * - Never use AWS Amplify directly in your React code. This is the only file that is allowed to do so.
 */

// eslint-disable-next-line no-restricted-imports
import Amplify, { API as AmplifyAPI, Storage, Auth } from 'aws-amplify';
import { dataSuccess, error } from 'utils/responses';
import { encodeJSON64 } from 'utils/encoding';
import amplify from 'config/amplify';
import SessionStorage from 'utils/SessionStorage';
import DevConsole from 'utils/DevConsole';

export default class API {
  constructor() {
    this.mockData = null;
    this.config = amplify;
    this.storage = Storage;
    this.auth = Auth; // Need to pull out current user ID value to build s3 link.
    this.dev = new DevConsole('API');
  }

  /**
   * configure
   *
   * Configures the API, mainly required to config AWS Amplify.
   */
  configure() {
    this.dev.log('Configuring Amplify');
    Amplify.configure({
      Auth: {
        mandatorySignIn: false, // IMPORTANT or unAuth (guest) access won't work
        region: this.config.cognito.region,
        userPoolId: this.config.cognito.user_pool_id,
        identityPoolId: this.config.cognito.identity_pool_id,
        userPoolWebClientId: this.config.cognito.app_client_id,
        cookieStorage: this.config.cookieStorage,
        storage: SessionStorage.init(),
      },
      Storage: {
        region: this.config.s3.region,
        bucket: this.config.s3.bucket,
        identityPoolId: this.config.cognito.identity_pool_id,
        level: 'protected',
      },
      API: {
        endpoints: this.config.apiGateway.endpoints,
      },
    });
    this.dev.log('API Configured', this.config);
  }

  /**
   * setMock
   * Sets mock data to be used by API instead of hitting the server.
   * Clear it to resume normal behaviour.
   *
   * @param {object} mockData - Object containing mock data.
   */
  setMock(mockData = null) {
    this.mockData = mockData;
  }

  /**
   * API execution wrapper:
   *
   * @param {object} params
   * @param {string} params.action - get/list/post/put/del
   *
   * @returns {Promise} Data from API
   */
  async exec(params) {
    const {
      action,   // HTTP verb (GET/POST/CREATE/DELETE)
      service,  // Target microservice
      path,     // API path
      payload,  // Payload to be encoded & appended to path. Used mainly by GET, UPDATE, DELETE.
      init,     // Contains { body, headers } to be used by CREATE.
    } = params;

    const encodedPath = payload ? `${path}/${encodeJSON64(payload)}` : path;

    try {
      const body = this.mockData !== null
        ? this.mockData[action][encodedPath]
        : await AmplifyAPI[action](service, encodedPath, init);
      return dataSuccess(body);
    } catch (e) {
      return error(e);
    }
  }
}
