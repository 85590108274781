import React from 'react';
import { Asset } from 'components/UI';
import DevConsole from 'utils/DevConsole';
import _ from 'lodash';

const dev = new DevConsole('Polygon');
/**
 * Individual polygon rendering component
 *
 * @param {*} props
 * @param {object} props.polygons - array of polygon coordinates our jsx needs
 * @param {Array} props.collection - array of coords player has already interacted with
 * @param {string} props.hotSpotValue - the value of the current hotspot level provided by the first map
 * @param {Function} props.clickHandler - onClick method from parent - invokes showQuestion from useState
 *
 * @returns {React.Component}
 */
const Polygon = ({
  polygons,
  hotSpotValue,
  collection,
  showQuestion,
  disabledPolygonArea,
}) => {
  dev.log('Polygon, Hexagon, MyMindIsGon');
  const handleShowQuestion = () => {
    dev.log({ hotSpotValue });
    if (!disabledPolygonArea) {
      showQuestion(hotSpotValue);
    }
    dev.log({ hotSpotValue });
  };

  return _.map(polygons, (poly, keyItem) => {
    return (
      <React.Fragment key={keyItem}>
        {/* We render the polygon layer once the player has clicked an area that contains a polygon */}
        <Asset
          key={keyItem}
          src={poly.image}
          style={{
            position: 'absolute',
            opacity: collection.includes(hotSpotValue) ? 1 : 0,
            left: `${poly.x}%`,
            top: `${poly.y}%`,
            width: `${poly.w}%`,
            height: `${poly.h}%`,
          }}
          onClick={() => handleShowQuestion()}
        />
      </React.Fragment>
    );
  });
};

export default Polygon;
