import React from 'react';
import { Grid, Asset, Text, Action } from 'components/UI';
import DevConsole from 'utils/DevConsole';

import thumbPhone from 'assets/default/final_page/thumb_phone@2x.png';
import gsiBadge from 'assets/default/final_page/gsi_badge_hires.png';
import virus from 'assets/default/final_page/virus@2x.png';
import megaphone from 'assets/default/icons/megaphone_icon_only@3x.png';
import horatioThumbsUp from 'assets/default/final_page/small_horatio_thumbs_up@3x.png';
import horatioBadge from 'assets/default/final_page/small_horatio_badge@3x.png';
import useStyles from './styles';

const dev = new DevConsole('FinishScene');

/**
 * FinishScene Component
 *
 * @returns {undefined}
 */
function FinishScene() {
  dev.log('Finish');
  const classes = useStyles();

  const shareHandler = () => {
    if (navigator.share) {
      navigator
        .share({
          url: 'http://gsi.learnbyloci.com/',
          text: 'Spread facts not germs, play this game 🚫🦠🤓',
          title: 'GSI: Germ Science Investigation',
        })
        .then(() => {
          dev.log('Successfully shared');
        })
        .catch(error => {
          dev.error('Something went wrong sharing the game', error);
        });
    }
  };

  return (
    <div data-test="FinishScene" className={classes.root}>
      <div className={classes.rootOverlay} />
      <Grid
        spacing={0}
        direction="row"
        className={classes.containerRoot}
        container
      >
        <Grid xs={4} m={4} lg={4} className={classes.item} item>
          <Grid className={classes.contentFlex}>
            <Grid className={classes.textFlex}>
              <Asset src={gsiBadge} />
              <Text family="spyitalic-semi" className="title">Your next mission:</Text>
              <Text family="spyitalic-semi" className="instruction">Get your friends to help #stopthespread</Text>
            </Grid>
            <Grid className={classes.socialFlex}>
              <Grid className={classes.horatioContainer}>
                <Asset src={horatioThumbsUp} />
              </Grid>
              <Grid className={classes.socialContainer}>
                <Asset className={classes.megaphone} src={megaphone} />
                <Action text="SHARE" action={shareHandler} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4} m={4} lg={4} className={classes.item} item>
          <div className={classes.phoneWrapper}>
            <Asset src={thumbPhone} alt="Orange Hand with Phone" className={classes.phone} />
          </div>
        </Grid>
        <Grid xs={4} m={4} lg={4} className={classes.item} item>
          <Grid className={classes.contentFlex}>
            <Grid className={classes.textFlex}>
              <div className={classes.virusWrapper}>
                <Asset src={virus} className={classes.virus} />
              </div>
              <Text family="spyitalic-semi" className="title">Flex your germ knowledge on instagram and turn any area into a germ scene</Text>
            </Grid>
            <Grid className={classes.socialFlex}>
              <Grid className={classes.horatioContainer}>
                <Asset src={horatioBadge} />
              </Grid>
              <Grid className={classes.tryContainer}>
                <Action text="TRY NOW" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default FinishScene;
