/**
 * Encoding Utilities
 *
 * Base64-based encoding utilities.
 * DO NOT use these to encrypt values. Base64 is only useful to encode binary data into
 * text, so it can be passed from the client to the API as a simply string of characters.
 *
 * For questions, ask dario.daversa@gmail.com
 */

/**
 * encode64
 * Encodes a string into Base64 string.
 *
 * @param {string} payload
 *
 * @returns {string} Base64-encoded string
 */
function encode64(payload) {
  try {
    return btoa(payload);
  } catch (err) {
    return null;
  }
}

/**
 * decode64
 * Decodes a Base64 string back to a regular one.
 *
 * @param {string} payload
 *
 * @returns {string} Decode string
 */
function decode64(payload) {
  try {
    return atob(payload);
  } catch (err) {
    return null;
  }
}

/**
 * encodeJSON64
 * Encodes a JSON array into a Base64 string.
 *
 * @param {object} payload
 *
 * @returns {string} Base64-encoded string.
 */
function encodeJSON64(payload) {
  try {
    return btoa(JSON.stringify(payload));
  } catch (err) {
    return null;
  }
}

/**
 * decodeJSON64
 * Decodes a BAse64 string back into a JSON array.
 *
 * @param {string} payload
 *
 * @returns {object} Base64-decoded JSON object.
 */
function decodeJSON64(payload) {
  try {
    return JSON.parse(atob(payload));
  } catch (err) {
    return null;
  }
}

export {
  encode64,
  decode64,
  encodeJSON64,
  decodeJSON64,
};
