import Recoil from 'recoil';


// TODO: make the includes work with dynamic string values
const customURL = Recoil.atom({
  key: 'customURL',
  default: window.location.href.includes('bclions'),
});


export {
  customURL,
};
