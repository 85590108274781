import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import API from 'API';
import SceneManager from 'components/Game/SceneManager';
import SoundManager from 'components/Game/SoundManager';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import {
  gameState,
  guestState,
  levelState,
  levelProgress,
  questionState,
  nextLevel,
  translationState,
  languageState,
  selectedLanguageState,
  accessKeyState,
  allAccessKeyState,
  featuresState,
} from 'store/atoms';
import { Asset } from 'components/UI';
import gsiLogo from 'assets/default/labels/gsi_transparent@3x.png';
import { cleanPathname, cleanAccessKey } from 'utils/sanitize';
import { levelScoreFamily } from 'store/selectors';
import { localization, accessKey as accessKeyAPI, feature } from 'API/services';
import { GAME_STATE, LEVELS } from 'config/game';
import useDataApi from 'hooks/useDataApi';
import DevConsole from 'utils/DevConsole';
import { useCookies } from 'react-cookie';
import { ReactSession } from 'react-client-session';

const api = new API();
const dev = new DevConsole('GameEngine');

const APP_ID = 'GSI';

/**
 * GameEngine Component
 *
 * @returns {React.Component}
 */
const GameEngine = () => {
  dev.log('Rendered');

  /**
   * Game State Definitions
   *
   * gameState: keeps track of the game state the player is currently at
   * levelState: keeps track of what the level the player is currently at
   * levelProgress: keeps track of the progress within the levelState
   * levelScore,
   * levelScoreFamilyState: keeps track of the score that the player has for each level
   * questionState: keeps track of the question the player is at on the current levelState
   * nextLevel: keeps track of the question the player is at on the current levelState
   */
  const setCurrentGameState = useSetRecoilState(gameState);
  const [currentLevelState, setCurrentLevelState] = useRecoilState(levelState);
  const currentGuestState = useRecoilValue(guestState);
  const setCurrentLevelProgressState = useSetRecoilState(levelProgress);
  const setLevelScoreFamilyState = useSetRecoilState(levelScoreFamily(currentLevelState.key));
  const setCurrentQuestionState = useSetRecoilState(questionState);
  const setCurrentNextLevelState = useSetRecoilState(nextLevel);

  // Feature Flags
  const setFeatureFlagState = useSetRecoilState(featuresState);

  // Session Storage

  // Cookies
  const [cookie] = useCookies();

  // URLS
  const { pathname } = document.location;

  // Translations recoil state
  const setTranslations = useSetRecoilState(translationState);
  const setLanguages = useSetRecoilState(languageState);
  const setSelectedLanguage = useSetRecoilState(selectedLanguageState);
  const [initializing, setInitializing] = useState(true);

  // Access Key State
  const [currentAccessKeyState, setCurrentAccessKeyState] = useRecoilState(accessKeyState);
  const [allKeys, setAllKeys] = useRecoilState(allAccessKeyState);

  // Custom Hook for tracking player data
  const { getGuest } = useDataApi(initializing, currentAccessKeyState);
  dev.log(currentGuestState);

  // Update the language if Access Key is present
  const updateSelectedLanguage = (path, array) => {
    const findKey = array?.find(key => {
      const { accessKey } = key;
      return cleanAccessKey(accessKey) === cleanAccessKey(cleanPathname(path));
    });

    // Find matching key based on url pathname
    return findKey ? setSelectedLanguage(findKey.languageOverride) : setSelectedLanguage('en');
  };

  dev.log({ allKeys });
  /**
   * INIT: Get data from API.
   * If it fails, use defaults and move on.
   */
  useEffect(() => {
    (async () => {
      api.configure();
      if (pathname === '/') {
        setCurrentAccessKeyState('N/A');
      } else {
        setCurrentAccessKeyState(cleanPathname(pathname));
      }

      const APIGroups = await localization.group.list({
        productId: APP_ID,
      });
      const APITranslations = await localization.translation.list({
        productId: APP_ID,
      });
      const APIFeatures = await feature.list({
        productId: 'GSI',
      });

      // Access Keys
      if (!ReactSession.get('allAccessKeyState')) {
        const APIAccessKeys = await accessKeyAPI.list({
          productId: APP_ID,
          active: true,
        });
        setAllKeys(APIAccessKeys.items);
        updateSelectedLanguage(pathname, APIAccessKeys.items);
      }

      // Languages
      const APILanguages = await localization.language.list({
        productId: APP_ID,
        active: true,
      });

      setAllKeys(ReactSession.get('allAccessKeyState'));
      setLanguages(_.orderBy(APILanguages.items, ['listOrder']));
      setTranslations({
        groups: APIGroups.items || [],
        items: APITranslations.items || [],
      });

      if (APIFeatures.count) {
        setFeatureFlagState(APIFeatures.items);
        dev.log('APIFeatures', APIFeatures.items);
      }

      setInitializing(false);
    })();
  }, []);

  useEffect(() => {
    updateSelectedLanguage(pathname, allKeys);
  }, [currentAccessKeyState]);

  useEffect(() => {
    dev.log(typeof cookie.guestid);
    // If the guest closes the page and the cookie is NOT undefined.
    if (cookie.guestid !== undefined) {
      getGuest(cookie.guestid);
    }

    // If the current Guest does not have an ID we use the cookie to set it.
    return currentGuestState.guestId === undefined ? getGuest(cookie.guestid) : getGuest(currentGuestState.guestId);
  }, []);

  if (initializing) return <Asset isLoadingImg src={gsiLogo} />;

  dev.log({ api });
  /** START: Game Interactions Handlers */
  /**
   * changeGameState
   * changes the game state based on the state parameter passed
   *
   * @param {*} state - change game state based on this parameter
   */
  const changeGameState = (state) => {
    dev.log(`changeGameState: ${state}`);
    setCurrentGameState(state);
  };

  const changeNextLevelState = (level) => {
    dev.log(`level: ${level}`);
    setCurrentNextLevelState(level);
  };

  const changeLevelProgress = (progress) => {
    dev.log(`progress: ${progress}`);
    setCurrentLevelProgressState(progress);
  };

  const changeLevelScore = (score) => {
    dev.log(`question: ${score}`);
    setLevelScoreFamilyState(score);
  };

  const changeQuestionState = (question) => {
    dev.log(`question: ${question}`);
    setCurrentQuestionState(question);
  };

  const renderLevel = (level) => {
    dev.log(`renderLevel: ${level}`);
    changeGameState(GAME_STATE.IN_GAME);
    setCurrentLevelState(LEVELS[level]);
  };
  /** END: Game Interactions Handlers */

  return (
    <>
      <SoundManager />
      <SceneManager
        renderLevel={renderLevel}
        changeGameState={changeGameState}
        changeLevelProgress={changeLevelProgress}
        changeQuestionState={changeQuestionState}
        changeLevelScore={changeLevelScore}
        changeNextLevelState={changeNextLevelState}
      />
    </>
  );
};

export default GameEngine;
