import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  containerRoot: {
    position: 'relative',
    width: '100%',
    height: '100%',
    color: '#FFF',
    textShadow: '2px 1px 9px #2A97F4E6',
  },
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    margin: 0,
    padding: 0,
  },
  item: {
    position: 'relative',
    flex: 1,
  },
  language: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: '1%',
    right: '1%',
    '& .translate': {
      fontSize: '2.5vw',
      fontWeight: 800,
      color: '#77DDEC',
      textShadow: '0 0 15px #5CB4FF',
      textTransform: 'uppercase',
    },
    '& img': {
      width: '5vw',
      [theme.breakpoints.up('lg')]: {
        width: '4vw',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
    zIndex: 2,
  },
  gsiContainer: props => ({
    position: 'absolute',
    top: props.custom ? 0 : '2%',
    left: '30%',
    width: '65%',
    '@media only screen and (max-height: 430px)': {
      width: props.custom ? '55%' : '65%',
      left: '35%',
      top: '2%',
    },
    '@media only screen and (max-height: 380px)': {
      width: props.custom ? '55%' : '50%',
      left: props.custom ? '40%' : '50%',
      top: '2%',
    },
    '@media only screen and (max-height: 285px)': {
      width: props.custom ? '45%' : '50%',
      left: props.custom ? '45%' : '50%',
      top: '2%',
    },
    '@media screen and (max-width: 700px) and (max-height: 375px)': {
      width: props.custom ? '42%' : '55%',
      left: props.custom ? '45%' : '45%',
      top: '10%',
    },
  }),
  gsiBadge: {
    width: '100%',
    height: '100%', // Safari lol
  },
  horatioContainer: props => ({
    // We resize the container instead of the actual image.
    position: 'absolute',
    bottom: '0',
    left: '5vmax',
    width: '85%',
    '@media only screen and (max-height: 376px)': {
      left: '8vmax',
      width: '85%',
    },
    '@media only screen and (max-height: 380px)': {
      left: '10vmax',
      width: props.custom ? '65%' : '75%',
    },
  }),
  avatarImg: {
    // Image is always 100% of the container
    position: 'absolute',
    bottom: 0,
    left: '5%',
    width: '100%',
    zIndex: 1,
  },
  lociLogoContainer: props => ({
    position: 'absolute',
    top: props.custom ? '10%' : '15%',
    left: props.custom ? '15%' : '35%',
    width: '100%',
    zIndex: 999,
    '& img': {
      width: '100%',
      height: '100%',
    },
    '@media screen and (max-height: 360px)': {
      top: props.custom ? '5%' : '15%',
    },
    // Tablet/Laptop Height
    '@media only screen and (min-height: 768px) and (max-width: 1280px)': {
      width: '15vmax',
      top: '30%',
      left: '30%',
    },
    '@media only screen and (min-height: 1000px) and (max-width: 1366px)': {
      width: '15vmax',
      top: '30%',
      left: '25%',
    },
  }),
  covidLabel: {
    width: '15vmax',
    transform: 'translate(1vmax, 3vmax)',
  },
  podium: {
    position: 'absolute',
    bottom: 0,
    width: '15vmax',
    transform: 'translate(3vmax, 0)',
    [theme.breakpoints.up('md')]: {
      width: '18vmax',
      left: '-15%',
    },
    '@media only screen and (min-height: 768px) and (max-width: 1280px)': {
      width: '25vmax',
      left: '-20%',
    },
  },
}));

export default useStyles;
