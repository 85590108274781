import Recoil from 'recoil';

const featuresState = Recoil.atom({
  key: 'featuresState',
  default: null,
});

export {
  featuresState,
};
