import React from 'react';
import Img from 'react-cool-img';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * Wrapper for our images component
 *
 * @param {object} props
 * @param {string} props.src - the path to the image
 * @param {string} props.alt - the alt text for the image
 * @param {boolean} props.link - if the image has a link
 * @param {string} props.linkText - the url
 * @param {boolean} props.isLoadingImg - boolean for when we need an image for a placeholder when loading
 * @param {boolean} props.figure - whether or not the image contains a caption
 * @param {string} props.figureText - whatever caption we need for the image
 * @returns {React.Component}
 */
const Asset = ({ src, alt, link, linkText, isLoadingImg, ...props }) => {
  // custom css
  const classes = useStyles();

  const imageType = () => {
    if (link) {
      return (
        <a href={linkText} rel="noopener noreferrer" target="_blank">
          <Img
            src={src}
            alt={alt}
            {...props}
          />
        </a>
      );
    }

    return (
      <Img
        className={isLoadingImg ? classes.isLoading : ''}
        src={src}
        alt={alt}
        {...props}
      />
    );
  };

  return (
    <>
      {imageType()}
    </>
  );
};

Asset.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  link: PropTypes.bool,
  linkText: PropTypes.string,
  isLoadingImg: PropTypes.bool,
};

Asset.defaultProps = {
  alt: 'Default game asset',
  link: false,
  linkText: 'https://germscience.learnbyloci.com',
  isLoadingImg: false,
};

export default Asset;
