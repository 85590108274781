import { makeStyles } from '@material-ui/core/styles';

import levelCompleteBg from 'assets/default/level_complete/blue_purple_trans_bg_inner@2x.png';
import trapezoid from 'assets/default/final_page/UI_element_trapezoid@2x.png';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    opacity: 1,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  rootOverlay: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
    backgroundImage: `url(${levelCompleteBg})`,
    backgroundSize: '100% 100%',
    backgroundClip: 'padding-box',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% 0%',
    boxShadow: 'inset 0 0 3vw dodgerblue',
    opacity: 0.75,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  containerRoot: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    color: '#FFF',
    textShadow: '2px 1px 9px #2A97F4E6',
    opacity: 1,
    zIndex: 3,
  },
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    margin: 0,
    padding: 0,
  },
  item: {
    flex: 1,
  },
  contentFlex: {
    height: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25rem',
    color: '#FFFFFF',
    letterSpacing: '0.25rem',
    textShadow: '0 0 15px #00a7ff',
    textAlign: 'center',
    '& .title': {
      marginTop: '3vh',
      fontSize: '2vw',
    },
    '& .instruction': {
      fontSize: '2.5vw',
    },
    '& img': {
      width: '12vmax',
      [theme.breakpoints.up('sm')]: {
        width: '10vmax',
      },
      [theme.breakpoints.up('md')]: {
        width: '14vmax',
      },
      [theme.breakpoints.up('lg')]: {
        width: '10vmax',
      },
      [theme.breakpoints.up('lg')]: {
        width: '9vmax',
      },
    },
  },
  socialFlex: {
    position: 'relative',
  },
  virusWrapper:
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    backgroundImage: `url(${trapezoid})`,
    backgroundSize: '100% 100%',
    backgroundClip: 'padding-box',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% 0%',
    width: '14vw',
    height: '24vh',
    '& img': {
      width: '9vmax',
      [theme.breakpoints.up('md')]: {
        width: '13vmax',
      },
      [theme.breakpoints.up('lg')]: {
        width: '11vmax',
      },
      [theme.breakpoints.up('xl')]: {
        width: '9vmax',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '12vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '17vw',
      height: '22vh',
    },
    [theme.breakpoints.up('lg')]: {
      width: '15vw',
      height: '26vh',
    },
    [theme.breakpoints.up('xl')]: {
      width: '12vw',
      height: '24vh',
    },
  },
  textFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  horatioContainer: {
    position: 'relative',
    zIndex: 3,
    marginTop: '5vh',
    '& img': {
      objectFit: 'contain',
      aspectRatio: 1 / 1,
      width: '7vmax',
      marginBottom: '-0.5vh',
    },
  },
  socialContainer: {
    position: 'relative',
    zIndex: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: 'linear-gradient(to top, #0E264B 0%, #111A4EFD 0%, #5F2BA8BC 100%)',
    boxShadow: '0px 3px 6px #26ACC465',
    border: '2px solid #FFFFFFBE',
    borderRadius: '6px',
    opacity: 1,
    height: '9vh',
    width: '22vw',
    '& img': {
      width: '4vmax',
    },
    '& p': {
      fontSize: '2vw',
    },
  },
  tryContainer: {
    position: 'relative',
    zIndex: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: 'linear-gradient(to top, #0E264B 0%, #111A4EFD 0%, #5F2BA8BC 100%)',
    boxShadow: '0px 3px 6px #26ACC465',
    border: '2px solid #FFFFFFBE',
    borderRadius: '6px',
    opacity: 1,
    height: '9vh',
    width: '22vw',
    '& img': {
      width: '4vmax',
    },
    '& p': {
      fontSize: '2vw',
      width: '14vw',
    },
  },
  phoneWrapper: {
    position: 'absolute',
    bottom: '-1%',
    left: '21vw',
    '@media only screen and (-webkit-device-pixel-ratio: 2)': {
      left: '26vw',
    },
    [theme.breakpoints.up('sm')]: {
      left: '25vw',
    },
    '@media only screen and (-webkit-device-pixel-ratio: 3)': {
      left: '32vw',
    },
    [theme.breakpoints.up('md')]: {
      left: '17vw',
    },
    [theme.breakpoints.up('lg')]: {
      left: '25vw',
    },
  },
  phone: {
    objectFit: 'contain',
    aspectRatio: 1 / 1,
    height: '95vh',
    '@media only screen and (-webkit-device-pixel-ratio: 3)': {
      height: '85vh',
    },
    [theme.breakpoints.up('md')]: {
      height: '80vh',
    },
    [theme.breakpoints.up('lg')]: {
      height: '95vh',
    },
  },
  megaphone: {
    marginRight: '4%',
  },
}));

export default useStyles;
