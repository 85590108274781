import { makeStyles } from '@material-ui/core';

const rotation = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  width: '50vmin',
  height: '50vmin',
  '-webkit-animation': '$glow 1s infinite',
  '-moz-animation': '$glow 1s infinite',
  animation: '$glow 1s infinite',
};

const useStyles = makeStyles({
  isLoading: {
    ...rotation,
  },
  '@keyframes glow': {
    '0%': {
      filter: 'drop-shadow(0px 0px 15px rgba(0, 255, 48, 0.8))',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },
});

export default useStyles;
