import { makeStyles } from '@material-ui/core';

const languageFonts = (selectedLang) => {
  switch (selectedLang) {
    case 'es':
      return {
        fontSize: '2.5vmax',
      };
    case 'ps':
      return {
        fontSize: '2.5vmax',
        padding: '0.25rem',
      };
    case 'pt':
      return {
        fontSize: '1.85vmax',
        padding: '0.25rem',
      };
    case 'pa':
      return {
        fontSize: '2.5vmax',
        padding: '0.75rem',
      };
    case 'it':
      return {
        fontSize: '1.85vmax',
        padding: '0.25rem',
      };
    case 'fr':
      return {
        fontSize: '2vmax',
        padding: '0.25rem',
      };
    case 'cr':
      return {
        fontSize: '2.2vmax',
        padding: '0.25rem',
      };
    case 'de':
      return {
        fontSize: '1.85vmax',
      };
    case 'vi':
      return {
        fontSize: '1.85vmax',
        padding: '0.25rem',
      };
    case 'tl':
      return {
        fontSize: '2vmax',
        padding: '0.25rem',
      };
    default:
      return {
        fontSize: '2.5vmax',
        padding: '0.25rem',
      };
  }
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  actionButton: {
    outline: 'none',
    display: 'flex',
    maxWidth: '6vw',
    animation: '$glow 0.5s ease infinite alternate',
    filter: 'drop-shadow(0px 0px 15px rgba(0, 255, 48, 1))',
    transform: 'scale(1)',
    opacity: 1,
  },
  actionText: props => ({
    color: 'white',
    display: 'inline-block',
    textShadow: '0 0 15px #00a7ff',
    ...languageFonts(props.currentLang),
  }),
  '@keyframes glow': {
    '0%': {
      filter: 'drop-shadow(0px 0px 15px rgba(0, 255, 48, 0.8))',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },
});

export default useStyles;
