import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import { create } from 'jss';
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import theme from 'assets/style/theme';
import useStyles from './styles';

/**
 * Iframe Component for keeping a fixed width when not displayed on phone screens
 *
 * @param {*} props
 * @param {string} props.title
 * @param {string} props.src
 * @param {string} props.width
 * @param {string} props.height
 *
 * @returns {React.component}
 */
const Iframe = ({
  title,
  // customAssetPath,
  children,
  ...props
}) => {
  const classes = useStyles();

  // We copy the head tag from the parent element and inject as the initialContent prop.
  const initialContent = `<!DOCTYPE html><html><head>${document.head.innerHTML}</head><body><div></div></body></html>`;

  return (
    <Frame
      className={classes.root}
      title={title}
      initialContent={initialContent}
      {...props}
    >
      <FrameContextConsumer>
        {({ document }) => {
          const jss = create({
            plugins: [...jssPreset().plugins],
            insertionPoint: document.head,
          });
          return (
            <StylesProvider jss={jss}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
              </ThemeProvider>
            </StylesProvider>
          );
        }}
      </FrameContextConsumer>
    </Frame>
  );
};

Iframe.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Iframe;
