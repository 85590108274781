import React from 'react';
import { Asset, Text } from 'components/UI';
import germ from 'assets/default/images/coronavirus_germ_multi@3x.png';
import dangerArrow from 'assets/default/icons/danger-arrows@3x.png';
import dangerLabel from 'assets/default/labels/DANGER-LABEL@3x.png';
import covidLabel from 'assets/default/labels/covid19-coronavirus@3x.png';
import useStyles from './styles';

/**
 * This is a container component that groups game assets together to form a unit
 *
 * @returns {React.Component}
 */
const GermFlex = () => {
  const { pathname } = document.location;
  const props = {
    custom: pathname.includes('bclions'),
  };
  const classes = useStyles(props);

  return (
    <section className={classes.germWrapper}>
      <div className={classes.germFlex}>
        <div className={classes.dangerFlex}>
          {pathname.includes('bclions') ? null : (
            <>
              <Asset
                src={dangerArrow}
              />
              <Asset
                src={dangerLabel}
              />
            </>
          )}
        </div>
        <div>
          <Asset
            src={covidLabel}
            className={classes.covidLabel}
          />
          <Asset
            src={germ}
            className={classes.germBg}
          />
        </div>
      </div>
      {pathname.includes('bclions') ? <Text className={classes.leoName} color="secondary">LEO</Text> : null}
    </section>
  );
};

export default GermFlex;
