import React from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import useLocalization from 'hooks/useLocalization';
import { Action, Text } from 'components/UI';
import {
  gameState,
  levelState,
  questionState,
} from 'store/atoms';
import { GAME_STATE, LEVEL_PROGRESS } from 'config/game';
import instructions from 'config/instructions';
import questions from 'config/questions';
import DevConsole from 'utils/DevConsole';
import iconHand from 'assets/default/icons/hand-icon.png';
import useStyles from './styles';

const dev = new DevConsole('Instruction');

const InstructionScene = ({ changeLevelProgress }) => {
  const classes = useStyles();
  const currentGameState = useRecoilValue(gameState);
  const currentLevelState = useRecoilValue(levelState);
  const currentQuestionState = useRecoilValue(questionState);
  const t = useLocalization('Instructions');
  const tGlobal = useLocalization('Global');
  const tNumbers = useLocalization('Numbers');

  dev.log(`currentLevelState: ${JSON.stringify(currentLevelState)}`);

  const nextButtonHandler = () => {
    changeLevelProgress(LEVEL_PROGRESS.QUESTION);
  };

  return (
    <div
      data-test="InstructionScene"
      className={classes.root}
      style={{
        backgroundImage: `url(${questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].background})`,
        backgroundSize: `${Object.prototype.hasOwnProperty.call(questions[currentLevelState.key][`QUESTION_${currentQuestionState}`], 'HOT_SPOTS') ? '100% 100%' : 'cover'}`,
      }}
    >
      <div className={classes.shadowFrame} />
      {currentGameState === GAME_STATE.IN_GAME && (
        <div className={classes.wrapper}>
          <div className={classes.instruction}>
            <img src={iconHand} alt="icon-hand" className={classes.icon} />
            <Text className={classes.levelName} family="spy-regular">
              {`${tGlobal('LEVEL') || 'LEVEL'} ${tNumbers(currentLevelState.value.toString()) || currentLevelState.value}` || currentLevelState.text}
            </Text>
            <span>
              {t(currentLevelState.text) || instructions[currentLevelState.key].text}
            </span>
          </div>
          <div className={classes.nextButton}>
            <Action text={tGlobal('go') || 'GO'} action={nextButtonHandler} />
          </div>
        </div>
      )}
    </div>
  );
};

InstructionScene.propTypes = {
  changeLevelProgress: PropTypes.func,
};

InstructionScene.defaultProps = {
  changeLevelProgress: {},
};

export default InstructionScene;
