export default {
  get: {
    '/featureflags/TestFeature': {
      id: 'e07a9fc8-8c3c-4620-b7e1-a6fdad0058d3',
      name: 'TestFeature',
      description: 'Test feature',
      enabled: true,
      ratio: 1,
      meta: {},
    },
    '/featureflags/DisabledFeature': {
      id: '236d1727-933e-4d02-bf97-2a8ac14edcc3',
      name: 'DisabledFeature',
      description: 'Disabled feature',
      enabled: false,
      ratio: 1,
      meta: {},
    },
    '/featureflags': [{
      id: 'e07a9fc8-8c3c-4620-b7e1-a6fdad0058d3',
      name: 'TestFeature',
      description: 'Test feature',
      enabled: true,
      ratio: 1,
      meta: {},
    },
    {
      id: '236d1727-933e-4d02-bf97-2a8ac14edcc3',
      name: 'DisabledFeature',
      description: 'Disabled feature',
      enabled: true,
      ratio: 1,
      meta: {},
    }],
  },
};
