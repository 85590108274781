import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import useLocalization from 'hooks/useLocalization';
import { selectedLanguageState } from 'store/atoms';
import { detectLang } from 'utils/detectLang';
import clsx from 'clsx';
import _ from 'lodash';
import { Grid, Asset, Text } from 'components/UI';
import DevConsole from 'utils/DevConsole';
import useStyles from './styles';

const dev = new DevConsole('StatusBar');

/**
 * Status Bar component for TotalScene
 *
 * @param {object} props
 * @param {object} props.status - GSI_STATUS, object that contains the current game status
 * @param {object} props.rank - the current rank assigned to the player based on their score
 * @returns {React.Component}
 */
const StatusBar = ({ status, rank }) => {
  const selectedLang = useRecoilValue(selectedLanguageState);
  const t = useLocalization('Congratulations');

  // CSS Props
  const props = {
    activeBadgeBg: `url(${rank.badgeBg})`,
    language: detectLang(selectedLang, 'tl', 'es', 'pt', 'it', 'de', 'fr', 'cr', 'vi', 'ko'),
    currentLang: selectedLang,
  };
  const classes = useStyles(props);

  dev.log('SELECTED LANGUAGE', selectedLang);
  return (
    <Grid className={classes.statusContainer}>
      {
        _.map(status, (value, key) => {
          dev.log(value);
          return (
            <React.Fragment key={key}>
              <Grid xs={12} m={12} lg={12} item>
                {value.key === rank.key ? (
                  <section className={clsx(classes.active, classes[value.activeClassName])}>
                    <div className={classes.statusBadge}>
                      <Asset src={value.activeLogo} alt={value.text} />
                    </div>
                    <div className={classes[value.activeBadgeClassname]}>
                      <div className={classes.rankContent}>
                        <Text color="primary" className="rank-earned">
                          {t('rank') || 'RANK EARNED'}
                        </Text>
                        <Text
                          color="primary"
                          className="rank-name"
                        >
                          {`${t(value.translationKeyword) || value.text}`}
                        </Text>
                      </div>
                    </div>
                  </section>
                ) : (
                  <div className={classes.inactiveContainer}>
                    <div className={classes.inactive}>
                      <Asset src={value.inActiveLogo} alt={value.text} />
                    </div>
                    <Text color="primary" className={classes.statusText}>
                      {`${t(value.translationKeyword) || value.text}`}
                    </Text>
                  </div>
                )}
              </Grid>
            </React.Fragment>
          );
        })
      }
    </Grid>
  );
};

StatusBar.propTypes = {
  status: PropTypes.object.isRequired,
  rank: PropTypes.object.isRequired,
};

export default StatusBar;
