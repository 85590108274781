import React from 'react';
import PropTypes from 'prop-types';
import { Modal as MUIModal } from '@material-ui/core';
import DevConsole from 'utils/DevConsole';
import useStyles from './styles';

const dev = new DevConsole('Modal').mute();

/**
 * Modal wrapper for material-ui's modal
 *
 * @param {object} props
 * @param {boolean} props.open - state (bool) for showing/hiding
 * @param {React.Component} props.children - any other components the modal needs to render
 * @returns {React.Component}
 */
const Modal = ({ open, children }, ...props) => {
  dev.log('Modal Rendered!');
  const classes = useStyles();
  return (
    <MUIModal
      className={classes.root}
      open={open}
      disablePortal
      {...props}
    >
      <div className={classes.root}>
        {children}
      </div>
    </MUIModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Modal;
