import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  parentFlex: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    height: '100%',
    margin: 'auto',
    background: 'linear-gradient(180deg, rgba(95, 43, 168, 0.95), rgba(14, 38, 75, 0.95))',
    boxShadow: '0 0 15px #00a7ff',
    '@media screen and (orientation: landscape)': {
      flexDirection: 'row',
    },
  },
  formFlex: {
    width: '50%',
    height: '60%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 2,
    order: 2,
    [theme.breakpoints.up('sm')]: {
      height: '75%',
    },
    '@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)': {
      height: '75%',
    },
    '@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)': {
      height: '75%',
    },
    [theme.breakpoints.up('md')]: {
      height: '50%',
    },
    '@media screen and (orientation: landscape)': {
      borderLeft: '3px #FFFFFF5F solid',
    },
    '@media screen and (orientation: portrait)': {
      width: '100%',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  close: {
    position: 'absolute',
    top: '2vw',
    right: '2vw',
    fontSize: '5vw',
    '@media screen and (orientation: portrait)': {
      fontSize: '5vh',
    },
  },
  title: {
    color: '#FFFFFF',
    fontStyle: 'italic',
    fontSize: '3vw',
    marginLeft: '12%',
    '@media screen and (orientation: portrait)': {
      fontSize: '5vh',
    },
  },
  select: {
    '& label': {
      marginLeft: '12%',
    },
  },
  textField: {
    position: 'relative',
    zIndex: 4,
    color: '#FFFFFF',
    width: '75%',
    margin: '2% auto',
    borderBottom: '2px solid #FFFFFF',
    '& label': {
      color: '#FFFFFF',
    },
    '& textarea': {
      color: '#FFFFFF',
    },
    '& input': {
      color: '#FFFFFF',
    },
  },
  textArea: {
    color: '#727272',
    width: '75%',
    margin: '2% auto',
    border: '2px solid #FFFFFF',
    borderRadius: '3px',
    resize: 'none',
    height: '35% !important',
    [theme.breakpoints.up('sm')]: {
      height: '50% !important',
    },
    [theme.breakpoints.up('md')]: {
      height: '100% !important',
    },
  },
  formBtn: {
    fontFamily: 'Open Sans',
    boxShadow: '0 0 15px #00a7ff',
    fontSize: '0.75rem',
    letterSpacing: '0.05rem',
    color: '#FFF',
    border: '2px solid #FFFFFF',
    borderRadius: '5px',
    width: '40%',
    height: '12%',
    margin: '2% 30%',

    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      borderRadius: '7px',
      height: '14%',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
      width: '35%',
      height: '25%',
    },
    '@media screen and (orientation: portrait)': {
      fontSize: '2vh',
    },
  },
  question: {
    position: 'absolute',
    bottom: 2,
    left: 4,
    width: '8%',
    zIndex: 9999,
    [theme.breakpoints.up('lg')]: {
      width: '5%',
    },
  },
}));

export default useStyles;
