import React, { useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import { gameState, levelProgress, formModal, imageState, gdprOpenState } from 'store/atoms';
import { v4 as uuid } from 'uuid';
import report from 'utils/html2canvas';
import { GAME_STATE, LEVEL_PROGRESS } from 'config/game';
import {
  IntroScene,
  FinishScene,
  InstructionScene,
  QuestionScene,
  CongratulationScene,
  TotalScene,
} from 'components/Game/Scenes';
import { Asset, Modal, Form } from 'components/UI';
import ga from 'utils/ga';
import question from 'assets/default/icons/question-icon-hex@3x.png';
import useStyles from './styles';


/**
 * Scene Manager
 *
 * @param {*} renderLevel - function controlled by Game Engine
 * @returns {undefined}
 */
function SceneManager({
  renderLevel,
  changeGameState,
  changeLevelProgress,
  changeQuestionState,
  changeLevelScore,
  changeNextLevelState,
}) {
  const currentGameState = useRecoilValue(gameState);
  const currentLevelProgress = useRecoilValue(levelProgress);
  const [formModalOpen, setFormModalOpen] = useRecoilState(formModal);
  const setGdprOpen = useSetRecoilState(gdprOpenState);

  const setImage = useSetRecoilState(imageState);

  const classes = useStyles();

  const handleOpen = () => {
    setTimeout(() => {
      setFormModalOpen(true);
    }, 1000);
    setGdprOpen(false);
    report(setImage, uuid());
  };

  const isIconBottomVisible = () => {
    return !formModalOpen ? (
      <>
        <Asset
          src={question}
          className={classes.questionBottom}
          role="button"
          tabIndex="0"
          onClick={handleOpen}
        />
      </>
    )
      : null;
  };

  const isIconTopVisible = () => {
    return !formModalOpen ? (
      <div>
        <Asset
          src={question}
          className={classes.questionTop}
          role="button"
          tabIndex="0"
          onClick={handleOpen}
        />
      </div>
    )
      : null;
  };

  useEffect(() => {
    ga.init();
    ga.pageView(window.location.pathname + window.location.search);
    ga.event('SceneManager', 'Progress update');
    ga.set('currentGameState', currentGameState);
    ga.set('currentLevelProgress', currentLevelProgress);
  }, [currentGameState, currentLevelProgress]);

  return (
    <>
      <Modal open={formModalOpen}>
        <Form />
      </Modal>
      {currentGameState === GAME_STATE.INTRO ? isIconBottomVisible() : isIconTopVisible()}
      {currentGameState === GAME_STATE.INTRO && <IntroScene changeGameState={changeGameState} />}
      {currentGameState === GAME_STATE.IN_GAME && currentLevelProgress === LEVEL_PROGRESS.INSTRUCTION
        && <InstructionScene changeLevelProgress={changeLevelProgress} />}
      {currentGameState === GAME_STATE.IN_GAME && currentLevelProgress === LEVEL_PROGRESS.QUESTION
        && (
          <QuestionScene
            changeQuestionState={changeQuestionState}
            changeLevelProgress={changeLevelProgress}
            changeLevelScore={changeLevelScore}
          />
        )}
      {currentGameState === GAME_STATE.IN_GAME && currentLevelProgress === LEVEL_PROGRESS.CONGRATULATION
        && (
          <CongratulationScene
            changeGameState={changeGameState}
            changeLevelProgress={changeLevelProgress}
            changeNextLevelState={changeNextLevelState}
            changeQuestionState={changeQuestionState}
            renderLevel={renderLevel}
          />
        )}
      {currentGameState === GAME_STATE.IN_GAME && currentLevelProgress === LEVEL_PROGRESS.TOTAL
        && (
          <TotalScene
            changeGameState={changeGameState}
            changeLevelProgress={changeLevelProgress}
          />
        )}
      {currentGameState === GAME_STATE.FINISH && <FinishScene />}
    </>
  );
}

SceneManager.propTypes = {
  renderLevel: PropTypes.func,
  changeGameState: PropTypes.func,
  changeLevelProgress: PropTypes.func,
  changeQuestionState: PropTypes.func,
  changeLevelScore: PropTypes.func,
  changeNextLevelState: PropTypes.func,
};

SceneManager.defaultProps = {
  renderLevel: {},
  changeGameState: {},
  changeLevelProgress: {},
  changeQuestionState: {},
  changeLevelScore: {},
  changeNextLevelState: {},
};

export default SceneManager;
