/**
 * Value-sanitizing functions
 *
 */

/**
 * cleanTranslation
 *
 * Used to sanitize translation (group name, keyword) values.
 *
 * @param {string} str
 * @returns {string}
 */
function cleanTranslation(str) {
  return typeof str === 'string' ? str.trim().toLowerCase() : '';
}

/**
 * cleanPathname
 *
 * Used to sanitize the pathname to remove the / in front of the path
 *
 * @param {string} str
 * @returns {string}
 */
function cleanPathname(str) {
  if (str === '/') {
    return 'N/A';
  }

  const splitStr = typeof str === 'string' ? str.trim().split('/') : '';
  return splitStr !== '' ? splitStr[1] : 'N/A';
}

/**
 * cleanHttpLocation
 *
 * Used to sanitize the href to remove the separator in end of the url
 *
 * @param {string} str
 * @param {string} separator
 * @returns {string}
 */
function cleanHttpLocation(str, separator) {
  const splitStr = typeof str === 'string' ? str.trim().split(separator) : '';
  return splitStr[0];
}

/**
 * cleanAccessKey
 *
 * Used to sanitize accessKey values.
 *
 * @param {string} str
 * @returns {string}
 */
function cleanAccessKey(str) {
  return typeof str === 'string' ? str.trim().toLowerCase() : '';
}

export {
  cleanTranslation,
  cleanPathname,
  cleanHttpLocation,
  cleanAccessKey,
};
