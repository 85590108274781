import React from 'react';
import useLocalization from 'hooks/useLocalization';
import { Grid, Text, Action } from 'components/UI';
import { useRecoilValue, useRecoilState } from 'recoil';
import { langModal, languageState, selectedLanguageState } from 'store/atoms';
import _ from 'lodash';
import clsx from 'clsx';
import DevConsole from 'utils/DevConsole';

import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const dev = new DevConsole('Language');
/**
 * For now this is specific to opening and closing the Language Selection Modal
 * it is just an icon that accepts a function for it's onClick event handler
 *
 * @returns {React.Component}
 */
const Language = () => {
  const classes = useStyles();
  const languages = useRecoilValue(languageState);
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(selectedLanguageState);
  const [langModalOpen, setLangModalOpen] = useRecoilState(langModal);
  const t = useLocalization('Translate');

  dev.log(langModalOpen);

  const handleClose = () => {
    setLangModalOpen(false);
  };

  const handlePickLanguage = (code) => () => {
    setSelectedLanguage(code);
    setLangModalOpen(false);
  };

  return (
    <Grid
      className={classes.root}
      container
      alignItems="flex-start"
    >
      <Text family="spyitalic-semi" color="primary" className={classes.title}>{t('Choose your language') || 'CHOOSE YOUR LANGUAGE'}</Text>
      <div className={classes.outer}>
        <div className={classes.wrapper}>
          <div className={classes.overFlow}>
            <div className={classes.languages}>
              {
                _.map(languages, (value) => {
                  const selectedClass = selectedLanguage === value.code ? 'selected' : '';
                  return (
                    <div key={value.code} className={classes.options}>
                      <div className={clsx(classes.languageContainer, classes[selectedClass])}>
                        <Action text={t(value.keyword) || value.keyword} family="chollasansreg" action={handlePickLanguage(value.code)} />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
      <CloseIcon color="primary" className={classes.close} role="button" tabIndex="0" onClick={handleClose} />
    </Grid>
  );
};

export default Language;
