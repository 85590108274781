import html2canvas from 'html2canvas';
import API from 'API';
import DevConsole from './DevConsole';

const dev = new DevConsole('report');
const api = new API();

// Work around for buffer undefined in the browser
const base64ToBinaryImage = (src) => {
  dev.log({ src });
  const binary = atob(src.split(',')[1]);
  const result = [];
  dev.log({ result });
  for (let i = 0; i < binary.length; i += 1) {
    result.push(binary.charCodeAt(i));
  }

  return new Blob([new Uint8Array(result)], { type: 'image/png' });
};

/**
 * A screen shot is generated here using html2canvas. The contents of the region (The DOM) are downloaded and rendered onto a canvas.
 * We call the put method here from api.storage to upload the screenshot to our
 * s3 bucket based on where the user is when they need to leave feedback.
 *
 * @param {Function} set
 * @param {string} key
 */
const report = (set, key) => {
  const region = document.querySelector('body');
  html2canvas(region).then(async canvas => {
    const img = canvas.toDataURL('image/png');

    const binary = base64ToBinaryImage(img);

    dev.log({ binary });

    try {
      const image = await api.storage.put(`feedback/${key}`, binary, {
        contentType: 'image/png',
      });
      set(image);
    } catch (error) {
      dev.log({ error });
    }
  });
};

export default report;
