import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import useLocalization from 'hooks/useLocalization';
import _ from 'lodash';
import { Action, Asset, CustomAsset, Grid, Text, Feature } from 'components/UI';
import {
  gameState,
  levelState,
  levelProgress,
  levelScore,
  questionState,
  selectedLanguageState,
  customURL,
} from 'store/atoms';
import { GAME_STATE, LEVELS, LEVEL_PROGRESS, LEVEL_TYPES } from 'config/game';
import questions from 'config/questions';
import { detectLang } from 'utils/detectLang';
import DevConsole from 'utils/DevConsole';

import horatioThumbsUp from 'assets/default/level_complete/horatio_full_thumbsup_side-min.png';
import useStyles from './styles';

const dev = new DevConsole('CongratulationScene');

const CongratulationScene = ({ changeGameState, changeLevelProgress, changeNextLevelState, changeQuestionState, renderLevel }) => {
  const currentGameState = useRecoilValue(gameState);
  const [currentLevelState, setCurrentLevelState] = useRecoilState(levelState);
  const currentQuestionState = useRecoilValue(questionState);
  const currentLevelProgress = useRecoilValue(levelProgress);
  const currentLevelScore = useRecoilValue(levelScore);
  const selectedLang = useRecoilValue(selectedLanguageState);
  const isCustomURL = useRecoilValue(customURL);
  const maxLevels = Object.keys(LEVELS).length;
  const maxQuestions = Object.keys(questions[currentLevelState.key]).length;
  const t = useLocalization('Level complete');
  const tGlobal = useLocalization('Global');
  const tNumbers = useLocalization('Numbers');

  const props = {
    bgFromQuestion: `url(${questions[currentLevelState.key][`QUESTION_${currentQuestionState}`].background})`,
    language: detectLang(selectedLang, 'cr'),
    custom: isCustomURL,
  };
  dev.log(props);
  const classes = useStyles(props);

  let maxQuestionsGHS = 0;
  if (currentLevelState.type === LEVEL_TYPES.GERM_HOT_SPOTS) {
    _.map(questions[currentLevelState.key], (value) => {
      const totalHotSpotQuestion = Object.keys(value.HOT_SPOTS).length;
      dev.log(`totalHotSpotQuestion: ${totalHotSpotQuestion}`);
      maxQuestionsGHS += totalHotSpotQuestion;
    });
  }

  const nextButtonHandler = () => {
    if (currentLevelState.value <= maxLevels && currentLevelState.key !== LEVELS[`LEVEL_${maxLevels}`].key) {
      changeGameState(GAME_STATE.IN_GAME);
      changeNextLevelState(currentLevelState.value + 1);
      renderLevel(`LEVEL_${currentLevelState.value + 1}`);
      changeLevelProgress(LEVEL_PROGRESS.INSTRUCTION);
      changeQuestionState(1);
    } else {
      changeLevelProgress(LEVEL_PROGRESS.TOTAL);
      changeQuestionState(1);
      changeNextLevelState(1);
      setCurrentLevelState(LEVELS.LEVEL_1);
    }
  };

  const nextText = currentLevelState.value < maxLevels ? `${tGlobal('LEVEL') || 'LEVEL'} ${tNumbers((currentLevelState.value + 1).toString()) || currentLevelState.value + 1}` : t('FINISH') || 'FINISH';

  return (
    <div data-test="CongratulationScene" className={classes.root}>
      <div className={classes.rootOverlay} />
      {currentGameState === GAME_STATE.IN_GAME && currentLevelProgress === LEVEL_PROGRESS.CONGRATULATION && (
        <>
          <Grid spacing={0} className={classes.containerRoot} container>
            <Grid xs={5} m={5} lg={5} item>
              <div className={classes.horatioWrapper}>
                <Feature
                  keyword="AssetSwap"
                  fallback={
                    (
                      <CustomAsset
                        defaultSrc={horatioThumbsUp}
                        customSrc="leocheersx3.png"
                        slash="bclions"
                        alt="Horatio"
                        cloudFront
                        className={classes.horatio}

                      />
                    )
                  }
                  component={
                    (
                      <CustomAsset
                        defaultSrc={horatioThumbsUp}
                        customSrc="BC_Lions_transparent_cheers_full_body_450x450.gif"
                        slash="bclions"
                        alt="Horatio"
                        cloudFront
                        className={classes.horatio}

                      />
                    )
                  }
                />

              </div>
            </Grid>
            <Grid xs={7} m={7} lg={7} item>
              <div className={classes.radarWrapper}>
                <Asset src={`${currentLevelState.radar}`} alt="Radar" className={classes.radar} />
              </div>

              <div className={classes.levelWrapper}>
                <Grid
                  spacing={0}
                  direction="column"
                  className={classes.container}
                  container
                >
                  <Grid xs={12} m={12} lg={12} className={classes.item} item>
                    <div className={classes.levelComplete}>
                      <Text className={classes.levelName} family="spyitalic-semi">
                        {`${tGlobal('LEVEL') || 'LEVEL'} ${tNumbers(currentLevelState.value.toString()) || currentLevelState.value}` || currentLevelState.text}
                      </Text>
                      <Text className={classes.completeText} family="spyitalic-semi">
                        {t('COMPLETE') || 'COMPLETE'}
                      </Text>
                    </div>
                    <div className={classes.scorePanel}>
                      <Text className={classes.scorePanelLabel} family="spyitalic-semi">
                        {t('score') || 'YOUR SCORE'}
                      </Text>

                      <Text style={{ fontFamily: 'spyitalic-semi' }} className={classes.scorePanelScore}>
                        {currentLevelState.type !== LEVEL_TYPES.GERM_HOT_SPOTS && (
                          <>
                            <span>{`${tNumbers(currentLevelScore[currentLevelState.key].toString()) || currentLevelScore[currentLevelState.key]}`}</span>
                            <span>/</span>
                            <span>{`${tNumbers(maxQuestions.toString()) || maxQuestions}`}</span>
                          </>
                        )}
                        {currentLevelState.type === LEVEL_TYPES.GERM_HOT_SPOTS && (
                          <>
                            <span>{`${tNumbers(currentLevelScore[currentLevelState.key].toString()) || currentLevelScore[currentLevelState.key]}`}</span>
                            <span>/</span>
                            <span>{`${tNumbers(maxQuestionsGHS.toString()) || maxQuestionsGHS}`}</span>
                          </>
                        )}
                      </Text>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>

          <div className={classes.nextButton}>
            <Action action={nextButtonHandler} text={nextText} />
          </div>
        </>
      )}
    </div>
  );
};

CongratulationScene.propTypes = {
  changeGameState: PropTypes.func,
  changeLevelProgress: PropTypes.func,
  changeNextLevelState: PropTypes.func,
  changeQuestionState: PropTypes.func,
  renderLevel: PropTypes.func,
};

CongratulationScene.defaultProps = {
  changeGameState: {},
  changeLevelProgress: {},
  changeNextLevelState: {},
  changeQuestionState: {},
  renderLevel: {},
};

export default CongratulationScene;
