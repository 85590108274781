/* eslint-disable no-underscore-dangle */
import React from 'react';
import Recoil from 'recoil';
import { useCookies } from 'react-cookie';
import {
  policyTextState,
  gdprOpenState,
  shakeAnimationState,
} from 'store/atoms';
import Slide from '@material-ui/core/Slide';
import {
  Text,
} from 'components/UI';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useMediaQuery } from '@material-ui/core';
import DevConsole from 'utils/DevConsole';
import PropTypes from 'prop-types';
import PrivacyPolicy from './PrivacyPolicy';
import privacyPolicyMd from './privacy-policy.md';
import useStyles from './styles';

const dev = new DevConsole('Compliance');

/**
 * This our Compliance component in response to GDPR and California cookie laws.
 * For GSI this is a modal that directs users to our privacy policy where they can
 * accept the policy with a checkbox or or a link that simply shows the policy
 * in full.
 *
 * @returns {React.Component}
 */
const Compliance = () => {
  dev.log('Rendered');
  dev.log(PropTypes);
  const phones = useMediaQuery('(min-width: 960px)');
  const tablets = useMediaQuery('(min-width: 1200px)');

  // local state
  const [openPolicy, setOpenPolicy] = React.useState(false);

  // global
  const [policyContent, setPolicyContent] = Recoil.useRecoilState(policyTextState);
  const [isOpen, setOpen] = Recoil.useRecoilState(gdprOpenState);
  const [cookie] = useCookies();
  const [shake, setShake] = Recoil.useRecoilState(shakeAnimationState);

  // Custom CSS
  const props = {
    isOpen,
    phones,
    tablets,
  };
  const classes = useStyles(props);

  // If we have a cookie we keep the compliance box closed
  React.useEffect(() => {
    if (cookie['cookie-accepted']) {
      setOpen(false);
    }
    setShake(true);

    setTimeout(() => {
      setShake(false);
    }, [3000]);

    return () => clearTimeout();
  }, []);

  // load markdown content
  React.useEffect(() => {
    (async () => {
      const policyText = await fetch(privacyPolicyMd);
      const policyResult = await policyText.text();
      setPolicyContent(policyResult);
    })();
  }, []);

  dev.group('Cookie and GA state');
  dev.log('COMPLIANCE PROPS', props);
  dev.log(cookie);
  dev.log({ shake });
  dev.ungroup();

  return isOpen ? (
    <>
      <section className={shake ? classes.shakeAnimation : classes.consentContainer}>
        <Slide direction="up" in={isOpen}>
          <section className={classes.textContainer}>
            <CloseIcon
              style={{
                position: 'absolute',
                right: '.25rem',
                top: '0.25rem',
                color: '#FFFFFF',
                width: '2rem',
                height: '2rem',
              }}
              onClick={() => setOpen(false)}
            />
            <Text>By playing this game you are agreeing to our</Text>
            <Link
              href="#"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
              onClick={() => setOpenPolicy(!openPolicy)}
            >
              privacy policy
            </Link>
          </section>
        </Slide>
      </section>
      <PrivacyPolicy
        open={openPolicy}
        setOpen={setOpenPolicy}
        policyContent={policyContent}
      />
    </>

  ) : null;
};

Compliance.propTypes = {
};

export default Compliance;
