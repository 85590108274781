import React from 'react';
import PropTypes from 'prop-types';
import { Container as MUIContainer } from '@material-ui/core';

/**
 * Container wrapper for MUI's Container component
 *
 * @param {object} props
 * @param {React.ReactNode} props.component - HTML or ReactComponent
 * @param {boolean} props.disableGutters - controls left and right padding
 * @param {boolean} props.fixed - determines if the width values are fixed or the container is fluid
 * @param {string} props.maxWidth - sets the maximum size of the container
 * @param {React.Component} props.children
 * @returns {React.Component}
 */
const Container = ({
  component,
  disableGutters,
  fixed,
  maxWidth,
  children,
}) => {
  return (
    <MUIContainer
      component={component}
      disableGutters={disableGutters}
      fixed={fixed}
      maxWidth={maxWidth}
    >
      {children}
    </MUIContainer>
  );
};

Container.propTypes = {
  component: PropTypes.string || PropTypes.element,
  disableGutters: PropTypes.bool,
  fixed: PropTypes.bool,
  maxWidth: PropTypes.string,
};

Container.defaultProps = {
  component: 'section',
  disableGutters: false,
  fixed: false,
  maxWidth: 'lg',
};

export default Container;
