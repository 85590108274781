/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button as MUIButton,
} from '@material-ui/core';

const Button = ({ variant = 'outlined', color = 'primary', ...props }) => (
  <MUIButton
    data-test="button"
    variant={variant}
    color={color}
    {...props}
  />
);

export default Button;
