import React from 'react';
import useSound from 'use-sound';
import introSound from 'assets/sounds/game-intro.mp3';
import congratsSound from 'assets/sounds/congrats-final.mp3';
import successSound from 'assets/sounds/success-final.mp3';
import { useRecoilValue } from 'recoil';
import { gameState, levelProgress } from 'store/atoms';
import { GAME_STATE, LEVEL_PROGRESS } from 'config/game';

/**
 * This component contains functions for playing sounds under a given condition
 * Primarily this acts as our sound layer for the entire game
 * but since the library is a hook we can use it anywhere we need it
 * TODO: consolidate all the sounds and put them to use with the useSound hook needed for the game here
 * We're going to need the game state here to keep track of what sounds to play and when.
 *
 * @returns {React.Component}
 */

// handle "DOMException: play() failed because the user didn't interact with the document first."
// see: https://stackoverflow.com/questions/49930680
let canPlayAudio = false;
function handleCanPlay() {
  canPlayAudio = true;
  window.removeEventListener('click', handleCanPlay);
}

window.addEventListener('click', handleCanPlay);

const SoundManager = () => {
  const currentGameState = useRecoilValue(gameState);
  const currentLevelProgress = useRecoilValue(levelProgress);
  const [gameIntro] = useSound(introSound, { volume: 0.25 }); // The first dependency of the array can be named to match whatever sound needs to be played
  const [gameComplete] = useSound(congratsSound);
  const [levelComplete] = useSound(successSound);
  const { INTRO } = GAME_STATE;
  const { CONGRATULATION, TOTAL } = LEVEL_PROGRESS;

  const play = (sound) => {
    if (canPlayAudio) {
      sound();
    }
  };

  return (
    <>
      {currentGameState === INTRO ? play(gameIntro) : null}
      {currentLevelProgress === CONGRATULATION ? play(levelComplete) : null}
      {currentLevelProgress === TOTAL ? play(gameComplete) : null}
    </>
  );
};

export default SoundManager;
