export default {
  get: {
    '/localization/group': [
      // TODO: Grab an image of the db content
    ],
    '/localization/translation': [
      // TODO: Grab an image of the db content
    ],
  },
};
