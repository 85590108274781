import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  scoreContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: '65%',
    justifyContent: 'center',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '85%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '85%',
    },
    '@media only screen and (max-height: 380px)': {
      margin: '-2% auto',
      width: '100%',
    },
  },
  scoreLevelContainer: {
    border: 'solid 0.2vw #ffffff82',
    borderRadius: '8px',
    boxShadow: 'inset 15px 2000px 2000px rgba(0, 45, 65, 0.6)',
    '& p': {
      textShadow: '2px 4px 9px #2A97F4E6',
    },
  },
  scoreTallyContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  scoreFlex: props => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '4%',
    paddingBottom: '2%',
    paddingRight: '2%',
    paddingLeft: '2%',
    height: '8vh',
    '& p': {
      fontSize: props.language ? '1.5vw' : '2vw',
    },
    '& .score-value': {
      fontSize: props.language ? '1.5vw' : '3vw',
    },
  }),
  finalScoreContainer: props => ({
    display: 'flex',
    border: 'solid 0.2vw #ffffff82',
    borderRadius: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: props.scoreBg,
    marginTop: '0.25rem',
    padding: '2%',
    textShadow: '2px 4px 9px #2A97F4E6',
    height: '17vh',
    '& p': {
      fontSize: '2vw',
    },
    '& .final-value': {
      fontSize: '3vw',
    },
  }),
  horatioContainer: props => ({
    position: 'relative',
    border: 'solid 0.2vw #ffffff82',
    flexBasis: '25vw',
    marginRight: '0.25rem',
    borderRadius: '8px',
    boxShadow: 'inset 15px 2000px 2000px rgba(0, 45, 65, 0.6)',
    '& img': {
      width: props.custom ? '100%' : '100%',
      position: 'absolute',
      bottom: 0,
      left: props.custom ? '0' : 0,
      // clipPath: props.custom ? 'inset(10px 10px 0px 0px)' : 'inset(0px 0px 0px 0px)',
    },
  }),
}));

export default useStyles;
